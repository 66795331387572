import {
  Box,
  BoxProps,
  Center,
  Image,
  ImageProps,
  Wrap,
  WrapItem,
  WrapProps,
  chakra,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';

import {
  EImageGroupImageAlignment,
  ELinkSource,
} from '@/graphql-types/globalTypes';
import { ImageFallback } from '@/imports/ui/chakra/components/ImageFallback';
import { BACKGROUND_COLOR } from '@/modules/website-builder/attributes';
import { ImageGroup, ImageGroupImage } from '@/modules/website-builder/types';
import { getExternalUrl } from '@/utilities/getExternalUrl';

const JUSTIFY: Record<EImageGroupImageAlignment, WrapProps['justify']> = {
  [EImageGroupImageAlignment.LEFT]: 'left',
  [EImageGroupImageAlignment.CENTER]: 'center',
};

const ImageLink: React.FC<ImageProps & { image: ImageGroupImage }> = ({
  image,
  ...props
}) => {
  const href =
    image.linkSource === ELinkSource.CREATED_PAGE
      ? image.linkPage?.href
      : image.linkSource === ELinkSource.LINK
      ? image.linkHref
      : undefined;

  if (href) {
    if (image.linkIsExternal) {
      return (
        <chakra.a target="_blank" rel="noreferrer" href={getExternalUrl(href)}>
          <Image cursor="pointer" alt="" {...props} />
        </chakra.a>
      );
    }

    return (
      <NextLink href={href}>
        <Image cursor="pointer" alt="" {...props} />
      </NextLink>
    );
  }

  return <Image alt="" {...props} />;
};

export const ImageGroupRender: React.FC<
  {
    imageGroup: ImageGroup;
  } & BoxProps
> = ({ imageGroup, ...props }) => {
  const height = imageGroup.options.imageHeight + 'px';

  return (
    <Box
      w="full"
      backgroundColor={BACKGROUND_COLOR[imageGroup.options.backgroundColor]}
      {...props}
    >
      <Wrap
        w="full"
        maxW="container.xl"
        px="4"
        mx="auto"
        justify={JUSTIFY[imageGroup.options.imageAlignment]}
        spacing="5"
      >
        {imageGroup.images.map((image, index) => (
          <WrapItem key={index}>
            <Center h="full">
              <ImageLink
                image={image}
                src={image.imageProxy ?? undefined}
                maxH={height}
                fallback={
                  <ImageFallback
                    aspectRatio="4/3"
                    h={height}
                    borderRadius="lg"
                  />
                }
              />
            </Center>
          </WrapItem>
        ))}
      </Wrap>
    </Box>
  );
};
