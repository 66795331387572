import { BoxProps } from '@chakra-ui/react';

import {
  EBlockGroupImageAspectRatio,
  EPageContentBackgroundColor,
} from '@/graphql-types/globalTypes';

export const ASPECT_RATIO = {
  [EBlockGroupImageAspectRatio.HORIZONTAL]: '4/3',
  [EBlockGroupImageAspectRatio.VERY_HORIZONTAL]: '16/9',
  [EBlockGroupImageAspectRatio.VERTICAL]: '3/4',
  [EBlockGroupImageAspectRatio.VERY_VERTICAL]: '9/16',
  [EBlockGroupImageAspectRatio.SQUARE]: '1/1',
};

export const BACKGROUND_COLOR: Record<
  EPageContentBackgroundColor,
  BoxProps['backgroundColor']
> = {
  [EPageContentBackgroundColor.WHITE]: 'white',
  [EPageContentBackgroundColor.TRANSPARENT]: undefined,
};
