import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ThemeTypings,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import * as React from 'react';

const ButtonRadioContext = React.createContext<{
  getRadioProps: ReturnType<typeof useRadioGroup>['getRadioProps'];
  onChange?: (value?: string | number) => void;
  isDisabled?: boolean;
  colorScheme?: ThemeTypings['colorSchemes'];
} | null>(null);

const useButtonRadio = () => {
  const context = React.useContext(ButtonRadioContext);

  if (!context) {
    throw new Error(
      'A <ButtonRadio /> must be a child of a <ButtonRadioGroup />'
    );
  }

  return context;
};

type ButtonRadioGroupProps = {
  value?: string;
  onChange?: (value?: string) => void;
  isDisabled?: boolean;
  colorScheme?: ThemeTypings['colorSchemes'];
} & Omit<ButtonGroupProps, 'onChange' | 'value'>;

export const ButtonRadioGroup = React.forwardRef<
  HTMLDivElement,
  ButtonRadioGroupProps
>(({ value, onChange, isDisabled, colorScheme, ...props }, ref) => {
  const { getRootProps, getRadioProps } = useRadioGroup({ value });
  const rootProps = getRootProps();

  return (
    <ButtonRadioContext.Provider
      value={{ getRadioProps, onChange, isDisabled, colorScheme }}
    >
      <ButtonGroup isAttached w="full" ref={ref} {...props} {...rootProps}>
        {props.children}
      </ButtonGroup>
    </ButtonRadioContext.Provider>
  );
});

ButtonRadioGroup.displayName = 'ButtonRadioGroup';

ButtonRadioGroup.defaultProps = {
  colorScheme: 'primary',
};

export type ButtonRadioProps = {
  value?: string | number;
  onChange?: (value?: string | number) => void;
  isChecked?: boolean;
  isDisabled?: boolean;
};

export const ButtonRadio: React.FC<ButtonRadioProps> = (props) => {
  const context = useButtonRadio();

  const radioProps = context.getRadioProps({ value: props.value });
  const { getInputProps, getCheckboxProps, state } = useRadio(radioProps);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const isChecked = props.isChecked ?? state.isChecked;
  const onChange = props.onChange ?? context.onChange;
  const isDisabled = props.isDisabled ?? context.isDisabled;

  return (
    <Button
      as="label"
      cursor="pointer"
      opacity={isDisabled ? 0.5 : undefined}
      pointerEvents={isDisabled ? 'none' : undefined}
      variant={isChecked ? undefined : 'outline'}
      colorScheme={isChecked ? context.colorScheme : 'gray'}
      borderColor={isChecked ? 'transparent' : undefined}
      w="full"
      {...checkbox}
    >
      <input
        {...input}
        onChange={onChange ? () => onChange(props.value) : undefined}
      />
      {props.children}
    </Button>
  );
};
