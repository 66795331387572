import { Button, ButtonProps, chakra } from '@chakra-ui/react';
import * as React from 'react';

import { ChevronLeftIcon } from '@/imports/ui/chakra/feather';

export const BackButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      variant="link"
      colorScheme="black"
      leftIcon={<ChevronLeftIcon />}
      size="sm"
      justifyContent="flex-start"
      whiteSpace="normal"
      {...props}
    >
      <chakra.span noOfLines={1} wordBreak="break-all">
        {children}
      </chakra.span>
    </Button>
  );
};
