import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
  VisuallyHidden,
  visuallyHiddenStyle,
} from '@chakra-ui/react';
import * as React from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';

import { ButtonRadio, ButtonRadioGroup } from '@/components/ButtonRadio';
import { EHeroLinkBehavior, ELinkSource } from '@/graphql-types/globalTypes';
import { Card } from '@/imports/ui/chakra/components/Card';
import { BackButton } from '@/modules/website-builder/components/BackButton';
import { SearchablePageSelect } from '@/modules/website-builder/components/SearchablePageSelect';
import { WebsiteBuilderPageFormValues } from '@/modules/website-builder/types';

import { ImagePicker } from './ImagePicker';

export const HeroEditor: React.FC<{
  index: number;
  defaultName: string;
  onClickBack: () => void;
  methods: UseFormReturn<WebsiteBuilderPageFormValues, any>;
}> = (props) => {
  const { control, register } = props.methods;

  const linkBehavior = useWatch({
    control,
    name: `content.${props.index}.options.linkBehavior`,
  });
  const isLinkEnabled = useWatch({
    control,
    name: `content.${props.index}.options.isLinkEnabled`,
  });
  const linkSource = useWatch({
    control,
    name: `content.${props.index}.linkSource`,
  });
  const variant = useWatch({
    control,
    name: `content.${props.index}.variant`,
  });
  const imageProxy = useWatch({
    control,
    name: `content.${props.index}.imageProxy`,
  });

  return (
    <Stack spacing="4">
      <BackButton onClick={props.onClickBack}>
        Back to all components
      </BackButton>

      <Card as={Stack} spacing="4" p="6">
        <Text textStyle="bodyStrong">Component Settings</Text>

        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input
            placeholder="Hero"
            {...register(`content.${props.index}.name`)}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Variant</FormLabel>
          <Select
            {...register(`content.${props.index}.variant`, {
              valueAsNumber: true,
            })}
          >
            <option value="1">Card</option>
            <option value="2">Blend in</option>
            <option value="3">Image</option>
          </Select>
        </FormControl>
      </Card>

      <Card as={Stack} spacing="4" p="6">
        <Text textStyle="bodyStrong">Content</Text>

        {variant !== 3 && (
          <Controller
            control={control}
            name={`content.${props.index}.subtitle`}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Subtitle</FormLabel>
                <Textarea
                  resize="vertical"
                  value={field.value ?? ''}
                  onChange={field.onChange}
                  maxLength={50}
                />
                <FormHelperText textAlign="right">
                  {field.value?.length ?? 0}/50
                </FormHelperText>
              </FormControl>
            )}
          />
        )}

        {variant !== 3 && (
          <Controller
            control={control}
            name={`content.${props.index}.title`}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Title</FormLabel>
                <Textarea
                  resize="vertical"
                  value={field.value ?? ''}
                  onChange={field.onChange}
                  maxLength={60}
                />
                <FormHelperText textAlign="right">
                  {field.value?.length ?? 0}/60
                </FormHelperText>
              </FormControl>
            )}
          />
        )}

        {variant !== 3 && (
          <Controller
            control={control}
            name={`content.${props.index}.body`}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Body</FormLabel>
                <Textarea
                  resize="vertical"
                  value={field.value ?? ''}
                  onChange={field.onChange}
                  maxLength={360}
                />
                <FormHelperText textAlign="right">
                  {field.value?.length ?? 0}/360
                </FormHelperText>
              </FormControl>
            )}
          />
        )}

        <FormControl>
          <FormLabel>Background Image</FormLabel>
          <Controller
            control={control}
            name={`content.${props.index}.imageId`}
            render={({ field }) => (
              <ImagePicker
                {...field}
                imageAspectRatio={variant === 3 ? undefined : '16/9'}
                imageInput={{ w: 2000 }}
                nonDraftImageProxy={imageProxy}
              />
            )}
          />
        </FormControl>
      </Card>

      {variant !== 3 && (
        <Card as={Stack} spacing="4" p="6">
          <FormControl
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormLabel fontSize="md" mb="0">
              Link
            </FormLabel>
            <Switch
              colorScheme="purple"
              {...register(`content.${props.index}.options.isLinkEnabled`)}
            />
          </FormControl>

          {isLinkEnabled && variant === 1 && (
            <FormControl>
              <FormLabel>Link Option</FormLabel>
              <Controller
                control={control}
                name={`content.${props.index}.options.linkBehavior`}
                render={({ field }) => (
                  <ButtonRadioGroup {...field} size="sm" colorScheme="purple">
                    <ButtonRadio value={EHeroLinkBehavior.BUTTON}>
                      Button Link
                    </ButtonRadio>
                    <ButtonRadio value={EHeroLinkBehavior.CARD}>
                      Card Link
                    </ButtonRadio>
                  </ButtonRadioGroup>
                )}
              />
            </FormControl>
          )}

          {isLinkEnabled &&
            variant === 1 &&
            linkBehavior === EHeroLinkBehavior.CARD && (
              <Box
                bg="gray.100"
                borderRadius="md"
                color="gray.500"
                py="2"
                px="4"
              >
                The card will be the link
              </Box>
            )}

          {isLinkEnabled &&
            (variant === 2 || linkBehavior === EHeroLinkBehavior.BUTTON) && (
              <Controller
                control={control}
                name={`content.${props.index}.linkText`}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel
                      sx={variant === 1 ? visuallyHiddenStyle : undefined}
                    >
                      Button copy
                    </FormLabel>

                    <Input
                      value={field.value ?? ''}
                      onChange={field.onChange}
                      maxLength={50}
                    />
                    <FormHelperText textAlign="right">
                      {field.value?.length ?? 0}/50
                    </FormHelperText>
                  </FormControl>
                )}
              />
            )}

          {isLinkEnabled && (
            <Controller
              control={control}
              name={`content.${props.index}.linkSource`}
              render={({ field }) => (
                <FormControl>
                  <FormLabel>Link to</FormLabel>
                  <ButtonRadioGroup colorScheme="purple" {...field}>
                    <ButtonRadio value={ELinkSource.CREATED_PAGE}>
                      Created Page
                    </ButtonRadio>
                    <ButtonRadio value={ELinkSource.LINK}>
                      External Link
                    </ButtonRadio>
                  </ButtonRadioGroup>
                </FormControl>
              )}
            />
          )}

          {linkSource === ELinkSource.CREATED_PAGE && (
            <Controller
              control={control}
              name={`content.${props.index}.linkPage`}
              render={({ field }) => (
                <FormControl>
                  <VisuallyHidden>
                    <FormLabel>Page</FormLabel>
                  </VisuallyHidden>
                  <SearchablePageSelect {...field} />
                </FormControl>
              )}
            />
          )}

          {linkSource === ELinkSource.LINK && (
            <FormControl>
              <VisuallyHidden>
                <FormLabel>Href</FormLabel>
              </VisuallyHidden>
              <Input
                placeholder="https://"
                {...register(`content.${props.index}.linkHref`)}
              />
            </FormControl>
          )}

          {/* TODO(website-builder-v2): link editor goes here */}
        </Card>
      )}
    </Stack>
  );
};
