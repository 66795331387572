import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';
import * as React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { EPageContentBackgroundColor } from '@/graphql-types/globalTypes';
import { Card } from '@/imports/ui/chakra/components/Card';
import { BackButton } from '@/modules/website-builder/components/BackButton';
import { WebsiteBuilderPageFormValues } from '@/modules/website-builder/types';

import { RichTextModal } from './RichTextModal';

export const RichTextEditor: React.FC<{
  index: number;
  defaultName: string;
  onClickBack: () => void;
  methods: UseFormReturn<WebsiteBuilderPageFormValues, any>;
}> = (props) => {
  const {
    control,
    register,
    formState: { isDirty },
  } = props.methods;

  return (
    <Stack spacing="4">
      <BackButton onClick={props.onClickBack}>
        Back to all components
      </BackButton>

      <Card as={Stack} spacing="4" p="6">
        <Text textStyle="bodyStrong">Component Settings</Text>

        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input
            placeholder="RichText"
            {...register(`content.${props.index}.name`)}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Background Color</FormLabel>
          <Select
            {...register(`content.${props.index}.options.backgroundColor`)}
          >
            <option value={EPageContentBackgroundColor.WHITE}>White</option>
            <option value={EPageContentBackgroundColor.TRANSPARENT}>
              Light Gray
            </option>
          </Select>
        </FormControl>
      </Card>

      <Card as={Stack} spacing="4" p="6">
        <Text textStyle="bodyStrong">Body</Text>

        <Controller
          control={control}
          name={`content.${props.index}.rawDraftContentState`}
          render={({ field }) => (
            <FormControl>
              <VisuallyHidden>
                <FormLabel>Body</FormLabel>
              </VisuallyHidden>
              <RichTextModal {...field} isDirty={isDirty} />
            </FormControl>
          )}
        />
      </Card>
    </Stack>
  );
};
