import { Box, BoxProps } from '@chakra-ui/react';
import * as React from 'react';

import {
  Blueprint,
  BlueprintPortal,
  useBlueprint,
} from '@/layouts/components/Blueprint';
import { useAlphabetSoup } from '@/modules/alphabet-soup';

export const MilleFeuilleMain: React.FC<BoxProps> = (props) => {
  return <Box as="main" flex="1" {...props} />;
};

export const MilleFeuillePortal = BlueprintPortal;

const _Wrapper: React.FC<BoxProps> = (props) => {
  const { headerHeight } = useBlueprint();
  const { isWebsiteBuilder } = useAlphabetSoup();

  return (
    <Box
      // when rendering pages we want to span the height of the screen. in the
      // website builder we are in a preview box so we don't want the whole
      // screen, just the height of the preview box
      minH={`calc(100${isWebsiteBuilder ? '%' : 'vh'} - ${headerHeight}px)`}
      display="flex"
      flexDirection="column"
      {...props}
    />
  );
};

export const MilleFeuille: React.FC<BoxProps> = ({ children, ...props }) => (
  <Blueprint {...props}>
    <_Wrapper>{children}</_Wrapper>
  </Blueprint>
);
