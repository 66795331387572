import { Button, SlideFade, Stack, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';

import { Card } from '@/imports/ui/chakra/components/Card';
import { PlusIcon } from '@/imports/ui/chakra/feather';
import { VeryGhostlyButton } from '@/modules/website-builder/components/GhostlyButton';

import { FormTypeSelect } from './components/FormTypeSelect';

export const ChooseFormType: React.FC<{
  onChoose: (value: 'direct-link' | 'dropdown-menu') => void;
  onCancel: () => void;
}> = ({ onChoose, onCancel }) => {
  const [formType, setFormType] = React.useState<
    'direct-link' | 'dropdown-menu'
  >('direct-link');
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {isOpen && (
        <SlideFade in>
          <Card border="1px" borderColor="gray.200" shadow="sm" p="4">
            <Stack spacing="4">
              <FormTypeSelect value={formType} onChange={setFormType} />

              <Stack direction="row">
                <Button
                  size="sm"
                  w="full"
                  variant="outline"
                  colorScheme="gray"
                  onClick={() => {
                    onClose();
                    onCancel();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  size="sm"
                  w="full"
                  colorScheme="purple"
                  onClick={() => onChoose(formType)}
                >
                  Select
                </Button>
              </Stack>
            </Stack>
          </Card>
        </SlideFade>
      )}
      {!isOpen && (
        <SlideFade in>
          <VeryGhostlyButton
            leftIcon={<PlusIcon />}
            borderRadius="xl"
            onClick={() => onOpen()}
          >
            Add a New Tab
          </VeryGhostlyButton>
        </SlideFade>
      )}
    </>
  );
};
``;
