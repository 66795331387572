import { Link, Stack, Text } from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';

import { ENavigationItemRestriction } from '@/graphql-types/globalTypes';
import { Card } from '@/imports/ui/chakra/components/Card';
import { Semibold } from '@/imports/ui/chakra/components/Semibold';

export const NotEditable: React.FC<{ page: React.ReactNode }> = (props) => (
  <Card p="6">
    <Stack spacing="4">
      {props.page}
      <Stack textAlign="center">{props.children}</Stack>
    </Stack>
  </Card>
);

export const NotEditableTitle: React.FC = (props) => (
  <Text textStyle="compactStrong">{props.children}</Text>
);

export const NotEditableDescription: React.FC = (props) => (
  <Text textStyle="compact" color="gray.600">
    {props.children}
  </Text>
);

export const NotEditableSuggestion: React.FC = (props) => (
  <Text textStyle="detail" color="gray.500">
    {props.children}
  </Text>
);

export const NotEditableRestriction: React.FC<{
  restriction: ENavigationItemRestriction;
}> = (props) => {
  return (
    <>
      This tab is{' '}
      {
        {
          [ENavigationItemRestriction.ANYONE]: (
            <>
              visible to the <Semibold>public</Semibold>
            </>
          ),
          [ENavigationItemRestriction.LOGGED_IN]: (
            <>
              visible to <Semibold>logged in users</Semibold>
            </>
          ),
          [ENavigationItemRestriction.MEMBERS_ONLY]: (
            <>
              visible to <Semibold>members only</Semibold>
            </>
          ),
          [ENavigationItemRestriction.NOBODY]: (
            <>
              currently <Semibold>hidden</Semibold>
            </>
          ),
        }[props.restriction]
      }
      . To edit page access, please to to{' '}
      <Link
        as={NextLink}
        href="/admin/settings/generalSettings"
        textDecoration="underline"
      >
        General Settings
      </Link>
      .
    </>
  );
};
