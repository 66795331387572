import { Button, ButtonProps } from '@chakra-ui/react';
import * as React from 'react';

export const VeryGhostlyButton: React.FC<ButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Button
      variant="unstyled"
      w="full"
      p="4"
      height="auto"
      bg="white"
      color="gray.400"
      _hover={{ color: 'gray.500' }}
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      border="1px"
      borderColor="gray.200"
      shadow="sm"
      fontWeight="normal"
      {...props}
    >
      {children}
    </Button>
  );
};

export const ExtremelyGhostlyButton: React.FC<ButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Button
      variant="unstyled"
      w="full"
      pl="3"
      pr="3"
      height="auto"
      bg="white"
      color="gray.400"
      _hover={{ color: 'gray.500' }}
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      fontWeight="normal"
      {...props}
    >
      {children}
    </Button>
  );
};
