import { gql, useMutation } from '@apollo/client';
import { Button, useToast } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import * as React from 'react';

import { ConfirmDialog } from '@/components/ConfirmDialog';

const PUBLISH_ALL = gql`
  mutation PublishButton_PublishAll {
    publishAll {
      success
    }
  }
`;

export const PublishButton: React.FC = () => {
  const toast = useToast();
  const confirmDialog = useDisclosure();
  const [publishAll] = useMutation(PUBLISH_ALL, {
    update(cache) {
      cache.evict({
        id: `ROOT_QUERY`,
        fieldName: 'pages',
      });
      cache.evict({
        id: `ROOT_QUERY`,
        fieldName: 'page',
      });
      cache.evict({
        id: `ROOT_QUERY`,
        fieldName: 'navigation',
      });
      cache.gc();
    },
    onCompleted: () =>
      toast({ status: 'success', description: 'All changes published!' }),
    onError: () =>
      toast({ status: 'error', description: 'An unknown error occurred' }),
  });

  return (
    <Button colorScheme="purple" onClick={confirmDialog.onOpen}>
      Publish
      <ConfirmDialog
        isOpen={confirmDialog.isOpen}
        onClose={confirmDialog.onClose}
        title="Publish All Changes"
        body="Are you sure you want to continue?"
        onConfirm={publishAll}
        confirmButtonText="Yes, Publish Everything"
        confirmButtonColorScheme="purple"
      />
    </Button>
  );
};
