import { Box, BoxProps } from '@chakra-ui/react';
import * as React from 'react';

import { Redraft } from '@/components/Redraft';
import { Prose } from '@/imports/ui/chakra/components/Prose';
import { BACKGROUND_COLOR } from '@/modules/website-builder/attributes';
import { RichText } from '@/modules/website-builder/types';

export const RichTextRender: React.FC<{ richText: RichText } & BoxProps> = ({
  richText,
  ...props
}) => {
  return (
    <Box
      w="full"
      backgroundColor={BACKGROUND_COLOR[richText.options.backgroundColor]}
      {...props}
    >
      <Box w="full" maxW="container.md" mx="auto" px="4">
        <Prose>
          <Redraft rawDraftContentState={richText.rawDraftContentState} />
        </Prose>
      </Box>
    </Box>
  );
};
