import {
  Button,
  ButtonProps,
  Divider,
  DividerProps,
  MenuDivider,
  MenuGroup,
  Stack,
  StackProps,
  Text,
  chakra,
} from '@chakra-ui/react';
import * as React from 'react';

import { CheckIcon } from '@/imports/ui/chakra/feather';

/**
 * A rough approximation of a chakra "MenuList" for use within a popover instead of a chakra "Menu"
 *
 * This is useful when you need to use a popover instead of a menu (for example in searchable select lists)
 */
export const PopoverMenuList = React.forwardRef<HTMLDivElement, StackProps>(
  ({ children, ...props }, ref) => {
    return (
      <Stack padding="2" borderRadius="lg" ref={ref} {...props}>
        {children}
      </Stack>
    );
  }
);

PopoverMenuList.displayName = 'PopoverMenuList';

/**
 * A rough approximation of a chakra "MenuItem" for use within a popover instead of a chakra "Menu"
 *
 * This is useful when you need to use a popover instead of a menu (for example in searchable select lists)
 */
export const PopoverMenuItem = React.forwardRef<
  HTMLButtonElement,
  {
    isChecked?: boolean;
  } & ButtonProps
>(({ isChecked, children, ...props }, ref) => {
  return (
    <Button
      variant="ghost"
      colorScheme="gray"
      leftIcon={isChecked ? <CheckIcon /> : undefined}
      fontWeight="normal"
      whiteSpace="normal"
      {...props}
      ref={ref}
      borderRadius="xl"
    >
      <chakra.span
        textAlign="left"
        noOfLines={1}
        flex="1"
        wordBreak="break-all"
      >
        {children}
      </chakra.span>
    </Button>
  );
});

PopoverMenuItem.displayName = 'PopoverMenuItem';

export const PopoverMenuDivider: React.FC<DividerProps> = (props) => {
  return <Divider my="2" {...props} />;
};

export const PopoverMenuGroup: React.FC<{ title?: string }> = (props) => {
  return (
    <Stack>
      {props.title && (
        <Text
          color="gray.500"
          fontSize="sm"
          fontWeight="semibold"
          mx="2"
          textTransform="uppercase"
        >
          {props.title}
        </Text>
      )}
      {props.children}
    </Stack>
  );
};
