import { NetworkStatus, gql, useQuery } from '@apollo/client';
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import FocusLock from 'react-focus-lock';

import { PopoverMenuItem, PopoverMenuList } from '@/components/PopoverMenu';
import { SelectButton } from '@/components/SelectButton';
import { EPageRestriction } from '@/graphql-types/globalTypes';
import { XIcon } from '@/imports/ui/chakra/feather';
import { useDebouncedValue } from '@/imports/ui/hooks/useDebouncedValue';

import {
  SearchablePageSelect_Pages,
  SearchablePageSelect_PagesVariables,
} from './graphql-types/SearchablePageSelect_Pages';

const PAGES = gql`
  query SearchablePageSelect_Pages($input: PagesInput!) {
    pages(input: $input) {
      edges {
        node {
          _id
          name
          metadata {
            version
            publishedVersion
          }
          restriction
        }
      }
    }
  }
`;

export const SearchablePageSelect = React.forwardRef<
  HTMLButtonElement,
  {
    value: {
      _id: string;
      name: string;
      restriction: EPageRestriction;
    } | null;
    onChange: (
      value: {
        _id: string;
        name: string;
        restriction: EPageRestriction;
      } | null
    ) => void;
    onBlur: () => void;
  }
>((props, ref) => {
  const firstFieldRef = React.useRef(null);
  const [searchText, setSearchText] = React.useState('');
  const debouncedSearchText = useDebouncedValue(searchText, 500);

  const query = useQuery<
    SearchablePageSelect_Pages,
    SearchablePageSelect_PagesVariables
  >(PAGES, {
    variables: {
      input: {
        first: 10,
        filter: {
          includeUnpublished: true,
          searchText: debouncedSearchText,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const pages = React.useMemo(() => {
    const _pages =
      query.networkStatus === NetworkStatus.setVariables
        ? query.previousData?.pages.edges || []
        : query.data?.pages.edges || [];

    return _pages.map((edge) => ({
      _id: edge.node._id,
      name: edge.node.name,
      restriction: edge.node.restriction,
    }));
  }, [
    query.data?.pages.edges,
    query.networkStatus,
    query.previousData?.pages.edges,
  ]);

  const pagesWithValue = React.useMemo(() => {
    return props.value
      ? pages.find((page) => page._id === props.value?._id)
        ? pages
        : [props.value, ...pages]
      : pages;
  }, [pages, props.value]);

  return (
    <Popover initialFocusRef={firstFieldRef} matchWidth isLazy>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <SelectButton ref={ref}>
              {props.value ? props.value.name : 'Choose a page'}
            </SelectButton>
          </PopoverTrigger>
          <PopoverContent width="full" shadow="lg" onBlur={props.onBlur}>
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverMenuList>
                <InputGroup>
                  <Input
                    autoFocus
                    ref={firstFieldRef}
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    placeholder=""
                    autoComplete="off"
                  />
                  {query.networkStatus === NetworkStatus.setVariables ? (
                    <InputRightElement>
                      <Spinner color="gray.600" size="sm" />
                    </InputRightElement>
                  ) : !!searchText ? (
                    <InputRightElement>
                      <IconButton
                        aria-label="clear search"
                        icon={<XIcon />}
                        onClick={() => setSearchText('')}
                        size="sm"
                        variant="ghost"
                        colorScheme="gray"
                      />
                    </InputRightElement>
                  ) : null}
                </InputGroup>
                {pagesWithValue.map((page) => (
                  <PopoverMenuItem
                    key={page._id}
                    isChecked={props.value?._id === page._id}
                    onClick={() => {
                      props.onChange(
                        props.value?._id === page._id
                          ? null
                          : {
                              _id: page._id,
                              name: page.name,
                              restriction: page.restriction,
                            }
                      );
                      onClose();
                    }}
                  >
                    {page.name}
                  </PopoverMenuItem>
                ))}
                {pages.length === 0 && (
                  <Text
                    color="gray.500"
                    padding="2"
                    textStyle="compact"
                    textAlign="center"
                  >
                    No matching search results
                  </Text>
                )}
              </PopoverMenuList>
            </FocusLock>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
});

SearchablePageSelect.displayName = 'SearchablePageSelect';
