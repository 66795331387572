import {
  BlockInput,
  CarouselItemInput,
  ImageGroup_ImageInput,
  PageContentInput,
} from '@/graphql-types/globalTypes';

import { PageContent } from './types';

export const convertPageContentToPageContentInput = (
  pageContent: PageContent
): PageContentInput[] => {
  return pageContent.map((item): PageContentInput => {
    if (item.__typename === 'Hero') {
      return {
        hero: {
          name: item.name,
          variant: item.variant,
          imageId: item.imageId,
          options: {
            linkBehavior: item.options.linkBehavior,
            isLinkEnabled: item.options.isLinkEnabled,
          },
          title: item.title,
          subtitle: item.subtitle,
          body: item.body,
          linkText: item.linkText,
          linkHref: item.linkHref,
          linkPageId: item.linkPage?._id,
          linkSource: item.linkSource,
        },
      };
    }

    if (item.__typename === 'BlockGroup') {
      return {
        blockGroup: {
          name: item.name,
          variant: item.variant,
          options: {
            columns: item.options.columns,
            backgroundColor: item.options.backgroundColor,
            blockAlignment: item.options.blockAlignment,
            blockLinkBehavior: item.options.blockLinkBehavior,
            blockImageAspectRatio: item.options.blockImageAspectRatio,
            isBlockImageEnabled: item.options.isBlockImageEnabled,
            isBlockSubtitleEnabled: item.options.isBlockSubtitleEnabled,
            isBlockTitleEnabled: item.options.isBlockTitleEnabled,
            isBlockBodyTextEnabled: item.options.isBlockBodyTextEnabled,
          },
          blocks: item.blocks
            ? item.blocks.map((block): BlockInput => {
                return {
                  name: block.name,
                  imageId: block.imageId,
                  title: block.title,
                  subtitle: block.subtitle,
                  body: block.body,
                  linkText: block.linkText,
                  linkHref: block.linkHref,
                  linkPageId: block.linkPage?._id,
                  linkSource: block.linkSource,
                };
              })
            : [],
        },
      };
    }

    if (item.__typename === 'Carousel') {
      return {
        carousel: {
          name: item.name,
          variant: item.variant,
          options: {
            carouselItemLinkBehavior: item.options.carouselItemLinkBehavior,
            isCarouselItemSubtitleEnabled:
              item.options.isCarouselItemSubtitleEnabled,
            isCarouselItemTitleEnabled: item.options.isCarouselItemTitleEnabled,
            isCarouselItemBodyTextEnabled:
              item.options.isCarouselItemBodyTextEnabled,
            isCarouselAutoChangeEnabled:
              item.options.isCarouselAutoChangeEnabled,
            carouselAutoChangeSpeed: item.options.carouselAutoChangeSpeed,
          },
          carouselItems: item.carouselItems
            ? item.carouselItems.map((carouselItem): CarouselItemInput => {
                return {
                  name: carouselItem.name,
                  imageId: carouselItem.imageId,
                  title: carouselItem.title,
                  subtitle: carouselItem.subtitle,
                  body: carouselItem.body,
                  linkText: carouselItem.linkText,
                  linkHref: carouselItem.linkHref,
                  linkPageId: carouselItem.linkPage?._id,
                  linkSource: carouselItem.linkSource,
                };
              })
            : [],
        },
      };
    }

    if (item.__typename === 'Heading') {
      return {
        heading: {
          name: item.name,
          variant: 1,
          title: item.title,
          subtitle: item.subtitle,
          options: {
            textAlign: item.options.textAlign,
            backgroundColor: item.options.backgroundColor,
          },
        },
      };
    }

    if (item.__typename === 'ImageGroup') {
      return {
        imageGroup: {
          name: item.name,
          variant: item.variant,
          options: {
            backgroundColor: item.options.backgroundColor,
            imageHeight: item.options.imageHeight,
            imageAlignment: item.options.imageAlignment,
          },
          images: item.images
            ? item.images.map(
                (image): ImageGroup_ImageInput => ({
                  imageId: image.imageId,
                  linkHref: image.linkHref,
                  linkPageId: image.linkPage?._id,
                  linkSource: image.linkSource,
                })
              )
            : [],
        },
      };
    }

    if (item.__typename === 'RichText') {
      return {
        richText: {
          name: item.name,
          variant: 1,
          rawDraftContentState: item.rawDraftContentState,
          options: {
            backgroundColor: item.options.backgroundColor,
          },
        },
      };
    }

    throw new Error('Item Typename Not Recognized');
  });
};
