import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  SlideFade,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { DefaultValues, useFieldArray, useForm } from 'react-hook-form';

import {
  ENavigationItemRestriction,
  ENavigationItemStructure,
} from '@/graphql-types/globalTypes';
import { Card } from '@/imports/ui/chakra/components/Card';
import {
  ChevronDownIcon,
  Edit2Icon,
  PlusIcon,
} from '@/imports/ui/chakra/feather';
import { BackButton } from '@/modules/website-builder/components/BackButton';
import { ExtremelyGhostlyButton } from '@/modules/website-builder/components/GhostlyButton';
import {
  NavigationItem,
  NavigationItemChild,
} from '@/modules/website-builder/types';

import { ChildItem } from '../../NewDropdownMenuForm/components/ChildItem';

export type DropdownMenuFormValues = {
  label: string;
  children: NavigationItemChild[];
};

export const DropdownMenuForm: React.FC<{
  value: NavigationItem;
  onChange: (value: NavigationItem) => void;
  onClickAdd: () => void;
  onClickChild: (childIndex: number) => void;
}> = (props) => {
  const nameEditing = useDisclosure();

  const defaultValues: DefaultValues<DropdownMenuFormValues> =
    React.useMemo(() => {
      return {
        label: props.value.label,
        children: props.value.children || [],
      };
    }, [props.value.label, props.value.children]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<DropdownMenuFormValues>({
    defaultValues,
  });

  const childrenFieldArray = useFieldArray({ control, name: 'children' });

  const onSubmit = handleSubmit((data) => {
    props.onChange({
      label: data.label,
      href: null,
      page: null,
      restriction: ENavigationItemRestriction.ANYONE,
      defaultItemIdentifier: null,
      children: data.children,
      structure: ENavigationItemStructure.DROPDOWN,
    });
  });

  return (
    <SlideFade in>
      <Stack spacing="4">
        <Card border="1px" borderColor="gray.200" shadow="sm" p="6">
          {nameEditing.isOpen ? (
            <Stack spacing="4">
              <FormControl isInvalid={!!errors.label}>
                <FormLabel>Tab Name</FormLabel>
                <Input
                  autoFocus
                  {...register('label', {
                    required: 'Tab name is required',
                  })}
                />
                <FormErrorMessage>{errors.label?.message}</FormErrorMessage>
              </FormControl>
              <Stack direction="row" spacing="4">
                <Button
                  size="sm"
                  w="full"
                  variant="outline"
                  colorScheme="gray"
                  onClick={nameEditing.onClose}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  w="full"
                  colorScheme="purple"
                  onClick={() => {
                    onSubmit();
                    nameEditing.onClose();
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text textStyle="bodyStrong">{props.value.label}</Text>
              <IconButton
                size="sm"
                aria-label="edit name"
                icon={<Edit2Icon />}
                variant="ghost"
                colorScheme="gray"
                onClick={nameEditing.onOpen}
              />
            </Stack>
          )}
        </Card>

        <Card border="1px" borderColor="gray.200" shadow="sm" py="6">
          <Stack spacing="4">
            <Stack direction="row" alignItems="center" px="7" spacing="3">
              <ChevronDownIcon boxSize="4" />
              <Text textStyle="bodyStrong">Links</Text>
            </Stack>

            <Box px="4">
              <DragDropContext
                onDragEnd={(result) => {
                  if (result.destination) {
                    childrenFieldArray.move(
                      result.source.index,
                      result.destination.index
                    );
                    onSubmit();
                  }
                }}
              >
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <Box ref={provided.innerRef} {...provided.droppableProps}>
                      {childrenFieldArray.fields.map((field, index) => (
                        <ChildItem
                          key={index}
                          index={index}
                          field={field}
                          onClick={() => props.onClickChild(index)}
                        />
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>

            <Box px="4">
              <ExtremelyGhostlyButton
                leftIcon={<PlusIcon />}
                iconSpacing="3"
                onClick={props.onClickAdd}
              >
                Add a Link
              </ExtremelyGhostlyButton>
            </Box>
          </Stack>
        </Card>
      </Stack>
    </SlideFade>
  );
};
