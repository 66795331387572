import { gql, useQuery } from '@apollo/client';
import { Box, Button, Center, Image, Input } from '@chakra-ui/react';
import * as React from 'react';

import {
  DynamicImageInput,
  EPrepImagesSource,
} from '@/graphql-types/globalTypes';
import { useImageUpload } from '@/hooks/useImageUpload';
import { CameraIcon } from '@/imports/ui/chakra/feather';

import {
  ImagePicker_GetDraftImageProxy,
  ImagePicker_GetDraftImageProxyVariables,
} from './graphql-types/ImagePicker_GetDraftImageProxy';

const GET_DRAFT_IMAGE_PROXY = gql`
  query ImagePicker_GetDraftImageProxy(
    $getDraftImageProxyInput: GetDraftImageProxyInput!
    $dynamicImageInput: DynamicImageInput!
  ) {
    getDraftImageProxy(input: $getDraftImageProxyInput) {
      imageId
      imageProxy(input: $dynamicImageInput)
    }
  }
`;

export const ImagePicker = React.forwardRef<
  HTMLButtonElement,
  {
    value: string | null;
    onChange: (value: string) => void;
    onBlur: () => void;
    imageAspectRatio?: string;
    imageMaxHeight?: number;
    imageInput: DynamicImageInput;
    nonDraftImageProxy: string | null;
  }
>(
  (
    {
      value,
      onChange,
      imageAspectRatio,
      imageMaxHeight,
      imageInput,
      nonDraftImageProxy,
    },
    ref
  ) => {
    const query = useQuery<
      ImagePicker_GetDraftImageProxy,
      ImagePicker_GetDraftImageProxyVariables
    >(GET_DRAFT_IMAGE_PROXY, {
      variables: {
        getDraftImageProxyInput: {
          imageId: value ?? '',
          source: EPrepImagesSource.DEFAULT,
        },
        dynamicImageInput: imageInput,
      },
      skip: !value,
    });

    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const { uploadImages } = useImageUpload(EPrepImagesSource.DEFAULT);

    const [isLoading, setIsLoading] = React.useState(false);

    const src =
      query.data?.getDraftImageProxy?.imageProxy ??
      nonDraftImageProxy ??
      undefined;

    return (
      <Center position="relative">
        <Input
          type="file"
          multiple={false}
          accept="image/*"
          onChange={async (event) => {
            setIsLoading(true);
            const file = event.currentTarget.files?.item(0);

            if (!file) {
              return;
            }

            const resource = await uploadImages([file]);
            setIsLoading(false);

            onChange && onChange(resource[0]);
          }}
          ref={fileInputRef}
          display="none"
        />

        <Image
          borderRadius="md"
          src={src}
          alt=""
          objectFit="cover"
          sx={{ aspectRatio: imageAspectRatio }}
          h="full"
          maxH={imageMaxHeight ? imageMaxHeight + 'px' : undefined}
          fallback={
            <Box
              sx={{ aspectRatio: imageAspectRatio }}
              backgroundColor="gray.50"
              border="1px"
              borderColor="gray.200"
              w="full"
              borderRadius="md"
              minH={
                imageMaxHeight
                  ? imageMaxHeight + 'px'
                  : imageAspectRatio
                  ? undefined
                  : '150px'
              }
            />
          }
        />

        <Center position="absolute" top="0" left="0" right="0" bottom="0">
          <Button
            leftIcon={<CameraIcon />}
            onClick={() => fileInputRef.current?.click()}
            isLoading={isLoading}
            colorScheme="gray"
            variant="outline"
            ref={ref}
            size="sm"
          >
            {!!value ? 'Replace Image' : 'Choose Image'}
          </Button>
        </Center>
      </Center>
    );
  }
);

ImagePicker.displayName = 'ImagePicker';
