import { gql, useQuery } from '@apollo/client';
import { IconButton, Stack, Text } from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { AnimatedLoaderIcon } from '@/components/AnimatedLoaderIcon';
import { CheckCircleIcon, XIcon } from '@/imports/ui/chakra/feather';
import {
  WebsiteBuilderNavigationFormValues,
  WebsiteBuilderPageFormValues,
} from '@/modules/website-builder/types';

import { Header_Tenant } from './graphql-types/Header_Tenant';

import { PublishButton } from './components/PublishButton';

const TENANT = gql`
  query Header_Tenant {
    tenant {
      result {
        _id
        acronym
      }
    }
  }
`;

export const Header: React.FC<{
  pageMethods: UseFormReturn<WebsiteBuilderPageFormValues, any>;
  navigationMethods: UseFormReturn<WebsiteBuilderNavigationFormValues, any>;
}> = (props) => {
  const query = useQuery<Header_Tenant>(TENANT);

  const isDirty =
    props.pageMethods.formState.isDirty ||
    props.navigationMethods.formState.isDirty;

  return (
    <Stack
      direction="row"
      spacing="8"
      alignItems="center"
      bg="white"
      shadow="base"
      zIndex="2"
      px="4"
    >
      <NextLink href="/admin/website">
        <IconButton
          variant="ghost"
          colorScheme="gray"
          icon={<XIcon />}
          aria-label="back to admin site"
        />
      </NextLink>
      <Stack
        flex="1"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        py="4"
        spacing="4"
      >
        <Stack spacing="0">
          <Text textStyle="bodyStrong">
            {query.data?.tenant.result?.acronym}
          </Text>

          <Stack direction="row" alignItems="center" spacing="1">
            {isDirty ? (
              <AnimatedLoaderIcon boxSize="3" color="gray.600" />
            ) : (
              <CheckCircleIcon boxSize="3" color="green.500" />
            )}
            <Text textStyle="detail" color="gray.600">
              {isDirty ? 'Saving' : 'All changes saved'}
            </Text>
          </Stack>
        </Stack>

        <PublishButton />
      </Stack>
    </Stack>
  );
};
