import {
  FormControl,
  FormControlProps,
  FormLabel,
  FormLabelProps,
  Switch,
  SwitchProps,
  forwardRef,
} from '@chakra-ui/react';
import * as React from 'react';

export const SpecialFormControl = forwardRef<
  FormControlProps,
  typeof FormControl
>((props, ref) => (
  <FormControl
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    {...props}
    ref={ref}
  />
));

export const SpecialFormLabel = forwardRef<FormLabelProps, typeof FormLabel>(
  (props, ref) => (
    <FormLabel fontSize="md" fontWeight="normal" mb="0" {...props} ref={ref} />
  )
);

export const SpecialSwitch = forwardRef<SwitchProps, typeof Switch>(
  (props, ref) => <Switch size="sm" colorScheme="purple" {...props} ref={ref} />
);
