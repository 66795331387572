import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import * as React from 'react';
import { FieldPath, UseFormReturn, useWatch } from 'react-hook-form';

import { Semibold } from '@/imports/ui/chakra/components/Semibold';
import { WebsiteBuilderNavigationFormValues } from '@/modules/website-builder/types';

export const DeleteButton: React.FC<{
  onDelete: () => void;
  labelPath: FieldPath<WebsiteBuilderNavigationFormValues>;
  isDisabled: boolean;
  methods: UseFormReturn<WebsiteBuilderNavigationFormValues, any>;
}> = (props) => {
  const initialFocusRef = React.useRef<HTMLButtonElement>(null);
  const { control } = props.methods;
  const name = useWatch({ control, name: props.labelPath });

  return (
    <Popover initialFocusRef={initialFocusRef} placement="end">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              variant="outline"
              colorScheme="gray"
              color="red.500"
              isDisabled={props.isDisabled}
              w="full"
            >
              {props.children}
            </Button>
          </PopoverTrigger>

          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              Are you sure you want to delete <Semibold>{name}</Semibold>?
            </PopoverBody>
            <PopoverFooter display="flex" justifyContent="flex-end" gap="2">
              <Button
                colorScheme="gray"
                variant="outline"
                size="sm"
                ref={initialFocusRef}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="purple"
                size="sm"
                onClick={() => {
                  props.onDelete();
                  onClose();
                }}
              >
                Delete
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
