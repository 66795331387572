import {
  Box,
  BoxProps,
  Center,
  CenterProps,
  Circle,
  Divider,
  Grid,
  GridItem,
  Stack,
} from '@chakra-ui/react';
import random from 'lodash/random';
import * as React from 'react';

import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ImageIcon,
} from '@/imports/ui/chakra/feather';

const _MiniSearch = () => (
  <Box borderWidth="1px" borderColor="gray.50" p="1" borderRadius="lg">
    <Box backgroundColor="gray.50" h="2" borderRadius="lg" />
  </Box>
);

const _MiniNavigation = () => (
  <Stack>
    <Box backgroundColor="gray.50" h="2" borderRadius="lg" />
    <Box backgroundColor="gray.50" h="2" borderRadius="lg" />
    <Box backgroundColor="gray.50" h="2" borderRadius="lg" />
    <Box backgroundColor="gray.50" h="2" borderRadius="lg" />
  </Stack>
);

const _MiniEvent = () => (
  <Stack
    borderWidth="1px"
    borderColor="gray.50"
    p="1"
    borderRadius="lg"
    spacing="1"
  >
    <Center backgroundColor="gray.50" h="10" borderRadius="lg">
      <CalendarIcon color="gray.400" boxSize="3" />
    </Center>
    <Box backgroundColor="gray.50" h="2" borderRadius="lg" />
  </Stack>
);

export const MiniEventsPage = () => (
  <Grid
    borderWidth="1px"
    borderColor="gray.300"
    backgroundColor="gray.200"
    borderRadius="md"
    p="2"
    templateRows="repeat(3, auto)"
    templateColumns="repeat(12, 1fr)"
    gap="2"
  >
    <GridItem colSpan={3} />
    <GridItem colSpan={6}>
      <_MiniSearch />
    </GridItem>
    <GridItem colSpan={3} />
    <GridItem colSpan={3}>
      <_MiniNavigation />
    </GridItem>
    <GridItem colSpan={6}>
      <Stack>
        <_MiniEvent />
        <_MiniEvent />
      </Stack>
    </GridItem>
    <GridItem colSpan={3}>
      <_MiniEvent />
    </GridItem>
  </Grid>
);

const _MiniSponsor = () => (
  <Stack direction="row" alignItems="center" spacing="1">
    <Center backgroundColor="gray.50" h="6" w="8" borderRadius="lg">
      <ImageIcon color="gray.400" boxSize="3" />
    </Center>
    <Stack flex="1" spacing="1">
      <Box backgroundColor="gray.50" h="1" borderRadius="lg" w="30%" />
      <Box backgroundColor="gray.50" h="1" borderRadius="lg" />
      <Box backgroundColor="gray.50" h="1" borderRadius="lg" w="70%" />
    </Stack>
  </Stack>
);

export const MiniSponsors = () => (
  <Stack borderWidth="1px" borderColor="gray.50" p="1" borderRadius="lg">
    <_MiniSponsor />
    <_MiniSponsor />
    <_MiniSponsor />
  </Stack>
);

export const MiniSponsorDirectoryPage = () => (
  <Grid
    borderWidth="1px"
    borderColor="gray.300"
    backgroundColor="gray.200"
    borderRadius="md"
    p="2"
    templateRows="repeat(2, auto)"
    templateColumns="repeat(12, 1fr)"
    gap="2"
  >
    <GridItem colSpan={3} />
    <GridItem colSpan={6}>
      <_MiniSearch />
    </GridItem>
    <GridItem colSpan={3} />
    <GridItem colSpan={3}>
      <_MiniNavigation />
    </GridItem>
    <GridItem colSpan={6}>
      <MiniSponsors />
    </GridItem>
    <GridItem colSpan={3} />
  </Grid>
);

const _MiniPerson = () => (
  <Stack direction="row" alignItems="center" spacing="1">
    <Circle backgroundColor="gray.50" size="2.5" />
    <Stack flex="1" spacing="1" direction="row" justifyContent="space-between">
      <Box
        backgroundColor="gray.50"
        h="1.5"
        borderRadius="lg"
        w={`${random(25, 50)}%`}
      />
      <Box backgroundColor="gray.50" h="1.5" borderRadius="lg" w="15%" />
    </Stack>
  </Stack>
);

export const MiniPeople = () => (
  <Stack
    borderWidth="1px"
    borderColor="gray.50"
    p="1"
    borderRadius="lg"
    spacing="1"
  >
    <_MiniPerson />
    <_MiniPerson />
    <_MiniPerson />
    <_MiniPerson />
    <_MiniPerson />
    <_MiniPerson />
    <_MiniPerson />
    <_MiniPerson />
  </Stack>
);

export const MiniPeopleDirectoryPage = () => (
  <Grid
    borderWidth="1px"
    borderColor="gray.300"
    backgroundColor="gray.200"
    borderRadius="md"
    p="2"
    templateRows="repeat(2, auto)"
    templateColumns="repeat(12, 1fr)"
    gap="2"
  >
    <GridItem colSpan={3} />
    <GridItem colSpan={6}>
      <_MiniSearch />
    </GridItem>
    <GridItem colSpan={3} />
    <GridItem colSpan={3}>
      <_MiniNavigation />
    </GridItem>
    <GridItem colSpan={6}>
      <MiniPeople />
    </GridItem>
    <GridItem colSpan={3} />
  </Grid>
);

const _MiniPost = () => (
  <Stack
    borderWidth="1px"
    borderColor="gray.50"
    p="1"
    borderRadius="lg"
    spacing="1"
  >
    <Stack direction="row" alignItems="center" spacing="1">
      <Circle backgroundColor="gray.50" size="2.5" />
      <Box backgroundColor="gray.50" h="1.5" borderRadius="lg" w="30%" />
    </Stack>
    <Box backgroundColor="gray.50" h="1.5" borderRadius="lg" w="full" />
    <Box backgroundColor="gray.50" h="1.5" borderRadius="lg" w="60%" />
    <Divider borderColor="gray.50" />
    <Box backgroundColor="gray.50" h="1.5" borderRadius="lg" w="full" />
  </Stack>
);

export const MiniCommunityPage = () => (
  <Grid
    borderWidth="1px"
    borderColor="gray.300"
    backgroundColor="gray.200"
    borderRadius="md"
    p="2"
    templateRows="repeat(2, auto)"
    templateColumns="repeat(12, 1fr)"
    gap="2"
  >
    <GridItem colSpan={3} />
    <GridItem colSpan={6}>
      <_MiniSearch />
    </GridItem>
    <GridItem colSpan={3} />
    <GridItem colSpan={3}>
      <_MiniNavigation />
    </GridItem>
    <GridItem colSpan={6}>
      <Stack>
        <_MiniPost />
        <_MiniPost />
      </Stack>
    </GridItem>
    <GridItem colSpan={3}>
      <Stack
        borderWidth="1px"
        borderColor="gray.50"
        p="1"
        borderRadius="lg"
        spacing="1"
      >
        <Box backgroundColor="gray.50" h="1.5" borderRadius="lg" />
        <Box backgroundColor="gray.50" h="1.5" borderRadius="lg" w="60%" />
      </Stack>
    </GridItem>
  </Grid>
);

const _MiniText: React.FC<BoxProps> = (props) => (
  <Box bg="gray.200" borderRadius="md" {...props} />
);

export const MiniHeading = () => <_MiniText h="4" />;

export const MiniHero = () => (
  <Center h="10" bg="gray.200" borderRadius="md">
    <ImageIcon color="gray.400" boxSize="4" />
  </Center>
);

export const MiniCarousel = () => (
  <Center
    h="10"
    bg="gray.200"
    borderRadius="md"
    justifyContent="space-between"
    px="2"
  >
    <ChevronLeftIcon color="gray.400" boxSize="4" />
    <ImageIcon color="gray.400" boxSize="4" />
    <ChevronRightIcon color="gray.400" boxSize="4" />
  </Center>
);

const _MiniImage: React.FC<CenterProps> = (props) => (
  <Center h="10" bg="gray.200" borderRadius="md" w="full" {...props}>
    <ImageIcon color="gray.400" boxSize="4" />
  </Center>
);

export const MiniImageGroup = () => (
  <Stack direction="row">
    <_MiniImage />
    <_MiniImage />
    <_MiniImage />
    <_MiniImage />
  </Stack>
);

const _MiniBlock = () => (
  <Stack
    borderRadius="md"
    borderWidth="1px"
    borderColor="gray.200"
    p="1"
    spacing="1"
    w="full"
  >
    <Center h="10" bg="gray.200" borderRadius="md">
      <ImageIcon color="gray.400" boxSize="4" />
    </Center>
    <Box h="2" bg="gray.200" borderRadius="md" />
  </Stack>
);

export const MiniBlockGroup = () => (
  <Stack direction="row">
    <_MiniBlock />
    <_MiniBlock />
    <_MiniBlock />
  </Stack>
);

export const MiniRichText = () => (
  <Stack direction="row" alignItems="center" w="full">
    <Stack flex="1" spacing="1">
      <_MiniText h="2" w="70%" />
      <_MiniText h="2" />
      <_MiniText h="2" />
      <_MiniText h="2" />
    </Stack>
    <_MiniImage h="12" w="14" />
  </Stack>
);

export const MiniBlogTemplate = () => (
  <Stack>
    <MiniHero />
    <Stack spacing="1">
      <_MiniText h="2" />
      <_MiniText h="2" />
      <_MiniText h="2" w="70%" />
      <_MiniText h="2" />
      <_MiniText h="2" />
      <_MiniText h="2" w="70%" />
    </Stack>
  </Stack>
);

export const MiniMixedContentTemplate = () => (
  <Stack>
    <MiniHero />
    <Stack spacing="1">
      <_MiniText h="2" />
      <_MiniText h="2" />
      <_MiniText h="2" w="70%" />
    </Stack>
    <MiniBlockGroup />
  </Stack>
);

export const MiniHomeTemplate = () => (
  <Stack>
    <MiniHero />
    <MiniBlockGroup />
    <MiniImageGroup />
  </Stack>
);
