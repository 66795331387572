import { DocumentNode, gql } from '@apollo/client';
import {
  Badge,
  Box,
  IconButton,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import FocusLock from 'react-focus-lock';

import { EPageRestriction } from '@/graphql-types/globalTypes';
import { useRouteMatch } from '@/hooks/useRouteMatch';
import {
  AwardIcon,
  MoreHorizontalIcon,
  SettingsIcon,
  Trash2Icon,
  UserIcon,
} from '@/imports/ui/chakra/feather';

import { PageLink_PageFragment } from './graphql-types/PageLink_PageFragment';

import { DeleteModal } from './components/DeleteModal';
import { DuplicatePageButton } from './components/DuplicatePageButton';
import { PageSettingsEditor } from './components/PageSettingsEditor';

// this allows us to open the popover relative to a target element instead
// of the <PopoverTrigger />
const Target = React.forwardRef<
  unknown,
  { targetRef: React.RefObject<unknown> }
>((props, ref) => {
  React.useImperativeHandle(ref, () => props.targetRef.current);
  return null;
});

Target.displayName = 'Target';

export const PageLink: React.FC<{
  page: PageLink_PageFragment;
  onClick: () => void;
}> & { pageFragment: DocumentNode } = (props) => {
  const router = useRouter();
  const href = `/website-builder/${props.page._id}`;
  const isRouteMatch = useRouteMatch(href);
  const settingsPopover = useDisclosure();
  const settingsPopoverTargetRef = React.useRef<HTMLDivElement>(null);
  const deleteModal = useDisclosure();

  return (
    <Box borderBottom="1px" borderColor="gray.200" bg="white">
      <LinkBox>
        <Stack
          direction="row"
          cursor="pointer"
          py="3"
          px="4"
          borderLeftWidth="3px"
          borderLeftColor={isRouteMatch ? 'purple.400' : 'transparent'}
          alignItems="center"
          spacing="4"
        >
          <Stack flex="1" spacing="0">
            <Stack direction="row" alignItems="center">
              <LinkOverlay as={NextLink} href={href} onClick={props.onClick}>
                <Text
                  textStyle="bodyStrong"
                  noOfLines={1}
                  title={props.page.name}
                  wordBreak="break-all"
                >
                  {props.page.name}
                </Text>
              </LinkOverlay>
              {props.page.isMarketingHomepage && (
                <Badge variant="solid" colorScheme="purple">
                  Home
                </Badge>
              )}
              {props.page.restriction === EPageRestriction.LOGGED_IN && (
                <Tooltip label="Only logged in users can access this page">
                  <UserIcon
                    color="gray.500"
                    boxSize="3"
                    position="relative" // this is necessary so that the tooltip still works inside a LinkBox
                  />
                </Tooltip>
              )}
              {props.page.restriction === EPageRestriction.MEMBERS_ONLY && (
                <Tooltip label="Only members can access this page">
                  <AwardIcon
                    color="yellow.500"
                    boxSize="3"
                    position="relative" // this is necessary so that the tooltip still works inside a LinkBox
                  />
                </Tooltip>
              )}
            </Stack>
            <Text
              textStyle="compact"
              color="gray.600"
              noOfLines={1}
              title={props.page.href}
              wordBreak="break-all"
            >
              {props.page.href}
            </Text>
          </Stack>
          <Box ref={settingsPopoverTargetRef}>
            <Menu>
              <MenuButton
                as={IconButton}
                size="sm"
                variant="outline"
                colorScheme="gray"
                icon={<MoreHorizontalIcon />}
                aria-label="options"
              />
              <Portal>
                <MenuList minW="none">
                  <MenuItem
                    icon={<SettingsIcon />}
                    onClick={settingsPopover.onOpen}
                  >
                    Settings
                  </MenuItem>
                  <DuplicatePageButton pageId={props.page._id} />
                  <MenuItem icon={<Trash2Icon />} onClick={deleteModal.onOpen}>
                    Delete
                    <DeleteModal
                      isOpen={deleteModal.isOpen}
                      onClose={() => {
                        deleteModal.onClose();
                      }}
                      onCompleted={() => {
                        if (isRouteMatch) {
                          router.push('/website-builder');
                        }
                      }}
                      pageName={props.page.name}
                      pageId={props.page._id}
                      pageVersion={props.page.metadata.version}
                    />
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          </Box>
        </Stack>
      </LinkBox>
      <Popover
        isLazy // don't render until opening for performance
        isOpen={settingsPopover.isOpen}
        onClose={settingsPopover.onClose}
        placement="right-start"
      >
        <PopoverTrigger>
          {/* this will render the popover relative to a target element */}
          <Target targetRef={settingsPopoverTargetRef} />
        </PopoverTrigger>
        <Portal>
          <PopoverContent shadow="2xl">
            <FocusLock returnFocus persistentFocus={false}>
              <PopoverBody p="6">
                <PageSettingsEditor
                  page={props.page}
                  onClose={settingsPopover.onClose}
                />
              </PopoverBody>
            </FocusLock>
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  );
};

PageLink.pageFragment = gql`
  fragment PageLink_PageFragment on Page {
    _id
    name
    isMarketingHomepage
    restriction
    href
    metadata {
      version
      publishedVersion
    }
    ...PageSettingsEditor_PageFragment
  }
  ${PageSettingsEditor.pageFragment}
`;
