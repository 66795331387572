import { gql, useQuery } from '@apollo/client';
import { Box, Fade, Stack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { UnexpectedError } from '@/imports/ui/chakra/components/UnexpectedError';
import { MilleFeuille, MilleFeuilleMain } from '@/layouts/MilleFeuille';
import { AlphabetSoupProvider } from '@/modules/alphabet-soup';
import { FooterRender } from '@/modules/website-builder';
import {
  WebsiteBuilderNavigationFormValues,
  WebsiteBuilderPageFormValues,
} from '@/modules/website-builder/types';
import { PAGE_FRAGMENT, TENANT_FRAGMENT } from '@/queries';

import { WebsiteBuilder_Navigation } from './graphql-types/WebsiteBuilder_Navigation';
import {
  WebsiteBuilder_Page,
  WebsiteBuilder_PageVariables,
} from './graphql-types/WebsiteBuilder_Page';
import { WebsiteBuilder_Tenant } from './graphql-types/WebsiteBuilder_Tenant';

import { AutoSave } from './components/AutoSave';
import { Header } from './components/Header';
import { IsolatedPageRender } from './components/IsolatedPageRender';
import { Sidebar } from './components/Sidebar';

const TENANT = gql`
  query WebsiteBuilder_Tenant {
    tenant {
      result {
        _id
        ...TenantFragment
      }
    }
  }
  ${TENANT_FRAGMENT}
`;

const NAVIGATION_ITEM = gql`
  fragment WebsiteBuilder_NavigationItem on NavigationItem {
    href
    isExternal
    label
    restriction
    defaultItemIdentifier
    page {
      _id
      name
      restriction
    }
    structure
  }
`;

const NAVIGATION = gql`
  query WebsiteBuilder_Navigation {
    navigation(
      input: { isUnpublishedVersion: true, isRestrictionIgnored: true }
    ) {
      items {
        ...WebsiteBuilder_NavigationItem
        children {
          ...WebsiteBuilder_NavigationItem
          children {
            ...WebsiteBuilder_NavigationItem
          }
        }
      }
      metadata {
        version
        publishedVersion
        isRestrictionIgnored
      }
    }
  }
  ${NAVIGATION_ITEM}
`;

const PAGE = gql`
  query WebsiteBuilder_Page($input: PageInput!) {
    page(input: $input) {
      _id
      ...PageFragment
      ...Sidebar_PageFragment
    }
  }
  ${PAGE_FRAGMENT}
  ${Sidebar.pageFragment}
`;

export const WebsiteBuilder: React.FC = () => {
  const router = useRouter();
  const tenantQuery = useQuery<WebsiteBuilder_Tenant>(TENANT);

  const navigationQuery = useQuery<WebsiteBuilder_Navigation>(NAVIGATION);

  const navigationMethods = useForm<WebsiteBuilderNavigationFormValues>({
    defaultValues: { items: navigationQuery.data?.navigation.items },
  });

  React.useEffect(
    () =>
      navigationMethods.reset({
        items: navigationQuery.data?.navigation.items,
      }),
    [navigationMethods, navigationQuery.data?.navigation.items]
  );

  const pageQuery = useQuery<WebsiteBuilder_Page, WebsiteBuilder_PageVariables>(
    PAGE,
    {
      variables: {
        input: {
          id: router.query.pageId?.toString(),
          isUnpublishedVersion: true,
        },
      },
      skip: !router.query.pageId,
    }
  );

  const pageMethods = useForm<WebsiteBuilderPageFormValues>({
    defaultValues: {
      content: undefined,
    },
  });

  React.useEffect(
    () =>
      pageMethods.reset({
        content: pageQuery.data?.page?.content,
      }),
    [pageMethods, pageQuery.data?.page?.content]
  );

  const isLoading =
    tenantQuery.loading || pageQuery.loading || navigationQuery.loading;

  const hasError =
    tenantQuery.error || pageQuery.error || navigationQuery.error;

  return (
    <AlphabetSoupProvider isWebsiteBuilder={true}>
      <Stack spacing="0" h="100vh">
        <Header
          pageMethods={pageMethods}
          navigationMethods={navigationMethods}
        />
        <Stack direction="row" spacing="0" flex="1" overflow="hidden">
          <Sidebar
            key={pageQuery.data?.page._id}
            pageMethods={pageMethods}
            navigationMethods={navigationMethods}
            page={pageQuery.data?.page}
          />
          <Box flex="1" p="4" bg="gray.200" zIndex="0">
            {isLoading ? null : hasError || !tenantQuery.data?.tenant.result ? (
              <UnexpectedError />
            ) : (
              <Box sx={{ zoom: 0.75 }} bg="gray.50" overflowY="auto" h="100%">
                {pageQuery.data && (
                  <AutoSave page={pageQuery.data.page} methods={pageMethods} />
                )}
                <MilleFeuille pointerEvents="none">
                  <MilleFeuilleMain>
                    <Fade in key={router.query.pageId?.toString()}>
                      <IsolatedPageRender methods={pageMethods} />
                    </Fade>
                  </MilleFeuilleMain>
                  <FooterRender
                    tenant={tenantQuery.data.tenant.result}
                    pointerEvents="none"
                  />
                </MilleFeuille>
              </Box>
            )}
          </Box>
        </Stack>
      </Stack>
    </AlphabetSoupProvider>
  );
};
