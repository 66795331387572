import {
  Button,
  Center,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import Editor from '@draft-js-plugins/editor';
import * as React from 'react';

import { AnimatedLoaderIcon } from '@/components/AnimatedLoaderIcon';
import { RichTextEditor } from '@/components/RichTextEditor';
import { CheckCircleIcon, EditIcon, XIcon } from '@/imports/ui/chakra/feather';
import { MiniRichText } from '@/modules/website-builder/components/Minis';

export const RichTextModal = React.forwardRef<
  Editor,
  {
    value: RawDraftContentState | null;
    onChange?: (value: RawDraftContentState) => void;
    onBlur?: () => void;
    isDirty: boolean;
  }
>(({ value, onChange, onBlur, isDirty }, ref) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Center
        border="1px"
        borderColor="gray.200"
        p="4"
        borderRadius="md"
        bg="gray.50"
        position="relative"
      >
        <Button
          variant="outline"
          colorScheme="gray"
          position="absolute"
          size="sm"
          leftIcon={<EditIcon />}
          onClick={onOpen}
        >
          Click to Edit
        </Button>
        <MiniRichText />
      </Center>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="3xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent h="xl">
          <ModalHeader
            borderBottom="1px"
            borderColor="gray.200"
            display="flex"
            alignItems="end"
            gap="2"
          >
            <span>Rich Text Editor</span>

            <Stack direction="row" alignItems="center" spacing="1">
              {isDirty ? (
                <AnimatedLoaderIcon boxSize="3" color="gray.600" />
              ) : (
                <CheckCircleIcon boxSize="3" color="green.500" />
              )}
              <Text fontSize="xs" fontWeight="normal" color="gray.600">
                {isDirty ? 'Saving' : 'All changes saved'}
              </Text>
            </Stack>
            <Spacer />
            <IconButton
              size="sm"
              aria-label="close"
              icon={<XIcon />}
              onClick={onClose}
              variant="ghost"
              colorScheme="purple"
            />
          </ModalHeader>
          <ModalBody h="full" px="6" py="4">
            <RichTextEditor
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
});

RichTextModal.displayName = 'RichTextModal';
