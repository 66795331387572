import { Stack } from '@chakra-ui/react';
import * as React from 'react';

import { useAlphabetSoup } from '@/modules/alphabet-soup';
import { PageContent } from '@/modules/website-builder/types';

import { BlockGroupRender } from './components/BlockGroupRender';
import { CarouselRender } from './components/CarouselRender';
import { HeadingRender } from './components/HeadingRender';
import { HeroRender } from './components/HeroRender';
import { Highlight } from './components/Highlight';
import { ImageGroupRender } from './components/ImageGroupRender';
import { RichTextRender } from './components/RichTextRender';

const SPACING = 16;

const _PageRender: React.FC<{ content: PageContent }> = ({ content }) => {
  const { highlightedContentIndex, carouselIndex } = useAlphabetSoup();

  return (
    <Stack spacing="0" alignItems="center">
      {content.map((item, index) => {
        // calculate the padding based on the surrounding content. this
        // is basically a way to manage the "margin" except we need to use
        // padding so that we can make sure the background color is correct

        const previous = content[index - 1];
        const next = content[index + 1];

        const backgroundColor =
          'backgroundColor' in item.options
            ? item.options.backgroundColor
            : undefined;

        const pt = previous
          ? previous.__typename === 'Hero'
            ? SPACING
            : previous.__typename === 'Carousel'
            ? SPACING
            : previous.options.backgroundColor === backgroundColor
            ? SPACING / 2
            : SPACING
          : SPACING;

        const pb = next
          ? next.__typename === 'Hero'
            ? SPACING
            : next.__typename === 'Carousel'
            ? SPACING
            : next.options.backgroundColor === backgroundColor
            ? SPACING / 2
            : SPACING
          : SPACING;

        if (item.__typename === 'Carousel') {
          return (
            <Highlight
              key={index}
              label={item.name || 'Carousel'}
              isActive={highlightedContentIndex.value === index}
              shouldScroll={highlightedContentIndex.shouldScroll}
            >
              <CarouselRender
                carousel={item}
                carouselIndex={
                  highlightedContentIndex.value === index
                    ? carouselIndex
                    : undefined
                }
              />
            </Highlight>
          );
        }

        if (item.__typename === 'Hero') {
          return (
            <Highlight
              key={index}
              label={item.name || 'Hero'}
              isActive={highlightedContentIndex.value === index}
              shouldScroll={highlightedContentIndex.shouldScroll}
            >
              <HeroRender hero={item} />
            </Highlight>
          );
        }

        if (item.__typename === 'BlockGroup') {
          return (
            <Highlight
              key={index}
              label={item.name || 'Card Group'}
              isActive={highlightedContentIndex.value === index}
              shouldScroll={highlightedContentIndex.shouldScroll}
            >
              <BlockGroupRender blockGroup={item} pt={pt} pb={pb} />
            </Highlight>
          );
        }

        if (item.__typename === 'ImageGroup') {
          return (
            <Highlight
              key={index}
              label={item.name || 'Image Group'}
              isActive={highlightedContentIndex.value === index}
              shouldScroll={highlightedContentIndex.shouldScroll}
            >
              <ImageGroupRender imageGroup={item} pt={pt} pb={pb} />
            </Highlight>
          );
        }

        if (item.__typename === 'Heading') {
          return (
            <Highlight
              key={index}
              label={item.name || 'Heading'}
              isActive={highlightedContentIndex.value === index}
              shouldScroll={highlightedContentIndex.shouldScroll}
            >
              <HeadingRender heading={item} pt={pt} pb={pb} />
            </Highlight>
          );
        }

        if (item.__typename === 'RichText') {
          return (
            <Highlight
              key={index}
              label={item.name || 'Rich Text'}
              isActive={highlightedContentIndex.value === index}
              shouldScroll={highlightedContentIndex.shouldScroll}
            >
              <RichTextRender richText={item} pt={pt} pb={pb} />
            </Highlight>
          );
        }

        return null;
      })}
    </Stack>
  );
};

export const PageRender = React.memo(
  _PageRender,
  (previousProps, newProps) =>
    JSON.stringify(previousProps.content) === JSON.stringify(newProps.content)
);
