import * as React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';

import { PageRender } from '@/modules/website-builder';
import { WebsiteBuilderPageFormValues } from '@/modules/website-builder/types';

export const IsolatedPageRender: React.FC<{
  methods: UseFormReturn<WebsiteBuilderPageFormValues, any>;
}> = (props) => {
  // this hook is better for performance than a regular watch. we put it
  // here inside this component to isolate the rerender of the custom page so
  // that we don't have performance issues rerendering the entire editing
  // experience on every keystroke
  const content = useWatch({
    control: props.methods.control,
    name: 'content',
  });

  return content ? <PageRender content={content} /> : null;
};
