import { gql, useMutation } from '@apollo/client';
import * as React from 'react';

import { ConfirmDialog } from '@/components/ConfirmDialog';

import {
  DeleteModal_UpdatePageSettings,
  DeleteModal_UpdatePageSettingsVariables,
} from './graphql-types/DeleteModal_UpdatePageSettings';

const UPDATE_PAGE_SETTINGS = gql`
  mutation DeleteModal_UpdatePageSettings($input: UpdatePageSettingsInput!) {
    updatePageSettings(input: $input) {
      page(input: { isUnpublishedVersion: true }) {
        _id
      }
    }
  }
`;

export const DeleteModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  pageName: string;
  pageId: string;
  pageVersion: number;
  onCompleted?: () => void;
}> = ({ isOpen, onClose, pageName, pageId, pageVersion, onCompleted }) => {
  const [updatePageSettings] = useMutation<
    DeleteModal_UpdatePageSettings,
    DeleteModal_UpdatePageSettingsVariables
  >(UPDATE_PAGE_SETTINGS, {
    variables: {
      input: {
        pageId,
        pageVersion,
        isDeleted: true,
      },
    },
    update(cache) {
      cache.evict({
        id: `ROOT_QUERY`,
        fieldName: 'pages',
      });
      cache.evict({
        id: `ROOT_QUERY`,
        fieldName: 'page',
      });
      cache.evict({
        id: `ROOT_QUERY`,
        fieldName: 'navigation',
      });
      cache.gc();
    },
    onCompleted,
  });

  return (
    <ConfirmDialog
      isOpen={isOpen}
      onClose={onClose}
      title="Delete Page"
      body={`Are you sure you want to delete the “${pageName}” page?`}
      onConfirm={updatePageSettings}
      confirmButtonText="Delete"
      confirmButtonColorScheme="purple"
    />
  );
};
