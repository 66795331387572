import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  ENavigationItemRestriction,
  ENavigationItemStructure,
  EPageRestriction,
} from '@/graphql-types/globalTypes';
import { Card } from '@/imports/ui/chakra/components/Card';
import { Semibold } from '@/imports/ui/chakra/components/Semibold';
import { BackButton } from '@/modules/website-builder/components/BackButton';
import { SearchablePageSelect } from '@/modules/website-builder/components/SearchablePageSelect';
import { NavigationItem } from '@/modules/website-builder/types';
import {
  PAGE_RESTRICTION_TO_NAVIGATION_ITEM_RESTRICTION,
  READABLE_NAVIGATION_ITEM_RESTRICTION,
  READABLE_PAGE_RESTRICTION,
} from '@/utilities';

type NewDirectLinkFormValues = {
  label: string;
  href: string | null;
  page: {
    _id: string;
    name: string;
    restriction: EPageRestriction;
  } | null;
  restriction: ENavigationItemRestriction;
  directLinkType: 'created-page' | 'link';
};

export const NewDirectLinkForm: React.FC<{
  onClickBack: () => void;
  onSubmit: (item: NavigationItem) => void;
  backText: string;
  submitText: string;
}> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    watch,
    setValue,
    getValues,
    control,
  } = useForm<NewDirectLinkFormValues>({
    defaultValues: {
      label: '',
      href: null,
      page: null,
      restriction: ENavigationItemRestriction.ANYONE,
      directLinkType: 'created-page',
    },
  });

  const directLinkType = watch('directLinkType');
  const page = watch('page');

  const onSubmit = handleSubmit((data) => {
    props.onSubmit({
      label: data.label,
      href: data.directLinkType === 'link' ? data.href : null,
      page: data.directLinkType === 'created-page' ? data.page : null,
      restriction: data.restriction,
      defaultItemIdentifier: null,
      children: [],
      structure: ENavigationItemStructure.DIRECT_LINK,
    });
  });

  React.useEffect(() => {
    if (page) {
      setValue(
        'restriction',
        PAGE_RESTRICTION_TO_NAVIGATION_ITEM_RESTRICTION[page.restriction]
      );
    }
  }, [page, setValue]);

  React.useEffect(() => {
    if (!touchedFields.label) {
      if (directLinkType === 'created-page') {
        if (page) {
          setValue('label', page.name);
        }
      } else {
        setValue('label', '');
      }
    }
  }, [directLinkType, getValues, page, setValue, touchedFields.label]);

  return (
    <Stack spacing="4">
      <BackButton onClick={props.onClickBack}>{props.backText}</BackButton>

      <Card border="1px" borderColor="gray.200" shadow="sm" p="6">
        <FormControl isInvalid={!!errors.label}>
          <FormLabel>Tab Name</FormLabel>
          <Input {...register('label', { required: 'Tab name is required' })} />
          <FormErrorMessage>{errors.label?.message}</FormErrorMessage>
        </FormControl>
      </Card>

      <Card border="1px" borderColor="gray.200" shadow="sm" p="6">
        <Stack spacing="4">
          <ButtonGroup isAttached>
            <Button
              size="sm"
              variant={
                directLinkType === 'created-page' ? undefined : 'outline'
              }
              onClick={() => setValue('directLinkType', 'created-page')}
              colorScheme={
                directLinkType === 'created-page' ? 'purple' : 'gray'
              }
              w="full"
            >
              Created Page
            </Button>
            <Button
              size="sm"
              variant={directLinkType === 'link' ? undefined : 'outline'}
              onClick={() => setValue('directLinkType', 'link')}
              colorScheme={directLinkType === 'link' ? 'purple' : 'gray'}
              w="full"
            >
              Link
            </Button>
          </ButtonGroup>

          {directLinkType === 'link' && (
            <FormControl isInvalid={!!errors.href}>
              <FormLabel>Link</FormLabel>
              <Input
                {...register('href', {
                  validate: {
                    requiredIf: (value) =>
                      getValues('directLinkType') === 'created-page' ||
                      !!value ||
                      'Link is required',
                  },
                })}
              />
              <FormErrorMessage>{errors.href?.message}</FormErrorMessage>
            </FormControl>
          )}

          {directLinkType === 'created-page' && (
            <FormControl isInvalid={!!errors.page}>
              <FormLabel>Link to</FormLabel>
              <Controller
                name="page"
                control={control}
                rules={{
                  validate: {
                    requiredIf: (value) =>
                      getValues('directLinkType') === 'link' ||
                      !!value ||
                      'Link is required',
                  },
                }}
                render={({ field }) => <SearchablePageSelect {...field} />}
              />
              <FormErrorMessage>{errors.page?.message}</FormErrorMessage>
            </FormControl>
          )}

          <FormControl isDisabled={directLinkType === 'created-page'}>
            <FormLabel>Visible to</FormLabel>
            <Select {...register('restriction')}>
              <option value={ENavigationItemRestriction.ANYONE}>
                {
                  READABLE_NAVIGATION_ITEM_RESTRICTION[
                    ENavigationItemRestriction.ANYONE
                  ]
                }
              </option>
              <option value={ENavigationItemRestriction.LOGGED_IN}>
                {
                  READABLE_NAVIGATION_ITEM_RESTRICTION[
                    ENavigationItemRestriction.LOGGED_IN
                  ]
                }
              </option>
              <option value={ENavigationItemRestriction.MEMBERS_ONLY}>
                {
                  READABLE_NAVIGATION_ITEM_RESTRICTION[
                    ENavigationItemRestriction.MEMBERS_ONLY
                  ]
                }
              </option>
              <option value={ENavigationItemRestriction.NOBODY}>
                {
                  READABLE_NAVIGATION_ITEM_RESTRICTION[
                    ENavigationItemRestriction.NOBODY
                  ]
                }
              </option>
            </Select>
            {directLinkType === 'created-page' && page && (
              <Text color="gray.500" textStyle="detail" mt="4">
                The page permission is set to{' '}
                <Semibold textTransform="lowercase">
                  {READABLE_PAGE_RESTRICTION[page.restriction]}
                </Semibold>
                . To change that, please{' '}
                <Link
                  as={NextLink}
                  href={`/website-builder/${page._id}?tabIndex=2`}
                  textDecoration="underline"
                >
                  go to the page setting
                </Link>
                .
              </Text>
            )}
          </FormControl>
        </Stack>
      </Card>

      <Button colorScheme="purple" onClick={onSubmit} size="sm">
        {props.submitText}
      </Button>
    </Stack>
  );
};
