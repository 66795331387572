import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  Select,
  SlideFade,
  Stack,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { UseFormReturn, useFieldArray, useWatch } from 'react-hook-form';

import { ECarouselLinkBehavior } from '@/graphql-types/globalTypes';
import { Card } from '@/imports/ui/chakra/components/Card';
import { PlusIcon } from '@/imports/ui/chakra/feather';
import { useAlphabetSoup } from '@/modules/alphabet-soup';
import { BackButton } from '@/modules/website-builder/components/BackButton';
import { DEFAULT_CAROUSEL_ITEM_CONTENT } from '@/modules/website-builder/default-content';
import { WebsiteBuilderPageFormValues } from '@/modules/website-builder/types';

import { ContentItem } from '../ContentItem';
import { CarouselItemEditor } from './components/CarouselItemEditor';
import {
  SpecialFormControl,
  SpecialFormLabel,
  SpecialSwitch,
} from './components/Special';

export const CarouselEditor: React.FC<{
  index: number;
  defaultName: string;
  onClickBack: () => void;
  methods: UseFormReturn<WebsiteBuilderPageFormValues, any>;
}> = (props) => {
  const { setCarouselIndex } = useAlphabetSoup();

  const { control, register, setValue } = props.methods;

  const carouselItemsFieldArray = useFieldArray({
    control,
    name: `content.${props.index}.carouselItems`,
  });

  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const variant = useWatch({
    control,
    name: `content.${props.index}.variant`,
  });

  const isCarouselAutoChangeEnabled = useWatch({
    control,
    name: `content.${props.index}.options.isCarouselAutoChangeEnabled`,
  });

  React.useEffect(() => {
    if (activeIndex !== null) {
      setCarouselIndex(activeIndex);
    }
  }, [activeIndex, setCarouselIndex]);

  React.useEffect(() => {
    if (variant === 2) {
      setValue(
        `content.${props.index}.options.carouselItemLinkBehavior`,
        ECarouselLinkBehavior.BUTTON
      );
    }
  }, [props.index, setValue, variant]);

  return (
    <Stack spacing="4">
      {activeIndex === null && (
        <SlideFade in>
          <Stack spacing="4">
            <BackButton onClick={props.onClickBack}>
              Back to all components
            </BackButton>

            <Card as={Stack} spacing="4" p="6">
              <Text textStyle="bodyStrong">Component Settings</Text>

              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  placeholder="Carousel"
                  {...register(`content.${props.index}.name`)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Variant</FormLabel>
                <Select
                  {...register(`content.${props.index}.variant`, {
                    valueAsNumber: true,
                  })}
                >
                  <option value="1">Card</option>
                  <option value="2">Blend in</option>
                  <option value="3">Image</option>
                </Select>
              </FormControl>

              <Text textStyle="compactStrong">Autoplay</Text>

              <SpecialFormControl>
                <SpecialFormLabel>Enabled</SpecialFormLabel>
                <SpecialSwitch
                  {...register(
                    `content.${props.index}.options.isCarouselAutoChangeEnabled`
                  )}
                />
              </SpecialFormControl>

              {isCarouselAutoChangeEnabled && (
                <FormControl
                  bg="gray.50"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  py="2"
                  px="4"
                  borderRadius="md"
                  gap="4"
                >
                  <FormLabel fontWeight="normal" mb="0">
                    Speed
                  </FormLabel>
                  <Select
                    bg="white"
                    {...register(
                      `content.${props.index}.options.carouselAutoChangeSpeed`,
                      {
                        valueAsNumber: true,
                      }
                    )}
                  >
                    <option value="5000">5 Seconds</option>
                    <option value="10000">10 Seconds</option>
                  </Select>
                </FormControl>
              )}
            </Card>

            {variant === 1 && (
              <Card as={Stack} spacing="4" p="6">
                <Text textStyle="bodyStrong">Carousel Item Settings</Text>
                <FormControl>
                  <FormLabel>Link Option</FormLabel>
                  <Select
                    {...register(
                      `content.${props.index}.options.carouselItemLinkBehavior`
                    )}
                  >
                    <option value={ECarouselLinkBehavior.CARD}>
                      Card link
                    </option>
                    <option value={ECarouselLinkBehavior.BUTTON}>Button</option>
                  </Select>
                </FormControl>
              </Card>
            )}

            <DragDropContext
              onDragEnd={(result) => {
                if (result.destination) {
                  carouselItemsFieldArray.move(
                    result.source.index,
                    result.destination.index
                  );
                }
              }}
            >
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <Box ref={provided.innerRef} {...provided.droppableProps}>
                    {carouselItemsFieldArray.fields.map((field, index) => {
                      return (
                        <ContentItem
                          key={field.id}
                          index={index}
                          namePath={`content.${props.index}.carouselItems.${index}.name`}
                          defaultName="Carousel Item"
                          onClick={() => setActiveIndex(index)}
                          onDelete={() => carouselItemsFieldArray.remove(index)}
                          methods={props.methods}
                          pointerEvents={
                            snapshot.isDraggingOver ? 'none' : undefined
                          }
                          onMouseEnter={() => {
                            setCarouselIndex(index);
                          }}
                          onMouseLeave={() => {
                            setCarouselIndex(undefined);
                          }}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Stack>
        </SlideFade>
      )}

      {carouselItemsFieldArray.fields.map((field, index) => {
        return activeIndex === index ? (
          <SlideFade in key={index}>
            <CarouselItemEditor
              parentIndex={props.index}
              index={index}
              defaultName="Carousel Item"
              onClickBack={() => setActiveIndex(null)}
              methods={props.methods}
            />
          </SlideFade>
        ) : null;
      })}

      {activeIndex === null && (
        <SlideFade in>
          <Center>
            <Button
              colorScheme="purple"
              leftIcon={<PlusIcon />}
              size="sm"
              onClick={() =>
                carouselItemsFieldArray.append(DEFAULT_CAROUSEL_ITEM_CONTENT)
              }
            >
              Add a New Carousel Item
            </Button>
          </Center>
        </SlideFade>
      )}
    </Stack>
  );
};
