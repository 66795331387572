import { Box, SlideFade } from '@chakra-ui/react';
import * as React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FormProvider, UseFormReturn, useFieldArray } from 'react-hook-form';

import { WebsiteBuilderNavigationFormValues } from '@/modules/website-builder/types';

import { AutoSave } from './components/AutoSave';
import { ChooseFormType } from './components/ChooseFormType';
import { NavigationItem } from './components/NavigationItem';
import { NavigationItemEditor } from './components/NavigationItemEditor';
import { NewDirectLinkForm } from './components/NewDirectLinkForm';
import { NewDropdownMenuForm } from './components/NewDropdownMenuForm';

export const NavigationEditor: React.FC<{
  methods: UseFormReturn<WebsiteBuilderNavigationFormValues, any>;
}> = ({ methods }) => {
  const itemsFieldArray = useFieldArray({
    control: methods.control,
    name: 'items',
  });

  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);
  const [activeForm, setActiveForm] = React.useState<
    'direct-link' | 'dropdown-menu' | null
  >(null);

  return (
    <Box p="4">
      <AutoSave methods={methods} />

      {activeIndex === null && activeForm === null && (
        <SlideFade in>
          <DragDropContext
            onDragEnd={(result) => {
              if (result.destination) {
                itemsFieldArray.move(
                  result.source.index,
                  result.destination.index
                );
              }
            }}
          >
            <Droppable droppableId="droppable">
              {(provided) => (
                <Box ref={provided.innerRef} {...provided.droppableProps}>
                  {itemsFieldArray.fields.map((field, index) => {
                    return (
                      <NavigationItem
                        key={index}
                        index={index}
                        field={field}
                        onClick={() => setActiveIndex(index)}
                      />
                    );
                  })}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </SlideFade>
      )}

      {itemsFieldArray.fields.map((field, index) => {
        return activeIndex === index ? (
          <SlideFade key={index} in>
            <NavigationItemEditor
              index={index}
              field={field}
              onClickBack={() => setActiveIndex(null)}
              onDelete={() => {
                itemsFieldArray.remove(index);
                setActiveIndex(null);
              }}
              methods={methods}
            />
          </SlideFade>
        ) : null;
      })}

      {activeIndex === null && activeForm === null && (
        <SlideFade in>
          <ChooseFormType
            onChoose={(value) => setActiveForm(value)}
            onCancel={() => setActiveForm(null)}
          />
        </SlideFade>
      )}

      {activeForm === 'direct-link' && (
        <SlideFade in>
          <NewDirectLinkForm
            onClickBack={() => setActiveForm(null)}
            backText="Back to all tabs"
            onSubmit={(item) => {
              itemsFieldArray.append(item);
              setActiveForm(null);
            }}
            submitText="Add Tab"
          />
        </SlideFade>
      )}

      {activeForm === 'dropdown-menu' && (
        <SlideFade in>
          <NewDropdownMenuForm
            onClickBack={() => setActiveForm(null)}
            onSubmit={(item) => {
              itemsFieldArray.append(item);
              setActiveForm(null);
            }}
          />
        </SlideFade>
      )}
    </Box>
  );
};
