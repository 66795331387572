import { UseFormReturn, useWatch } from 'react-hook-form';

import { WebsiteBuilderPageFormValues } from '@/modules/website-builder/types';

export const ParentName: React.FC<{
  parentIndex: number;
  default: string;
  methods: UseFormReturn<WebsiteBuilderPageFormValues, any>;
}> = (props) => {
  const parentName = useWatch<WebsiteBuilderPageFormValues>({
    control: props.methods.control,
    name: `content.${props.parentIndex}.name`,
  });

  return <>{parentName || props.default}</>;
};
