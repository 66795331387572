import { DocumentNode, gql } from '@apollo/client';
import {
  Box,
  IconButton,
  IconButtonProps,
  SlideFade,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  useTab,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
  Edit2Icon,
  ListIcon,
  NavigationIcon,
} from '@/imports/ui/chakra/feather';
import {
  WebsiteBuilderNavigationFormValues,
  WebsiteBuilderPageFormValues,
} from '@/modules/website-builder/types';

import { Sidebar_PageFragment } from './graphql-types/Sidebar_PageFragment';

import { NavigationEditor } from './components/NavigationEditor';
import { PageContentEditor } from './components/PageContentEditor';
import { PageList } from './components/PageList';

const IconTab = React.forwardRef<HTMLElement, IconButtonProps>((props, ref) => {
  const tabProps = useTab({ ...props, ref });
  const isSelected = !!tabProps['aria-selected'];

  return (
    <IconButton
      variant={isSelected ? 'solid' : 'outline'}
      colorScheme="gray"
      icon={<Edit2Icon />}
      {...props}
      {...tabProps}
    />
  );
});

IconTab.displayName = 'IconTab';

export const Sidebar: React.FC<{
  pageMethods: UseFormReturn<WebsiteBuilderPageFormValues, any>;
  navigationMethods: UseFormReturn<WebsiteBuilderNavigationFormValues, any>;
  page?: Sidebar_PageFragment;
}> & { pageFragment: DocumentNode } = (props) => {
  const router = useRouter();
  const [index, setIndex] = React.useState(router.query.pageId ? 2 : 0);

  React.useEffect(() => {
    if (router.query.tabIndex) {
      setIndex(Number(router.query.tabIndex));
      const { tabIndex, ...query } = router.query;
      router.replace({ pathname: router.pathname, query });
    }
  }, [router]);

  return (
    <Tabs index={index} onChange={setIndex}>
      <Stack direction="row" spacing="0" h="full" overflow="hidden">
        <Stack
          spacing="4"
          p="4"
          h="full"
          borderRight="1px"
          borderRightColor="gray.200"
          bg="white"
        >
          <IconTab icon={<ListIcon />} aria-label="toggle page list" />
          <IconTab
            icon={<NavigationIcon />}
            aria-label="toggle navigation editor"
          />
          <IconTab
            icon={<Edit2Icon />}
            aria-label="toggle page content editor"
            isDisabled={!props.page}
          />
        </Stack>

        <Box
          h="full"
          w="xs"
          bg="gray.50"
          borderRight="1px"
          borderRightColor="gray.200"
          overflow="auto"
        >
          <TabPanels>
            <TabPanel>
              <SlideFade in={index === 0}>
                <PageList
                  onClickPageLink={(pageId) => {
                    if (router.query.pageId === pageId) {
                      setIndex(2);
                    }
                  }}
                />
              </SlideFade>
            </TabPanel>
            <TabPanel>
              <SlideFade in={index === 1}>
                <NavigationEditor methods={props.navigationMethods} />
              </SlideFade>
            </TabPanel>
            <TabPanel>
              {props.page && (
                <SlideFade in={index === 2}>
                  <PageContentEditor
                    key={props.page._id}
                    methods={props.pageMethods}
                    page={props.page}
                    onClickPageLink={() => setIndex(0)}
                  />
                </SlideFade>
              )}
            </TabPanel>
          </TabPanels>
        </Box>
      </Stack>
    </Tabs>
  );
};

Sidebar.pageFragment = gql`
  fragment Sidebar_PageFragment on Page {
    _id
    ...PageContentEditor_PageFragment
  }
  ${PageContentEditor.pageFragment}
`;
