import { gql, useMutation } from '@apollo/client';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  StackProps,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { BigRadio, BigRadioGroup } from '@/components/BigRadio';
import {
  EBlockGroupImageAspectRatio,
  EPageContentBackgroundColor,
  EPageRestriction,
} from '@/graphql-types/globalTypes';
import { Card } from '@/imports/ui/chakra/components/Card';
import { BackButton } from '@/modules/website-builder/components/BackButton';
import {
  MiniBlogTemplate,
  MiniHomeTemplate,
  MiniMixedContentTemplate,
} from '@/modules/website-builder/components/Minis';
import { PageRestrictionSelect } from '@/modules/website-builder/components/PageRestrictionSelect';
import {
  DEFAULT_BLOCK_CONTENT,
  DEFAULT_BLOCK_GROUP_CONTENT,
  DEFAULT_HEADING_CONTENT,
  DEFAULT_HERO_CONTENT,
  DEFAULT_IMAGE_GROUP_CONTENT,
  DEFAULT_RICH_TEXT_CONTENT,
} from '@/modules/website-builder/default-content';
import { PageContent } from '@/modules/website-builder/types';
import { convertPageContentToPageContentInput } from '@/modules/website-builder/utilities';

import {
  CreateNewPage_CreatePage,
  CreateNewPage_CreatePageVariables,
} from './graphql-types/CreateNewPage_CreatePage';

enum TEMPLATE {
  MIXED_CONTENT = 'mixed-content',
  HOME = 'home',
  EMPTY_PAGE = 'empty-page',
  BLOG = 'blog',
}

const CONTENT: Record<TEMPLATE, PageContent> = {
  [TEMPLATE.BLOG]: [
    { ...DEFAULT_HERO_CONTENT, variant: 2 },
    DEFAULT_RICH_TEXT_CONTENT,
  ],
  [TEMPLATE.MIXED_CONTENT]: [
    { ...DEFAULT_HERO_CONTENT, variant: 2 },
    DEFAULT_RICH_TEXT_CONTENT,
    {
      ...DEFAULT_HEADING_CONTENT,
      options: {
        ...DEFAULT_HEADING_CONTENT.options,
        backgroundColor: EPageContentBackgroundColor.TRANSPARENT,
      },
    },
    {
      ...DEFAULT_BLOCK_GROUP_CONTENT,
      options: {
        ...DEFAULT_BLOCK_GROUP_CONTENT.options,
        columns: 2,
        blockImageAspectRatio: EBlockGroupImageAspectRatio.VERY_HORIZONTAL,
        backgroundColor: EPageContentBackgroundColor.TRANSPARENT,
      },
      blocks: [DEFAULT_BLOCK_CONTENT, DEFAULT_BLOCK_CONTENT],
    },
    DEFAULT_HEADING_CONTENT,
    {
      ...DEFAULT_BLOCK_GROUP_CONTENT,
      options: {
        ...DEFAULT_BLOCK_GROUP_CONTENT.options,
        isBlockImageEnabled: false,
      },
    },
    {
      ...DEFAULT_HEADING_CONTENT,
      options: {
        ...DEFAULT_HEADING_CONTENT.options,
        backgroundColor: EPageContentBackgroundColor.TRANSPARENT,
      },
    },
    {
      ...DEFAULT_IMAGE_GROUP_CONTENT,
      options: {
        ...DEFAULT_IMAGE_GROUP_CONTENT.options,
        backgroundColor: EPageContentBackgroundColor.TRANSPARENT,
      },
    },
  ],
  [TEMPLATE.HOME]: [
    DEFAULT_HERO_CONTENT,
    DEFAULT_HEADING_CONTENT,
    DEFAULT_BLOCK_GROUP_CONTENT,
    { ...DEFAULT_HERO_CONTENT, variant: 2 },
    {
      ...DEFAULT_HEADING_CONTENT,
      options: {
        ...DEFAULT_HEADING_CONTENT.options,
        backgroundColor: EPageContentBackgroundColor.TRANSPARENT,
      },
    },
    {
      ...DEFAULT_BLOCK_GROUP_CONTENT,
      options: {
        ...DEFAULT_BLOCK_GROUP_CONTENT.options,
        isBlockImageEnabled: false,
        backgroundColor: EPageContentBackgroundColor.TRANSPARENT,
      },
    },
    DEFAULT_HEADING_CONTENT,
    DEFAULT_IMAGE_GROUP_CONTENT,
  ],
  [TEMPLATE.EMPTY_PAGE]: [],
};

const CREATE_PAGE = gql`
  mutation CreateNewPage_CreatePage($input: CreatePageInput!) {
    createPage(input: $input) {
      page(input: { isUnpublishedVersion: true }) {
        _id
      }
    }
  }
`;

const defaultValues = {
  name: '',
  slug: '',
  restriction: EPageRestriction.ANYONE,
  template: TEMPLATE.EMPTY_PAGE,
};

export const CreateNewPage: React.FC<StackProps & { onCancel: () => void }> = ({
  onCancel,
  ...props
}) => {
  const toast = useToast();
  const router = useRouter();
  const [createPage] = useMutation<
    CreateNewPage_CreatePage,
    CreateNewPage_CreatePageVariables
  >(CREATE_PAGE, {
    // TODO(website-builder-v2): this should invalidate the entire "pages" query
    refetchQueries: ['PageList_Pages'],
    awaitRefetchQueries: true,
  });

  const {
    register,
    handleSubmit,
    formState: { touchedFields, isSubmitting, errors, isSubmitted, isValid },
    watch,
    setValue,
    control,
  } = useForm<{
    name: string;
    slug: string;
    restriction: EPageRestriction;
    template: TEMPLATE;
  }>({
    defaultValues,
  });

  const name = watch('name');

  React.useEffect(() => {
    if (!touchedFields.slug) {
      setValue(
        'slug',
        name
          .replace(/[^a-zA-Z\d\s:]/g, '')
          .toLowerCase()
          .split(' ')
          .join('-')
      );
    }
  }, [touchedFields.slug, name, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const result = await createPage({
        variables: {
          input: {
            name: data.name,
            slug: data.slug,
            restriction: data.restriction,
            content: convertPageContentToPageContentInput(
              CONTENT[data.template]
            ),
          },
        },
      });

      await router.push(`/website-builder/${result.data?.createPage.page._id}`);
    } catch (err) {
      err?.message && toast({ status: 'error', description: err.message });
    }
  });

  return (
    <Stack
      spacing="4"
      p="4"
      w="full"
      as="form"
      noValidate
      onSubmit={onSubmit}
      {...props}
    >
      <BackButton onClick={onCancel}>Back to All Pages</BackButton>
      <Card p="6">
        <FormControl isDisabled={isSubmitting} isInvalid={!!errors.name}>
          <FormLabel>Page Name</FormLabel>
          <Input
            autoFocus
            {...register('name', {
              required: 'Name is required',
            })}
          />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>
      </Card>
      <Card p="6">
        <Stack spacing="4">
          <FormControl isDisabled={isSubmitting} isInvalid={!!errors.slug}>
            <FormLabel>URL Slug</FormLabel>
            <Input
              {...register('slug', {
                required: 'URL slug is required',
              })}
            />
            <FormErrorMessage>{errors.slug?.message}</FormErrorMessage>
          </FormControl>
          <FormControl
            isDisabled={isSubmitting}
            isInvalid={!!errors.restriction}
          >
            <FormLabel>Who Can View</FormLabel>
            <Controller
              control={control}
              name="restriction"
              render={({ field }) => <PageRestrictionSelect {...field} />}
            />
            <FormErrorMessage>{errors.restriction?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
      </Card>
      <Card as={Stack} spacing="4" p="6">
        <FormControl>
          <FormLabel>Choose a Template</FormLabel>
          <Controller
            control={control}
            name="template"
            render={({ field }) => (
              <BigRadioGroup {...field} colorScheme="purple">
                <BigRadio value={TEMPLATE.BLOG}>
                  <Text
                    textStyle="detailStrong"
                    color="gray.500"
                    textTransform="uppercase"
                    mb="1"
                  >
                    Blog
                  </Text>
                  <MiniBlogTemplate />
                </BigRadio>
                <BigRadio value={TEMPLATE.MIXED_CONTENT}>
                  <Text
                    textStyle="detailStrong"
                    color="gray.500"
                    textTransform="uppercase"
                    mb="1"
                  >
                    Mixed Content
                  </Text>
                  <MiniMixedContentTemplate />
                </BigRadio>
                <BigRadio value={TEMPLATE.HOME}>
                  <Text
                    textStyle="detailStrong"
                    color="gray.500"
                    textTransform="uppercase"
                    mb="1"
                  >
                    Home
                  </Text>
                  <MiniHomeTemplate />
                </BigRadio>
                <BigRadio value={TEMPLATE.EMPTY_PAGE}>
                  <Text
                    textStyle="detailStrong"
                    color="gray.500"
                    textTransform="uppercase"
                  >
                    Empty Page
                  </Text>
                </BigRadio>
              </BigRadioGroup>
            )}
          />
        </FormControl>
      </Card>
      <Button
        type="submit"
        w="full"
        size="sm"
        isLoading={isSubmitting}
        isDisabled={isSubmitted && !isValid}
        colorScheme="purple"
      >
        Add Page
      </Button>
    </Stack>
  );
};
