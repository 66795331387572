import {
  Box,
  Circle,
  Stack,
  Text,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import * as React from 'react';

import { ExternalLinkIcon } from '@/imports/ui/chakra/feather';

export const FormTypeSelect: React.FC<{
  value: 'direct-link' | 'dropdown-menu';
  onChange: (value: 'direct-link' | 'dropdown-menu') => void;
  onBlur?: () => void;
}> = ({ value, onChange, onBlur }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({ value, onChange });
  const group = getRootProps();

  return (
    <Stack spacing="2" {...group} onBlur={onBlur}>
      <FormTypeOption value="direct-link" getRadioProps={getRadioProps} />
      <FormTypeOption value="dropdown-menu" getRadioProps={getRadioProps} />
    </Stack>
  );
};

const FormTypeOption: React.FC<{
  value: 'direct-link' | 'dropdown-menu';
  getRadioProps: ReturnType<typeof useRadioGroup>['getRadioProps'];
  isDisabled?: boolean;
}> = ({ value, getRadioProps, isDisabled }) => {
  const radioProps = getRadioProps({ value });
  const { getInputProps, getCheckboxProps, state } = useRadio(radioProps);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box
      as="label"
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      opacity={isDisabled ? 0.5 : undefined}
    >
      <input {...input} />
      <Stack
        {...checkbox}
        direction="row"
        alignItems="center"
        p="4"
        spacing="4"
        borderWidth="1px"
        borderColor={state.isChecked ? 'purple.300' : 'gray.200'}
        shadow={state.isChecked ? 'md' : undefined}
        borderRadius="xl"
        bg="white"
      >
        <Circle
          borderWidth={state.isChecked ? '6px' : '2px'}
          borderColor={state.isChecked ? 'purple.400' : 'gray.200'}
          size="5"
        />
        <Stack flex="1" spacing="1">
          <Text
            textStyle="detailStrong"
            color="gray.500"
            textTransform="uppercase"
          >
            {
              {
                'direct-link': 'Direct Link',
                'dropdown-menu': 'Dropdown Menu',
              }[value]
            }
          </Text>
          {value === 'direct-link' && (
            <Stack direction="row" alignItems="center">
              <Box h="4" bg="gray.200" borderRadius="md" w="full" />
              <ExternalLinkIcon boxSize="4" color="gray.400" />
            </Stack>
          )}
          {value === 'dropdown-menu' && (
            <Stack>
              <Box h="4" bg="gray.200" borderRadius="md" />
              <Stack
                borderWidth="1px"
                borderColor="gray.300"
                borderRadius="md"
                p="2"
              >
                <Box h="2" bg="gray.200" borderRadius="md" />
                <Box h="2" bg="gray.200" borderRadius="md" />
                <Box h="2" bg="gray.200" borderRadius="md" />
                <Box h="2" bg="gray.200" borderRadius="md" />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
