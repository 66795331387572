import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps,
  HStack,
} from '@chakra-ui/react';
import * as React from 'react';

export const ConfirmDialog: React.FC<{
  body: string;
  confirmButtonText: string;
  cancelButtonText?: string;
  alternativeButtonText?: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: null | (() => Promise<unknown> | void);
  onClickAlternativeOption?: () => Promise<unknown> | void;
  title: string;
  shouldHideCancelButton?: boolean;
  shouldCloseOnConfirmation?: boolean;
  confirmButtonColorScheme?: ButtonProps['colorScheme'];
}> = ({
  body,
  confirmButtonText,
  cancelButtonText,
  isOpen,
  onClose,
  onConfirm,
  shouldHideCancelButton,
  title,
  shouldCloseOnConfirmation = true,
  confirmButtonColorScheme,
  alternativeButtonText,
  onClickAlternativeOption,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const cancelButton = React.useRef(null);

  const onClickConfirm = async () => {
    if (onConfirm === null) {
      onClose();
    } else {
      setIsLoading(true);
      await onConfirm();
      if (shouldCloseOnConfirmation) {
        onClose();
      }
      setIsLoading(false);
    }
  };

  return (
    <AlertDialog
      leastDestructiveRef={cancelButton}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>{title}</AlertDialogHeader>

        <AlertDialogCloseButton />

        <AlertDialogBody>{body}</AlertDialogBody>

        <AlertDialogFooter>
          <HStack spacing="4">
            {shouldHideCancelButton ? null : (
              <Button
                ref={cancelButton}
                variant="outline"
                colorScheme="gray"
                onClick={onClose}
                isDisabled={isLoading}
              >
                {cancelButtonText ?? 'Cancel'}
              </Button>
            )}

            {alternativeButtonText && (
              <Button
                ref={cancelButton}
                variant="outline"
                colorScheme="gray"
                onClick={onClickAlternativeOption}
                isDisabled={isLoading}
              >
                {alternativeButtonText}
              </Button>
            )}

            <Button
              onClick={onClickConfirm}
              isLoading={isLoading}
              // not an ideal way to do this but i'm not sure what else to do
              colorScheme={confirmButtonColorScheme}
            >
              {confirmButtonText}
            </Button>
          </HStack>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
