import { DocumentNode, gql, useMutation } from '@apollo/client';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { EPageRestriction } from '@/graphql-types/globalTypes';
import { PageRestrictionSelect } from '@/modules/website-builder/components/PageRestrictionSelect';

import { PageSettingsEditor_PageFragment } from './graphql-types/PageSettingsEditor_PageFragment';
import {
  PageSettingsEditor_UpdatePageSettings,
  PageSettingsEditor_UpdatePageSettingsVariables,
} from './graphql-types/PageSettingsEditor_UpdatePageSettings';

const PAGE_FRAGMENT = gql`
  fragment PageSettingsEditor_PageFragment on Page {
    _id
    name
    slug
    restriction
    isMarketingHomepage
    href
    metadata {
      version
      publishedVersion
    }
  }
`;

const UPDATE_PAGE_SETTINGS = gql`
  mutation PageSettingsEditor_UpdatePageSettings(
    $input: UpdatePageSettingsInput!
  ) {
    updatePageSettings(input: $input) {
      page(input: { isUnpublishedVersion: true }) {
        _id
        ...PageSettingsEditor_PageFragment
      }
    }
  }
  ${PAGE_FRAGMENT}
`;

// this is extracted into its own component so it can be lazy loaded. there
// are potentially a lot of these on the screen at one time which causes
// performance problems.
type PageSettingsEditorFormValues = {
  name: string;
  slug: string;
  restriction: EPageRestriction;
  isMarketingHomepage: boolean;
};

export const PageSettingsEditor: React.FC<{
  page: PageSettingsEditor_PageFragment;
  onClose: () => void;
}> & {
  pageFragment: DocumentNode;
} = (props) => {
  const toast = useToast();

  const [updatePageSettings] = useMutation<
    PageSettingsEditor_UpdatePageSettings,
    PageSettingsEditor_UpdatePageSettingsVariables
  >(UPDATE_PAGE_SETTINGS);

  const defaultValues = React.useMemo(() => {
    return {
      name: props.page.name,
      slug: props.page.slug,
      restriction: props.page.restriction,
      isMarketingHomepage: props.page.isMarketingHomepage,
    };
  }, [
    props.page.isMarketingHomepage,
    props.page.restriction,
    props.page.name,
    props.page.slug,
  ]);

  const {
    control,
    register,
    formState: { errors, isSubmitted, isSubmitting, isValid },
    reset,
    handleSubmit,
  } = useForm<PageSettingsEditorFormValues>({ defaultValues });

  React.useEffect(() => reset(defaultValues), [defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updatePageSettings({
        variables: {
          input: {
            pageId: props.page._id,
            pageVersion: props.page.metadata.version,
            restriction: data.restriction,
            isMarketingHomepage: data.isMarketingHomepage,
            slug: data.slug,
            name: data.name,
          },
        },
      });
    } catch (e) {
      toast({ status: 'error', description: e.message });
    }
    props.onClose();
  });

  return (
    <Stack spacing="4" as="form" noValidate onSubmit={onSubmit}>
      <Text textStyle="bodyStrong">Settings</Text>
      <FormControl isInvalid={!!errors.name} isDisabled={isSubmitting}>
        <FormLabel>Name</FormLabel>
        <Input {...register('name')} />
        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.slug} isDisabled={isSubmitting}>
        <FormLabel>URL Slug</FormLabel>
        <InputGroup>
          <InputLeftAddon>/site/</InputLeftAddon>
          <Input {...register('slug')} />
        </InputGroup>
        <FormErrorMessage>{errors.slug?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.restriction} isDisabled={isSubmitting}>
        <FormLabel>Who Can View</FormLabel>
        <Controller
          control={control}
          name="restriction"
          render={({ field }) => <PageRestrictionSelect {...field} />}
        />
        <FormErrorMessage>{errors.restriction?.message}</FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={!!errors.isMarketingHomepage}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        isDisabled={isSubmitting}
      >
        <FormLabel mb="0">Make it the Home Page</FormLabel>
        <Controller
          control={control}
          name="isMarketingHomepage"
          render={({ field: { value, onChange, onBlur, ref } }) => (
            <Switch
              colorScheme="purple"
              isChecked={value}
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
            />
          )}
        />
        <FormErrorMessage>
          {errors.isMarketingHomepage?.message}
        </FormErrorMessage>
      </FormControl>
      <Stack direction="row" spacing="4">
        <Button
          colorScheme="gray"
          variant="outline"
          flex="1"
          onClick={props.onClose}
          size="sm"
          isDisabled={isSubmitting}
          w="full"
        >
          Cancel
        </Button>
        <Button
          colorScheme="purple"
          flex="1"
          size="sm"
          type="submit"
          isDisabled={isSubmitted && !isValid}
          isLoading={isSubmitting}
          w="full"
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

PageSettingsEditor.pageFragment = PAGE_FRAGMENT;
