import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Text,
  Textarea,
  VisuallyHidden,
} from '@chakra-ui/react';
import * as React from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';

import { ButtonRadio, ButtonRadioGroup } from '@/components/ButtonRadio';
import {
  ECarouselLinkBehavior,
  ELinkSource,
} from '@/graphql-types/globalTypes';
import { Card } from '@/imports/ui/chakra/components/Card';
import { BackButton } from '@/modules/website-builder/components/BackButton';
import { SearchablePageSelect } from '@/modules/website-builder/components/SearchablePageSelect';
import { WebsiteBuilderPageFormValues } from '@/modules/website-builder/types';

import { ImagePicker } from '../../ImagePicker';
import { ParentName } from '../../ParentName';

export const CarouselItemEditor: React.FC<{
  parentIndex: number;
  index: number;
  defaultName: string;
  onClickBack: () => void;
  methods: UseFormReturn<WebsiteBuilderPageFormValues, any>;
}> = (props) => {
  const { control, register } = props.methods;

  const carouselItemLinkBehavior = useWatch({
    control,
    name: `content.${props.parentIndex}.options.carouselItemLinkBehavior`,
  });

  const isCarouselItemSubtitleEnabled = useWatch({
    control,
    name: `content.${props.parentIndex}.options.isCarouselItemSubtitleEnabled`,
  });

  const isCarouselItemTitleEnabled = useWatch({
    control,
    name: `content.${props.parentIndex}.options.isCarouselItemTitleEnabled`,
  });

  const isCarouselItemBodyTextEnabled = useWatch({
    control,
    name: `content.${props.parentIndex}.options.isCarouselItemBodyTextEnabled`,
  });

  const linkSource = useWatch({
    control,
    name: `content.${props.parentIndex}.carouselItems.${props.index}.linkSource`,
  });

  const imageProxy = useWatch({
    control,
    name: `content.${props.parentIndex}.carouselItems.${props.index}.imageProxy`,
  });

  const variant = useWatch({
    control,
    name: `content.${props.parentIndex}.variant`,
  });

  return (
    <Stack spacing="4">
      <BackButton onClick={props.onClickBack}>
        Back to{' '}
        <ParentName
          parentIndex={props.parentIndex}
          default="Carousel"
          methods={props.methods}
        />
      </BackButton>

      <Card as={Stack} spacing="4" p="6">
        <Text textStyle="bodyStrong">Settings</Text>

        <FormControl>
          <FormLabel>Carousel Item Name</FormLabel>
          <Input
            placeholder="Carousel Item"
            {...register(
              `content.${props.parentIndex}.carouselItems.${props.index}.name`
            )}
          />
        </FormControl>
      </Card>

      <Card as={Stack} spacing="4" p="6">
        <Text textStyle="bodyStrong">Settings</Text>

        <Stack spacing="4">
          <FormControl>
            <FormLabel>Image</FormLabel>
            <Controller
              control={control}
              name={`content.${props.parentIndex}.carouselItems.${props.index}.imageId`}
              render={({ field }) => (
                <ImagePicker
                  {...field}
                  imageInput={{ w: 1000 }}
                  nonDraftImageProxy={imageProxy}
                />
              )}
            />
          </FormControl>
        </Stack>

        {isCarouselItemSubtitleEnabled && variant !== 3 && (
          <Controller
            control={control}
            name={`content.${props.parentIndex}.carouselItems.${props.index}.subtitle`}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Subtitle</FormLabel>
                <Textarea
                  resize="vertical"
                  value={field.value ?? ''}
                  onChange={field.onChange}
                  maxLength={60}
                />
                <FormHelperText textAlign="right">
                  {field.value?.length ?? 0}/60
                </FormHelperText>
              </FormControl>
            )}
          />
        )}

        {isCarouselItemTitleEnabled && variant !== 3 && (
          <Controller
            control={control}
            name={`content.${props.parentIndex}.carouselItems.${props.index}.title`}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Title</FormLabel>
                <Textarea
                  resize="vertical"
                  value={field.value ?? ''}
                  onChange={field.onChange}
                  maxLength={50}
                />
                <FormHelperText textAlign="right">
                  {field.value?.length ?? 0}/50
                </FormHelperText>
              </FormControl>
            )}
          />
        )}

        {isCarouselItemBodyTextEnabled && variant !== 3 && (
          <Controller
            control={control}
            name={`content.${props.parentIndex}.carouselItems.${props.index}.body`}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Body</FormLabel>
                <Textarea
                  resize="vertical"
                  value={field.value ?? ''}
                  onChange={field.onChange}
                  maxLength={270}
                />
                <FormHelperText textAlign="right">
                  {field.value?.length ?? 0}/270
                </FormHelperText>
              </FormControl>
            )}
          />
        )}
      </Card>

      {variant !== 3 && (
        <Card as={Stack} spacing="4" p="6">
          <Text textStyle="bodyStrong">Link</Text>

          {carouselItemLinkBehavior === ECarouselLinkBehavior.CARD && (
            <Box bg="gray.100" borderRadius="md" color="gray.500" py="2" px="4">
              The card will be the link
            </Box>
          )}

          {carouselItemLinkBehavior === ECarouselLinkBehavior.BUTTON && (
            <Controller
              control={control}
              name={`content.${props.parentIndex}.carouselItems.${props.index}.linkText`}
              render={({ field }) => (
                <FormControl>
                  <FormLabel>Button copy</FormLabel>

                  <Input
                    value={field.value ?? ''}
                    onChange={field.onChange}
                    maxLength={50}
                  />
                  <FormHelperText textAlign="right">
                    {field.value?.length ?? 0}/50
                  </FormHelperText>
                </FormControl>
              )}
            />
          )}

          <Controller
            control={control}
            name={`content.${props.parentIndex}.carouselItems.${props.index}.linkSource`}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Link to</FormLabel>
                <ButtonRadioGroup colorScheme="purple" {...field}>
                  <ButtonRadio value={ELinkSource.CREATED_PAGE}>
                    Created Page
                  </ButtonRadio>
                  <ButtonRadio value={ELinkSource.LINK}>
                    External Link
                  </ButtonRadio>
                </ButtonRadioGroup>
              </FormControl>
            )}
          />

          {linkSource === ELinkSource.CREATED_PAGE && (
            <Controller
              control={control}
              name={`content.${props.parentIndex}.carouselItems.${props.index}.linkPage`}
              render={({ field }) => (
                <FormControl>
                  <VisuallyHidden>
                    <FormLabel>Page</FormLabel>
                  </VisuallyHidden>
                  <SearchablePageSelect {...field} />
                </FormControl>
              )}
            />
          )}

          {linkSource === ELinkSource.LINK && (
            <FormControl>
              <VisuallyHidden>
                <FormLabel>Href</FormLabel>
              </VisuallyHidden>
              <Input
                placeholder="https://"
                {...register(
                  `content.${props.parentIndex}.carouselItems.${props.index}.linkHref`
                )}
              />
            </FormControl>
          )}
        </Card>
      )}
    </Stack>
  );
};
