import {
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import * as React from 'react';

import { SelectButton } from '@/components/SelectButton';
import { EPageRestriction } from '@/graphql-types/globalTypes';
import { READABLE_PAGE_RESTRICTION } from '@/utilities';

export const PageRestrictionSelect = React.forwardRef<
  HTMLButtonElement,
  {
    value: EPageRestriction;
    onChange: (value: EPageRestriction) => void;
    onBlur: () => void;
  }
>(({ value, onChange, onBlur }, ref) => {
  return (
    <Menu matchWidth>
      <MenuButton as={SelectButton} ref={ref}>
        {READABLE_PAGE_RESTRICTION[value]}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          type="radio"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        >
          <MenuItemOption value={EPageRestriction.ANYONE}>
            {READABLE_PAGE_RESTRICTION[EPageRestriction.ANYONE]}
          </MenuItemOption>
          <MenuItemOption value={EPageRestriction.LOGGED_IN}>
            {READABLE_PAGE_RESTRICTION[EPageRestriction.LOGGED_IN]}
          </MenuItemOption>
          <MenuItemOption value={EPageRestriction.MEMBERS_ONLY}>
            {READABLE_PAGE_RESTRICTION[EPageRestriction.MEMBERS_ONLY]}
          </MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
});

PageRestrictionSelect.displayName = 'PageRestrictionSelect';
