import {
  Box,
  BoxProps,
  Heading as ChakraHeading,
  Stack,
  StackProps,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';

import { EHeadingTextAlign } from '@/graphql-types/globalTypes';
import { ChevronsRightIcon } from '@/imports/ui/chakra/feather';
import { BACKGROUND_COLOR } from '@/modules/website-builder/attributes';
import { Heading } from '@/modules/website-builder/types';

const textAlign: Record<EHeadingTextAlign, BoxProps['textAlign']> = {
  [EHeadingTextAlign.CENTER]: 'center',
  [EHeadingTextAlign.LEFT]: 'start',
  [EHeadingTextAlign.RIGHT]: 'end',
};

const justifyContent: Record<EHeadingTextAlign, StackProps['justifyContent']> =
  {
    [EHeadingTextAlign.CENTER]: 'center',
    [EHeadingTextAlign.LEFT]: 'flex-start',
    [EHeadingTextAlign.RIGHT]: 'flex-end',
  };

export const HeadingRender: React.FC<
  {
    heading: Heading;
  } & BoxProps
> = ({ heading, ...props }) => {
  if (heading.title || heading.subtitle) {
    return (
      <Box
        w="full"
        backgroundColor={BACKGROUND_COLOR[heading.options.backgroundColor]}
        {...props}
      >
        <Stack
          w="full"
          maxW="container.md"
          textAlign={textAlign[heading.options.textAlign]}
          mx="auto"
          px="4"
        >
          {heading.title && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={justifyContent[heading.options.textAlign]}
            >
              <ChevronsRightIcon boxSize="10" color="primary.500" />
              <ChakraHeading>{heading.title}</ChakraHeading>
            </Stack>
          )}
          {heading.subtitle && (
            <Text textStyle="xlBody">{heading.subtitle}</Text>
          )}
        </Stack>
      </Box>
    );
  }

  return null;
};
