import { Box, Center, Circle, Stack, Text, Tooltip } from '@chakra-ui/react';
import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FieldArray } from 'react-hook-form';

import { ENavigationItemRestriction } from '@/graphql-types/globalTypes';
import {
  AwardIcon,
  EyeOffIcon,
  ShieldIcon,
  UserIcon,
} from '@/imports/ui/chakra/feather';
import { WebsiteBuilderNavigationFormValues } from '@/modules/website-builder/types';

export const NavigationItem: React.FC<{
  index: number;
  field: FieldArray<WebsiteBuilderNavigationFormValues, 'items'>;
  onClick: () => void;
}> = (props) => {
  return (
    <Draggable draggableId={props.index.toString()} index={props.index}>
      {(provided, snapshot) => {
        return (
          <Box
            border="1px"
            borderColor="gray.200"
            borderRadius="xl"
            shadow={snapshot.isDragging ? 'lg' : 'sm'}
            bg="white"
            _hover={{ bg: 'gray.50' }}
            cursor="pointer"
            onClick={props.onClick}
            mb="2"
            ref={provided.innerRef}
            style={provided.draggableProps.style}
            {...provided.draggableProps}
          >
            <Stack direction="row" alignItems="stretch" spacing="0">
              {/* TODO: make it so that the mouse doesn't trigger hover effects on elements underneath the one you are currently dragging */}
              <Center {...provided.dragHandleProps} px="4">
                <Stack spacing="1">
                  <Stack direction="row" spacing="1">
                    <Circle size="2px" bg="gray.500" />
                    <Circle size="2px" bg="gray.500" />
                  </Stack>
                  <Stack direction="row" spacing="1">
                    <Circle size="2px" bg="gray.500" />
                    <Circle size="2px" bg="gray.500" />
                  </Stack>
                  <Stack direction="row" spacing="1">
                    <Circle size="2px" bg="gray.500" />
                    <Circle size="2px" bg="gray.500" />
                  </Stack>
                </Stack>
              </Center>

              <Text textStyle="bodyStrong" flex="1" py="4">
                {props.field.label}
              </Text>

              {props.field.restriction ===
                ENavigationItemRestriction.LOGGED_IN && (
                <Center p="4">
                  <Tooltip label="All logged-in users can access this page">
                    <UserIcon
                      color="gray.500"
                      boxSize="3"
                      position="relative"
                    />
                  </Tooltip>
                </Center>
              )}

              {props.field.restriction ===
                ENavigationItemRestriction.MEMBERS_ONLY && (
                <Center p="4">
                  <Tooltip
                    label={
                      props.field.defaultItemIdentifier
                        ? 'Only members and admins can access this page'
                        : 'Only members can access this page'
                    }
                  >
                    <Stack direction="row" spacing="1">
                      <AwardIcon
                        color="yellow.500"
                        boxSize="3"
                        position="relative"
                      />
                      {props.field.defaultItemIdentifier && (
                        <ShieldIcon
                          color="red.500"
                          boxSize="3"
                          position="relative"
                        />
                      )}
                    </Stack>
                  </Tooltip>
                </Center>
              )}

              {props.field.restriction ===
                ENavigationItemRestriction.NOBODY && (
                <Center p="4">
                  <Tooltip label="This page is hidden">
                    <EyeOffIcon
                      color="gray.500"
                      boxSize="3"
                      position="relative"
                    />
                  </Tooltip>
                </Center>
              )}
            </Stack>
          </Box>
        );
      }}
    </Draggable>
  );
};
