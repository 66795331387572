import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SlideFade,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import {
  ENavigationItemRestriction,
  ENavigationItemStructure,
} from '@/graphql-types/globalTypes';
import { Card } from '@/imports/ui/chakra/components/Card';
import { ChevronDownIcon, PlusIcon } from '@/imports/ui/chakra/feather';
import { BackButton } from '@/modules/website-builder/components/BackButton';
import { ExtremelyGhostlyButton } from '@/modules/website-builder/components/GhostlyButton';
import {
  NavigationItem,
  NavigationItemChild,
} from '@/modules/website-builder/types';

import { DirectLinkForm } from '../NavigationItemEditor/components/DirectLinkForm';
import { NewDirectLinkForm } from '../NewDirectLinkForm';
import { ChildItem } from './components/ChildItem';

export type NewDropdownMenuFormValues = {
  label: string;
  children: NavigationItemChild[];
};

export const NewDropdownMenuForm: React.FC<{
  onClickBack: () => void;
  onSubmit: (item: NavigationItem) => void;
}> = (props) => {
  const newLinkForm = useDisclosure();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<NewDropdownMenuFormValues>({
    defaultValues: {
      label: '',
      children: [],
    },
  });

  const childrenFieldArray = useFieldArray({ control, name: 'children' });

  const onSubmit = handleSubmit((data) => {
    props.onSubmit({
      label: data.label,
      href: null,
      page: null,
      restriction: ENavigationItemRestriction.ANYONE,
      defaultItemIdentifier: null,
      children: data.children,
      structure: ENavigationItemStructure.DROPDOWN,
    });
  });

  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  return (
    <Box>
      {activeIndex === null && !newLinkForm.isOpen && (
        <SlideFade in>
          <Stack spacing="4">
            {/* TODO: add prompt to make sure user doesn't lose their changes on accident */}
            <BackButton onClick={props.onClickBack}>
              Back to All Tabs
            </BackButton>

            <Card border="1px" borderColor="gray.200" shadow="sm" p="6">
              <FormControl isInvalid={!!errors.label}>
                <FormLabel>Tab Name</FormLabel>
                <Input
                  autoFocus
                  {...register('label', { required: 'Tab name is required' })}
                />
                <FormErrorMessage>{errors.label?.message}</FormErrorMessage>
              </FormControl>
            </Card>

            <Card border="1px" borderColor="gray.200" shadow="sm" py="6">
              <Stack spacing="4">
                <Stack direction="row" alignItems="center" px="7" spacing="3">
                  <ChevronDownIcon boxSize="4" />
                  <Text textStyle="bodyStrong">Links</Text>
                </Stack>

                <Box px="4">
                  <DragDropContext
                    onDragEnd={(result) => {
                      if (result.destination) {
                        childrenFieldArray.move(
                          result.source.index,
                          result.destination.index
                        );
                      }
                    }}
                  >
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {childrenFieldArray.fields.map((field, index) => (
                            <ChildItem
                              key={index}
                              index={index}
                              field={field}
                              onClick={() => setActiveIndex(index)}
                            />
                          ))}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Box>

                <Box px="4">
                  <ExtremelyGhostlyButton
                    leftIcon={<PlusIcon />}
                    iconSpacing="3"
                    onClick={newLinkForm.onOpen}
                  >
                    Add a Link
                  </ExtremelyGhostlyButton>
                </Box>
              </Stack>
            </Card>

            <Button colorScheme="purple" onClick={onSubmit} size="sm">
              Add Tab
            </Button>
          </Stack>
        </SlideFade>
      )}

      {childrenFieldArray.fields.map((field, index) => {
        return activeIndex === index ? (
          <SlideFade key={index} in>
            <Stack spacing="4">
              <BackButton onClick={() => setActiveIndex(null)}>
                Back to Add a Tab
              </BackButton>
              <DirectLinkForm
                value={field}
                onChange={(value) => childrenFieldArray.update(index, value)}
                isEditable
              />
            </Stack>
          </SlideFade>
        ) : null;
      })}

      {activeIndex === null && newLinkForm.isOpen && (
        <SlideFade in>
          <NewDirectLinkForm
            onClickBack={newLinkForm.onClose}
            backText="Back to Add a Tab"
            onSubmit={(item) => {
              childrenFieldArray.append(item);
              newLinkForm.onClose();
            }}
            submitText="Add Link"
          />
        </SlideFade>
      )}
    </Box>
  );
};
