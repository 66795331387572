import {
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
  Stack,
  StackDivider,
  StackProps,
} from '@chakra-ui/react';
import * as React from 'react';

export const BigRadioGroup = React.forwardRef<
  HTMLDivElement,
  Omit<RadioGroupProps, 'variant' | 'value' | 'onChange'> &
    Omit<StackProps, 'variant' | 'value' | 'onChange'> & {
      variant?: 'spaced' | 'attached';
      value?: string;
      onChange?: (value: string) => void;
    }
>(({ children, variant = 'attached', value, onChange, ...props }, ref) => {
  const style = {
    attached: {
      borderWidth: '1px',
      borderColor: 'gray.200',
      divider: <StackDivider />,
      spacing: '0',
    },
    spaced: {},
  }[variant];

  return (
    <RadioGroup
      {...style}
      as={Stack}
      variant={variant}
      borderRadius="xl"
      value={value}
      onChange={onChange}
      ref={ref}
      tabIndex={-1} // make it focusable so react hook form can scroll to it
      {...props}
    >
      {children}
    </RadioGroup>
  );
});

BigRadioGroup.displayName = 'BigRadioGroup';

export type BigRadioProps = RadioProps;

export const BigRadio = React.forwardRef<HTMLInputElement, BigRadioProps>(
  ({ children, ...props }, ref) => {
    return (
      <Radio {...props} ref={ref}>
        {children}
      </Radio>
    );
  }
);

BigRadio.displayName = 'BigRadio';
