import debounce from 'lodash/debounce';
import * as React from 'react';

// TODO: I can't figure out how to type this correctly

export function useDebounce<T extends (...args: any[]) => any>(
  callback: T,
  delay = 500
): T {
  const callbackRef = React.useRef(callback);

  React.useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  return React.useMemo(
    // @ts-ignore
    () => debounce((...args) => callbackRef.current(...args), delay),
    [delay]
  );
}
