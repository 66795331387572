import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  VisuallyHidden,
} from '@chakra-ui/react';
import * as React from 'react';
import { Controller, FieldArray, useForm } from 'react-hook-form';

import { ButtonRadio, ButtonRadioGroup } from '@/components/ButtonRadio';
import { ELinkSource, EPageRestriction } from '@/graphql-types/globalTypes';
import { SearchablePageSelect } from '@/modules/website-builder/components/SearchablePageSelect';
import { WebsiteBuilderPageFormValues } from '@/modules/website-builder/types';

import { ImagePicker } from '../../ImagePicker';

export const ImageEditor: React.FC<{
  onClickBack: () => void;
  imageMaxHeight: number;
  value: FieldArray<WebsiteBuilderPageFormValues, `content.${number}.images`>;
  onChange: (
    value: FieldArray<WebsiteBuilderPageFormValues, `content.${number}.images`>
  ) => void;
  nonDraftImageProxy: string | null;
}> = (props) => {
  const {
    control,
    watch,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<{
    imageId: string | null;
    linkHref: string | null;
    linkPage: {
      _id: string;
      name: string;
      href: string;
      restriction: EPageRestriction;
      __typename: 'Page';
    } | null;
    linkSource: ELinkSource;
  }>({
    defaultValues: {
      imageId: props.value.imageId,
      linkHref: props.value.linkHref,
      linkPage: props.value.linkPage,
      linkSource: props.value.linkSource,
    },
  });

  const linkSource = watch('linkSource');

  const onSubmit = handleSubmit((data) => {
    props.onChange({ ...props.value, ...data });
  });

  return (
    <Modal isOpen onClose={props.onClickBack} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Image</ModalHeader>
        <ModalBody>
          <Stack spacing="4">
            <FormControl>
              <VisuallyHidden>
                <FormLabel>Image</FormLabel>
              </VisuallyHidden>
              <Controller
                control={control}
                name="imageId"
                render={({ field }) => (
                  <ImagePicker
                    imageMaxHeight={props.imageMaxHeight}
                    {...field}
                    imageInput={{}}
                    nonDraftImageProxy={props.nonDraftImageProxy}
                  />
                )}
              />
            </FormControl>

            <Controller
              control={control}
              name="linkSource"
              render={({ field }) => (
                <FormControl>
                  <FormLabel>Link to</FormLabel>
                  <ButtonRadioGroup colorScheme="purple" {...field}>
                    <ButtonRadio value={ELinkSource.CREATED_PAGE}>
                      Created Page
                    </ButtonRadio>
                    <ButtonRadio value={ELinkSource.LINK}>
                      External Link
                    </ButtonRadio>
                  </ButtonRadioGroup>
                </FormControl>
              )}
            />

            {linkSource === ELinkSource.CREATED_PAGE && (
              <Controller
                control={control}
                name="linkPage"
                render={({ field }) => (
                  <FormControl isInvalid={!!errors.linkPage}>
                    <VisuallyHidden>
                      <FormLabel>Page</FormLabel>
                    </VisuallyHidden>
                    <SearchablePageSelect {...field} />
                    <FormErrorMessage>
                      {errors.linkPage?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            )}

            {linkSource === ELinkSource.LINK && (
              <FormControl isInvalid={!!errors.linkHref}>
                <VisuallyHidden>
                  <FormLabel>Href</FormLabel>
                </VisuallyHidden>
                <Input placeholder="https://" {...register('linkHref')} />
                <FormErrorMessage>{errors.linkHref?.message}</FormErrorMessage>
              </FormControl>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="outline"
            colorScheme="gray"
            mr="2"
            onClick={props.onClickBack}
          >
            Cancel
          </Button>
          <Button colorScheme="purple" onClick={onSubmit}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
