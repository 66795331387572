import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Link,
  Select,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';
import { Controller, DefaultValues, useForm } from 'react-hook-form';

import { ButtonRadio, ButtonRadioGroup } from '@/components/ButtonRadio';
import { ReadOnly, ReadOnlyLabel, ReadOnlyValue } from '@/components/ReadOnly';
import {
  ENavigationItemRestriction,
  ENavigationItemStructure,
  EPageRestriction,
} from '@/graphql-types/globalTypes';
import { Card } from '@/imports/ui/chakra/components/Card';
import { Semibold } from '@/imports/ui/chakra/components/Semibold';
import { Edit2Icon, EditIcon } from '@/imports/ui/chakra/feather';
import { SearchablePageSelect } from '@/modules/website-builder/components/SearchablePageSelect';
import {
  NavigationItem,
  NavigationItemChild,
} from '@/modules/website-builder/types';
import {
  PAGE_RESTRICTION_TO_NAVIGATION_ITEM_RESTRICTION,
  READABLE_NAVIGATION_ITEM_RESTRICTION,
  READABLE_PAGE_RESTRICTION,
} from '@/utilities';

const READABLE_DIRECT_LINK_TYPE = {
  'created-page': 'Created Page',
  link: 'Link',
};

type DirectLinkFormValues = {
  label: string;
  href: string | null;
  page: {
    _id: string;
    name: string;
    restriction: EPageRestriction;
  } | null;
  restriction: ENavigationItemRestriction;
  directLinkType: 'created-page' | 'link';
};

export const DirectLinkForm: React.FC<{
  value: NavigationItem | NavigationItemChild;
  onChange: (value: NavigationItem) => void;
  isEditable: boolean;
}> = (props) => {
  const defaultValues: DefaultValues<DirectLinkFormValues> =
    React.useMemo(() => {
      return {
        label: props.value.label,
        href: props.value.href,
        page: props.value.page,
        restriction: props.value.restriction,
        directLinkType: props.value.page ? 'created-page' : 'link',
      };
    }, [
      props.value.href,
      props.value.label,
      props.value.page,
      props.value.restriction,
    ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    control,
    resetField,
  } = useForm<DirectLinkFormValues>({
    defaultValues,
  });

  const directLinkType = watch('directLinkType');
  const page = watch('page');
  const restriction = watch('restriction');
  const href = watch('href');

  const onSubmit = handleSubmit((data) => {
    props.onChange({
      label: data.label,
      href: data.directLinkType === 'link' ? data.href : null,
      page: data.directLinkType === 'created-page' ? data.page : null,
      restriction: data.restriction,
      defaultItemIdentifier: null,
      children: [],
      structure: ENavigationItemStructure.DIRECT_LINK,
    });

    attributeEditing.onClose();
  });

  React.useEffect(() => {
    if (page) {
      setValue(
        'restriction',
        PAGE_RESTRICTION_TO_NAVIGATION_ITEM_RESTRICTION[page.restriction]
      );
    }
  }, [page, setValue]);

  const nameEditing = useDisclosure();
  const attributeEditing = useDisclosure();

  return (
    <Stack spacing="4">
      <Card border="1px" borderColor="gray.200" shadow="sm" p="6">
        {nameEditing.isOpen ? (
          <Stack spacing="4">
            <FormControl isInvalid={!!errors.label}>
              <FormLabel>Tab Name</FormLabel>
              <Input
                autoFocus
                {...register('label', { required: 'Tab name is required' })}
              />
              <FormErrorMessage>{errors.label?.message}</FormErrorMessage>
            </FormControl>
            <Stack direction="row" spacing="4">
              <Button
                size="sm"
                w="full"
                variant="outline"
                colorScheme="gray"
                onClick={() => {
                  resetField('label');
                  nameEditing.onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                w="full"
                colorScheme="purple"
                onClick={() => {
                  onSubmit();
                  nameEditing.onClose();
                }}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        ) : (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text textStyle="bodyStrong">{props.value.label}</Text>
            {props.isEditable && (
              <IconButton
                size="sm"
                aria-label="edit name"
                icon={<Edit2Icon />}
                variant="ghost"
                colorScheme="gray"
                onClick={nameEditing.onOpen}
              />
            )}
          </Stack>
        )}
      </Card>

      {props.isEditable && (
        <Card border="1px" borderColor="gray.200" shadow="sm" p="6">
          <Stack spacing="4">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text textStyle="bodyStrong">Link</Text>

              <IconButton
                size="sm"
                aria-label="edit attributes"
                icon={<Edit2Icon />}
                variant="ghost"
                colorScheme="gray"
                onClick={attributeEditing.onOpen}
                visibility={attributeEditing.isOpen ? 'hidden' : undefined}
              />
            </Stack>

            {attributeEditing.isOpen ? (
              <Controller
                control={control}
                name="directLinkType"
                render={({ field }) => (
                  <ButtonRadioGroup {...field} size="sm" colorScheme="purple">
                    <ButtonRadio value="created-page">
                      {READABLE_DIRECT_LINK_TYPE['created-page']}
                    </ButtonRadio>
                    <ButtonRadio value="link">
                      {READABLE_DIRECT_LINK_TYPE['link']}
                    </ButtonRadio>
                  </ButtonRadioGroup>
                )}
              />
            ) : (
              <ReadOnly>
                <ReadOnlyLabel>Link type</ReadOnlyLabel>
                <ReadOnlyValue>
                  {READABLE_DIRECT_LINK_TYPE[directLinkType]}
                </ReadOnlyValue>
              </ReadOnly>
            )}

            {directLinkType === 'link' && (
              <>
                {attributeEditing.isOpen ? (
                  <FormControl isInvalid={!!errors.href}>
                    <FormLabel>Link</FormLabel>
                    <Input
                      {...register('href', {
                        validate: {
                          requiredIf: (value) =>
                            // TODO: validate links are valid links
                            getValues('directLinkType') === 'created-page' ||
                            !!value ||
                            'Link is required',
                        },
                      })}
                    />

                    <FormErrorMessage>{errors.href?.message}</FormErrorMessage>
                  </FormControl>
                ) : (
                  <ReadOnly>
                    <ReadOnlyLabel>Link</ReadOnlyLabel>
                    <ReadOnlyValue>{href}</ReadOnlyValue>
                  </ReadOnly>
                )}
              </>
            )}

            {directLinkType === 'created-page' && (
              <>
                {attributeEditing.isOpen ? (
                  <FormControl isInvalid={!!errors.page}>
                    <FormLabel>Link to</FormLabel>
                    <Controller
                      name="page"
                      control={control}
                      rules={{
                        validate: {
                          requiredIf: (value) =>
                            getValues('directLinkType') === 'link' ||
                            !!value ||
                            'Link is required',
                        },
                      }}
                      render={({ field }) => (
                        <SearchablePageSelect {...field} />
                      )}
                    />
                    <FormErrorMessage>{errors.page?.message}</FormErrorMessage>
                  </FormControl>
                ) : (
                  <ReadOnly>
                    <ReadOnlyLabel>Link to</ReadOnlyLabel>
                    <ReadOnlyValue>{page?.name}</ReadOnlyValue>
                  </ReadOnly>
                )}
              </>
            )}

            {attributeEditing.isOpen ? (
              <FormControl isDisabled={directLinkType === 'created-page'}>
                <FormLabel>Visible to</FormLabel>
                <Select {...register('restriction')}>
                  <option value={ENavigationItemRestriction.ANYONE}>
                    {
                      READABLE_NAVIGATION_ITEM_RESTRICTION[
                        ENavigationItemRestriction.ANYONE
                      ]
                    }
                  </option>
                  <option value={ENavigationItemRestriction.LOGGED_IN}>
                    {
                      READABLE_NAVIGATION_ITEM_RESTRICTION[
                        ENavigationItemRestriction.LOGGED_IN
                      ]
                    }
                  </option>
                  <option value={ENavigationItemRestriction.MEMBERS_ONLY}>
                    {
                      READABLE_NAVIGATION_ITEM_RESTRICTION[
                        ENavigationItemRestriction.MEMBERS_ONLY
                      ]
                    }
                  </option>
                  <option value={ENavigationItemRestriction.NOBODY}>
                    {
                      READABLE_NAVIGATION_ITEM_RESTRICTION[
                        ENavigationItemRestriction.NOBODY
                      ]
                    }
                  </option>
                </Select>
              </FormControl>
            ) : (
              <ReadOnly>
                <ReadOnlyLabel>Visible to</ReadOnlyLabel>
                <ReadOnlyValue>
                  {READABLE_NAVIGATION_ITEM_RESTRICTION[restriction]}
                </ReadOnlyValue>
              </ReadOnly>
            )}

            {directLinkType === 'created-page' && page && (
              <Text color="gray.500" textStyle="detail">
                The page permission is set to{' '}
                <Semibold textTransform="lowercase">
                  {READABLE_PAGE_RESTRICTION[page.restriction]}
                </Semibold>
                . To change that, please{' '}
                <Link
                  as={NextLink}
                  href={`/website-builder/${page._id}?tabIndex=2`}
                  textDecoration="underline"
                >
                  go to the page setting
                </Link>
                .
              </Text>
            )}

            {attributeEditing.isOpen && (
              <Stack direction="row" spacing="4">
                <Button
                  size="sm"
                  w="full"
                  variant="outline"
                  colorScheme="gray"
                  onClick={() => {
                    resetField('href');
                    resetField('page');
                    resetField('restriction');
                    resetField('directLinkType');
                    attributeEditing.onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  w="full"
                  colorScheme="purple"
                  onClick={onSubmit}
                >
                  Save
                </Button>
              </Stack>
            )}
          </Stack>
        </Card>
      )}
    </Stack>
  );
};
