import {
  Button,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
} from '@chakra-ui/react';
import * as React from 'react';
import { FieldPath, UseFormReturn, useWatch } from 'react-hook-form';

import { Semibold } from '@/imports/ui/chakra/components/Semibold';
import { XIcon } from '@/imports/ui/chakra/feather';
import { WebsiteBuilderPageFormValues } from '@/modules/website-builder/types';

export const DeleteButton: React.FC<{
  onDelete: () => void;
  namePath: FieldPath<WebsiteBuilderPageFormValues>;
  defaultName: string;
  methods: UseFormReturn<WebsiteBuilderPageFormValues, any>;
}> = (props) => {
  const initialFocusRef = React.useRef<HTMLButtonElement>(null);
  const { control } = props.methods;
  const name = useWatch({ control, name: props.namePath }) || props.defaultName;

  return (
    <Popover initialFocusRef={initialFocusRef}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <IconButton
              variant="outline"
              colorScheme="gray"
              icon={<XIcon />}
              aria-label="delete"
              borderRadius="full"
              size="xs"
            />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              Are you sure you want to delete <Semibold>{name}</Semibold>?
            </PopoverBody>
            <PopoverFooter display="flex" justifyContent="flex-end" gap="2">
              <Button
                colorScheme="gray"
                variant="outline"
                size="sm"
                ref={initialFocusRef}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="purple"
                size="sm"
                onClick={() => {
                  props.onDelete();
                  onClose();
                }}
              >
                Delete
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
