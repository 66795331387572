import {
  Box,
  BoxProps,
  Button,
  Flex,
  FlexProps,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';

import { EHeroLinkBehavior, ELinkSource } from '@/graphql-types/globalTypes';
import { Card, CardProps } from '@/imports/ui/chakra/components/Card';
import { Hero } from '@/modules/website-builder/types';
import { getExternalUrl } from '@/utilities/getExternalUrl';

const height: Record<number, BoxProps['height']> = {
  1: '3xl',
  2: '2xl',
  3: undefined,
};

const justifyContent: Record<number, FlexProps['justifyContent']> = {
  1: 'flex-start',
  2: 'center',
  3: undefined,
};

const background: Record<number, BoxProps['background']> = {
  1: 'blackAlpha.500',
  2: 'blackAlpha.700',
  3: undefined,
};

const HeroCard: React.FC<CardProps & { hero: Hero }> = ({ hero, ...props }) => {
  const href =
    hero.linkSource === ELinkSource.CREATED_PAGE
      ? hero.linkPage?.href
      : hero.linkSource === ELinkSource.LINK
      ? hero.linkHref
      : undefined;

  if (
    hero.options.isLinkEnabled &&
    hero.options.linkBehavior === EHeroLinkBehavior.CARD &&
    href
  ) {
    if (hero.linkIsExternal) {
      return (
        <Card
          cursor="pointer"
          as="a"
          display="block"
          target="_blank"
          rel="noreferrer"
          href={getExternalUrl(href)}
          {...props}
        />
      );
    }

    return (
      <NextLink href={href}>
        <Card cursor="pointer" {...props} />
      </NextLink>
    );
  }

  return <Card {...props} />;
};

const HeroButton: React.FC<{ hero: Hero }> = ({ hero }) => {
  const href =
    hero.linkSource === ELinkSource.CREATED_PAGE
      ? hero.linkPage?.href
      : hero.linkSource === ELinkSource.LINK
      ? hero.linkHref
      : undefined;

  if (href) {
    if (hero.linkIsExternal) {
      return (
        <Button
          as="a"
          target="_blank"
          rel="noreferrer"
          href={getExternalUrl(href)}
        >
          {hero.linkText}
        </Button>
      );
    }

    return (
      <NextLink href={href}>
        <Button>{hero.linkText}</Button>
      </NextLink>
    );
  }

  return <Button isDisabled>{hero.linkText}</Button>;
};

export const HeroRender: React.FC<{ hero: Hero }> = ({ hero }) => {
  if (hero.variant === 3 && hero.imageProxy) {
    return <Image w="full" src={hero.imageProxy} alt="" />;
  }

  return (
    <Flex
      height={height[hero.variant] ?? 'xl'}
      w="full"
      backgroundColor="gray.500"
      backgroundImage={hero.imageProxy ?? undefined}
      backgroundPosition="center"
      backgroundSize="cover"
      alignItems="center"
      justifyContent={justifyContent[hero.variant]}
      position="relative"
      zIndex="0"
    >
      {hero.variant !== 3 && (
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          background={background[hero.variant]}
          zIndex="1"
        />
      )}

      {hero.variant === 1 && (
        <Box
          w="full"
          maxW="container.xl"
          mx="auto"
          paddingX={{ base: 6, lg: '24' }}
          zIndex="2"
        >
          <HeroCard hero={hero} p="8" w={{ base: 'full', lg: 'xl' }}>
            {hero.subtitle && (
              <Text textStyle="xlBodyStrong" textTransform="uppercase">
                {hero.subtitle}
              </Text>
            )}

            {hero.title && <Heading mt="4">{hero.title}</Heading>}

            {hero.body && (
              <Text mt="4" textStyle="xlBody">
                {hero.body}
              </Text>
            )}

            {hero.options.isLinkEnabled &&
              hero.options.linkBehavior === EHeroLinkBehavior.BUTTON &&
              hero.linkText && (
                <Stack mt="8" direction="row" justifyContent="flex-end">
                  <HeroButton hero={hero} />
                </Stack>
              )}
          </HeroCard>
        </Box>
      )}

      {hero.variant === 2 && (
        <Box
          w="full"
          maxW="580px"
          textAlign="center"
          color="white"
          mx="6"
          zIndex="3"
        >
          {hero.subtitle && (
            <Text textStyle="xlBodyStrong" textTransform="uppercase">
              {hero.subtitle}
            </Text>
          )}

          {hero.title && <Heading mt="2">{hero.title}</Heading>}

          {hero.body && (
            <Text mt="2" textStyle="xlBody">
              {hero.body}
            </Text>
          )}

          {hero.options.isLinkEnabled && hero.linkText && (
            <Box mt="10">
              <HeroButton hero={hero} />
            </Box>
          )}
        </Box>
      )}
    </Flex>
  );
};
