import { gql, useMutation } from '@apollo/client';
import * as React from 'react';
import { UseFormReturn, useFormState, useWatch } from 'react-hook-form';

import { ENavigationItemStructure } from '@/graphql-types/globalTypes';
import { useDebounce } from '@/imports/ui/hooks/useDebounce';
import { WebsiteBuilderNavigationFormValues } from '@/modules/website-builder/types';

import {
  AutoSave_UpdateNavigation,
  AutoSave_UpdateNavigationVariables,
} from './graphql-types/AutoSave_UpdateNavigation';

const NAVIGATION_ITEM = gql`
  fragment AutoSave_NavigationItem on NavigationItem {
    href
    isExternal
    label
    restriction
    defaultItemIdentifier
    page {
      _id
      name
      restriction
    }
  }
`;

const UPDATE_PAGE = gql`
  mutation AutoSave_UpdateNavigation($input: UpdateNavigationInput!) {
    updateNavigation(input: $input) {
      editor: navigation(
        input: { isUnpublishedVersion: true, isRestrictionIgnored: true }
      ) {
        items {
          ...AutoSave_NavigationItem
          children {
            ...AutoSave_NavigationItem
            children {
              ...AutoSave_NavigationItem
            }
          }
        }
        metadata {
          version
          publishedVersion
          isRestrictionIgnored
        }
      }
      preview: navigation(input: { isUnpublishedVersion: true }) {
        items {
          ...AutoSave_NavigationItem
          children {
            ...AutoSave_NavigationItem
            children {
              ...AutoSave_NavigationItem
            }
          }
        }
        metadata {
          version
          publishedVersion
        }
      }
    }
  }
  ${NAVIGATION_ITEM}
`;

export const AutoSave: React.FC<{
  methods: UseFormReturn<WebsiteBuilderNavigationFormValues, any>;
}> = ({ methods }) => {
  const [updatePage] = useMutation<
    AutoSave_UpdateNavigation,
    AutoSave_UpdateNavigationVariables
  >(UPDATE_PAGE);

  const { control, handleSubmit } = methods;

  const { isDirty } = useFormState({ control });

  const content = useWatch({ control });

  const onSubmit = handleSubmit(async (data) => {
    await updatePage({
      variables: {
        input: {
          items: [
            ...data.items.map((item) => {
              if (item.defaultItemIdentifier) {
                return {
                  defaultItemIdentifier: item.defaultItemIdentifier,
                };
              }

              if (item.structure === ENavigationItemStructure.DIRECT_LINK) {
                if (item.page?._id) {
                  return {
                    label: item.label,
                    pageId: item.page._id,
                  };
                }

                return {
                  href: item.href,
                  label: item.label,
                  restriction: item.restriction,
                };
              }

              if (item.structure === ENavigationItemStructure.DROPDOWN) {
                return {
                  label: item.label,
                  children: item.children.map((child) => {
                    if (child.page?._id) {
                      return {
                        label: child.label,
                        pageId: child.page._id,
                      };
                    }

                    return {
                      href: child.href,
                      label: child.label,
                      restriction: child.restriction,
                    };
                  }),
                };
              }

              throw new Error('Unexpected Navigation Item Structure');
            }),
          ],
        },
      },
    });
  });

  const onChange = useDebounce(() => {
    if (isDirty) {
      onSubmit();
    }
  }, 1000);

  React.useEffect(onChange, [onChange, content]);

  return null;
};
