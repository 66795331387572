import { DocumentNode, gql } from '@apollo/client';
import { Box, SlideFade, useDisclosure } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import * as React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FormProvider, UseFormReturn, useFieldArray } from 'react-hook-form';
import yn from 'yn';

import { PlusIcon } from '@/imports/ui/chakra/feather';
import { useDebounce } from '@/imports/ui/hooks/useDebounce';
import { useAlphabetSoup } from '@/modules/alphabet-soup';
import { VeryGhostlyButton } from '@/modules/website-builder/components/GhostlyButton';
import { WebsiteBuilderPageFormValues } from '@/modules/website-builder/types';

import { PageContentEditor_PageFragment } from './graphql-types/PageContentEditor_PageFragment';

import { PageLink } from '../PageLink';
import { AddNewContent } from './components/AddNewContent';
import { BlockGroupEditor } from './components/BlockGroupEditor';
import { CarouselEditor } from './components/CarouselEditor';
import { ContentItem } from './components/ContentItem';
import { HeadingEditor } from './components/HeadingEditor';
import { HeroEditor } from './components/HeroEditor';
import { ImageGroupEditor } from './components/ImageGroupEditor';
import { RichTextEditor } from './components/RichTextEditor';

const DEFAULT_NAME = {
  Heading: 'Heading',
  Hero: 'Hero',
  BlockGroup: 'Card Group',
  ImageGroup: 'Image Group',
  RichText: 'Rich Text',
  Carousel: 'Carousel',
};

export const PageContentEditor: React.FC<{
  methods: UseFormReturn<WebsiteBuilderPageFormValues, any>;
  page: PageContentEditor_PageFragment;
  onClickPageLink: () => void;
}> & { pageFragment: DocumentNode } = ({ methods, page, onClickPageLink }) => {
  const newContent = useDisclosure();
  const contentFieldArray = useFieldArray<
    WebsiteBuilderPageFormValues,
    'content',
    'id'
  >({ control: methods.control, name: 'content' });

  const router = useRouter();

  const shouldScrollOnHover = yn(router.query.shouldScrollOnHover) ?? false;

  const { setHighlightedContentIndex } = useAlphabetSoup();

  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  React.useEffect(() => {
    setHighlightedContentIndex({ value: activeIndex, shouldScroll: true });
  }, [activeIndex, setHighlightedContentIndex]);

  const debouncedSetHighlightedContentIndex = useDebounce(
    setHighlightedContentIndex,
    300
  );

  return (
    <>
      <PageLink page={page} onClick={onClickPageLink} />
      <Box p="4">
        {activeIndex === null && (
          <SlideFade in>
            <DragDropContext
              onDragEnd={(result) => {
                if (result.destination) {
                  contentFieldArray.move(
                    result.source.index,
                    result.destination.index
                  );
                }
              }}
            >
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <Box ref={provided.innerRef} {...provided.droppableProps}>
                    {contentFieldArray.fields.map((field, index) => {
                      return (
                        <ContentItem
                          key={field.id}
                          index={index}
                          namePath={`content.${index}.name`}
                          defaultName={DEFAULT_NAME[field.__typename]}
                          onClick={() => setActiveIndex(index)}
                          onDelete={() => contentFieldArray.remove(index)}
                          methods={methods}
                          onMouseEnter={
                            snapshot.isDraggingOver
                              ? undefined
                              : () =>
                                  debouncedSetHighlightedContentIndex({
                                    value: index,
                                    shouldScroll: shouldScrollOnHover,
                                  })
                          }
                          onMouseLeave={
                            snapshot.isDraggingOver
                              ? undefined
                              : () =>
                                  debouncedSetHighlightedContentIndex({
                                    value: null,
                                    shouldScroll: shouldScrollOnHover,
                                  })
                          }
                          pointerEvents={
                            snapshot.isDraggingOver ? 'none' : undefined
                          }
                        />
                      );
                    })}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </SlideFade>
        )}

        {contentFieldArray.fields.map((field, index) => {
          return activeIndex === index ? (
            <SlideFade in key={index}>
              {field.__typename === 'Heading' && (
                <HeadingEditor
                  index={index}
                  defaultName={DEFAULT_NAME[field.__typename]}
                  onClickBack={() => setActiveIndex(null)}
                  methods={methods}
                />
              )}

              {field.__typename === 'Hero' && (
                <HeroEditor
                  index={index}
                  defaultName={DEFAULT_NAME[field.__typename]}
                  onClickBack={() => setActiveIndex(null)}
                  methods={methods}
                />
              )}

              {field.__typename === 'BlockGroup' && (
                <BlockGroupEditor
                  index={index}
                  defaultName={DEFAULT_NAME[field.__typename]}
                  onClickBack={() => setActiveIndex(null)}
                  methods={methods}
                />
              )}

              {field.__typename === 'Carousel' && (
                <CarouselEditor
                  index={index}
                  defaultName={DEFAULT_NAME[field.__typename]}
                  onClickBack={() => setActiveIndex(null)}
                  methods={methods}
                />
              )}

              {field.__typename === 'ImageGroup' && (
                <ImageGroupEditor
                  index={index}
                  defaultName={DEFAULT_NAME[field.__typename]}
                  onClickBack={() => setActiveIndex(null)}
                  methods={methods}
                />
              )}

              {field.__typename === 'RichText' && (
                <RichTextEditor
                  index={index}
                  defaultName={DEFAULT_NAME[field.__typename]}
                  onClickBack={() => setActiveIndex(null)}
                  methods={methods}
                />
              )}
            </SlideFade>
          ) : null;
        })}

        {activeIndex === null && !newContent.isOpen && (
          <SlideFade in>
            <VeryGhostlyButton
              leftIcon={<PlusIcon />}
              borderRadius="xl"
              onClick={newContent.onOpen}
            >
              Add a New Component
            </VeryGhostlyButton>
          </SlideFade>
        )}

        {activeIndex === null && newContent.isOpen && (
          <SlideFade in>
            <AddNewContent
              append={contentFieldArray.append}
              onCancel={newContent.onClose}
              onSuccess={() => {
                // this is intentionally setting the index to the last item + 1
                // because the last item technically hasn't gotten added at the
                // time this code runs
                setActiveIndex(contentFieldArray.fields.length);
                newContent.onClose();
              }}
            />
          </SlideFade>
        )}
      </Box>
    </>
  );
};

PageContentEditor.pageFragment = gql`
  fragment PageContentEditor_PageFragment on Page {
    _id
    ...PageLink_PageFragment
  }
  ${PageLink.pageFragment}
`;
