import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Center,
  Image,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';

import {
  EBlockGroupBlockAlignment,
  EBlockGroupLinkBehavior,
  ELinkSource,
} from '@/graphql-types/globalTypes';
import { Card, CardProps } from '@/imports/ui/chakra/components/Card';
import { ImageFallback } from '@/imports/ui/chakra/components/ImageFallback';
import {
  ASPECT_RATIO,
  BACKGROUND_COLOR,
} from '@/modules/website-builder/attributes';
import { BlockGroup, BlockGroupBlock } from '@/modules/website-builder/types';
import { getExternalUrl } from '@/utilities/getExternalUrl';

const TEXT_ALIGN: Record<EBlockGroupBlockAlignment, BoxProps['textAlign']> = {
  [EBlockGroupBlockAlignment.LEFT]: 'left',
  [EBlockGroupBlockAlignment.CENTER]: 'center',
};

const BlockCard: React.FC<
  CardProps & { block: BlockGroupBlock; linkBehavior: EBlockGroupLinkBehavior }
> = ({ block, linkBehavior, ...props }) => {
  const href =
    block.linkSource === ELinkSource.CREATED_PAGE
      ? block.linkPage?.href
      : block.linkSource === ELinkSource.LINK
      ? block.linkHref
      : undefined;

  if (linkBehavior === EBlockGroupLinkBehavior.CARD && href) {
    if (block.linkIsExternal) {
      return (
        <Card
          cursor="pointer"
          as="a"
          target="_blank"
          rel="noreferrer"
          href={getExternalUrl(href)}
          {...props}
        />
      );
    }

    return <Card as={NextLink} href={href} cursor="pointer" {...props} />;
  }

  return <Card {...props} />;
};

const BlockButton: React.FC<ButtonProps & { block: BlockGroupBlock }> = ({
  block,
  ...props
}) => {
  const href =
    block.linkSource === ELinkSource.CREATED_PAGE
      ? block.linkPage?.href
      : block.linkSource === ELinkSource.LINK
      ? block.linkHref
      : undefined;

  if (href) {
    if (block.linkIsExternal) {
      return (
        <Button
          as="a"
          target="_blank"
          rel="noreferrer"
          href={getExternalUrl(href)}
          {...props}
        >
          {block.linkText}
        </Button>
      );
    }

    return (
      <NextLink href={href}>
        <Button {...props}>{block.linkText}</Button>
      </NextLink>
    );
  }

  return (
    <Button isDisabled {...props}>
      {block.linkText}
    </Button>
  );
};

export const BlockGroupRender: React.FC<
  {
    blockGroup: BlockGroup;
  } & BoxProps
> = ({ blockGroup, ...props }) => {
  const aspectRatio = ASPECT_RATIO[blockGroup.options.blockImageAspectRatio];

  return (
    <Center
      w="full"
      backgroundColor={BACKGROUND_COLOR[blockGroup.options.backgroundColor]}
      {...props}
    >
      <Wrap
        justify="center"
        maxW="container.xl"
        px="4"
        spacing="0"
        m="-2.5"
        w="full"
      >
        {blockGroup.blocks.map((block, index) => (
          <WrapItem
            key={index}
            w={{
              base: 'full',
              md: '50%',
              lg: { 2: '50%', 3: 'calc(100% / 3)', 4: '25%' }[
                blockGroup.options.columns
              ],
            }}
            p="2.5"
          >
            <BlockCard
              overflow="hidden"
              linkBehavior={blockGroup.options.blockLinkBehavior}
              block={block}
              w="full"
              h="full"
            >
              <Stack spacing="0" h="full">
                {blockGroup.options.isBlockImageEnabled && block.imageProxy && (
                  <Center sx={{ aspectRatio }}>
                    <Image
                      src={block.imageProxy}
                      alt=""
                      objectFit="cover"
                      sx={{ aspectRatio }}
                      h="full"
                    />
                  </Center>
                )}

                {blockGroup.options.isBlockImageEnabled &&
                  !block.imageProxy && (
                    <ImageFallback aspectRatio={aspectRatio} />
                  )}

                {(blockGroup.options.isBlockTitleEnabled ||
                  blockGroup.options.isBlockSubtitleEnabled ||
                  blockGroup.options.isBlockBodyTextEnabled) && (
                  <Stack
                    spacing="4"
                    p="8"
                    flex="1"
                    textAlign={TEXT_ALIGN[blockGroup.options.blockAlignment]}
                  >
                    {(blockGroup.options.isBlockTitleEnabled ||
                      blockGroup.options.isBlockSubtitleEnabled) && (
                      <Box>
                        {blockGroup.options.isBlockSubtitleEnabled && (
                          <Text
                            textStyle="xlBodyStrong"
                            textTransform="uppercase"
                          >
                            {block.subtitle}
                          </Text>
                        )}

                        {blockGroup.options.isBlockTitleEnabled && (
                          <Text
                            fontSize="3xl"
                            fontWeight="semibold"
                            lineHeight="9"
                          >
                            {block.title}
                          </Text>
                        )}
                      </Box>
                    )}

                    {blockGroup.options.isBlockBodyTextEnabled && (
                      <Text textStyle="xlBody" whiteSpace="pre-wrap">
                        {block.body}
                      </Text>
                    )}

                    {blockGroup.options.blockLinkBehavior ===
                      EBlockGroupLinkBehavior.BUTTON &&
                      block.linkText && (
                        <>
                          <Spacer />
                          <Box>
                            <BlockButton
                              variant="outline"
                              colorScheme="gray"
                              block={block}
                            />
                          </Box>
                        </>
                      )}
                  </Stack>
                )}
              </Stack>
            </BlockCard>
          </WrapItem>
        ))}
      </Wrap>
    </Center>
  );
};
