import {
  Box,
  BoxProps,
  Center,
  Divider,
  Flex,
  Image,
  Link,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import * as React from 'react';

import { ImageFallback } from '@/imports/ui/chakra/components/ImageFallback';
import { TradewingIcon } from '@/imports/ui/chakra/customIcon';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
  YoutubeIcon,
} from '@/imports/ui/chakra/feather';
import { TenantFragment } from '@/queries/graphql-types/TenantFragment';

export const FooterRender: React.FC<
  {
    tenant: TenantFragment;
  } & BoxProps
> = ({ tenant, ...props }) => {
  const {
    contactInfo,
    logo,
    websiteCopyrightDate,
    privacyPolicyLink,
    termsAndConditionsLink,
  } = tenant;

  const hasSocialLinks = !!(
    contactInfo?.facebook ||
    contactInfo?.twitter ||
    contactInfo?.instagram ||
    contactInfo?.linkedin ||
    contactInfo?.youtube
  );

  return (
    <Box
      as="footer"
      bg="white"
      borderTop="1px"
      borderTopColor="gray.100"
      {...props}
    >
      <Stack
        h={{ base: 'full', lg: '264px' }}
        px="4"
        py={{ base: '4', lg: '12' }}
        spacing={{ base: '4', lg: '8' }}
        textAlign={{ base: 'center', lg: 'start' }}
        maxW="container.xl"
        mx="auto"
      >
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={{ base: '4', lg: '8' }}
          align="center"
          w="full"
        >
          <Center>
            <Image
              objectFit="cover"
              src={logo?.imageProxy || undefined}
              alt="association logo"
              borderRadius="base"
              height="78px"
              fallback={<ImageFallback height="78px" borderRadius="base" />}
            />
          </Center>
          <Stack spacing="0">
            {contactInfo?.phoneNumber && (
              <Text textStyle="xlBody">{contactInfo.phoneNumber}</Text>
            )}
            {contactInfo?.email && (
              <Text textStyle="xlBody">{contactInfo.email}</Text>
            )}
            {(contactInfo?.addressLine0 ||
              contactInfo?.addressAdministrativeArea ||
              contactInfo?.addressLocality ||
              contactInfo?.addressPostalCode) && (
              <ContactInfoText contactInfo={contactInfo} />
            )}
          </Stack>

          <Spacer display={{ base: 'none', lg: 'block' }} />

          {hasSocialLinks && (
            <Center>
              <Stack direction="row" spacing="5">
                {contactInfo?.facebook && (
                  <Link href={contactInfo.facebook} isExternal>
                    <FacebookIcon boxSize="8" color="primary" />
                  </Link>
                )}
                {contactInfo?.linkedin && (
                  <Link href={contactInfo.linkedin} isExternal>
                    <LinkedinIcon boxSize="8" color="primary" />
                  </Link>
                )}
                {contactInfo?.twitter && (
                  <Link href={contactInfo.twitter} isExternal>
                    <TwitterIcon boxSize="8" color="primary" />
                  </Link>
                )}
                {contactInfo?.instagram && (
                  <Link href={contactInfo.instagram} isExternal>
                    <InstagramIcon boxSize="8" color="primary" />
                  </Link>
                )}
                {contactInfo?.youtube && (
                  <Link href={contactInfo.youtube} isExternal>
                    <YoutubeIcon boxSize="8" color="primary" />
                  </Link>
                )}
              </Stack>
            </Center>
          )}
        </Stack>

        <Divider borderColor="gray.200" />

        <Flex direction={{ base: 'column', lg: 'row' }}>
          <Flex
            flex="1"
            justify={{ base: 'center', lg: 'start' }}
            mr={{ base: '0', lg: 'auto' }}
          >
            <Stack direction="row" spacing="4">
              <Link href={termsAndConditionsLink.url} isExternal>
                {termsAndConditionsLink.text}
              </Link>

              <Link href={privacyPolicyLink.url} isExternal>
                {privacyPolicyLink.text}
              </Link>
            </Stack>
          </Flex>

          <Flex flex="1" justify="center">
            <Link href="https://www.tradewing.com/" isExternal>
              Powered by
              <TradewingIcon boxSize="4" color="gray.500" ml="2" mr="1" />
              Tradewing
            </Link>
          </Flex>

          <Flex
            flex="1"
            justify={{ base: 'center', lg: 'end' }}
            ml={{ base: '0', lg: 'auto' }}
          >
            <Text>
              © {dayjs(websiteCopyrightDate).format('YYYY')}{' '}
              {tenant.displayName}
            </Text>
          </Flex>
        </Flex>
      </Stack>
    </Box>
  );
};

const ContactInfoText: React.FC<{
  contactInfo: TenantFragment['contactInfo'];
}> = ({ contactInfo }) => {
  if (!contactInfo) return null;

  const {
    addressLine0,
    addressLocality,
    addressAdministrativeArea,
    addressPostalCode,
  } = contactInfo;
  return (
    <Text textStyle="xlBody">
      {[
        addressLine0,
        addressLocality,
        addressAdministrativeArea,
        addressPostalCode,
      ]
        .filter((v) => !!v)
        .join(', ')}
    </Text>
  );
};
