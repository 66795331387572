import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import * as React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  EHeadingTextAlign,
  EPageContentBackgroundColor,
} from '@/graphql-types/globalTypes';
import { Card } from '@/imports/ui/chakra/components/Card';
import { BackButton } from '@/modules/website-builder/components/BackButton';
import { WebsiteBuilderPageFormValues } from '@/modules/website-builder/types';

export const HeadingEditor: React.FC<{
  index: number;
  defaultName: string;
  onClickBack: () => void;
  methods: UseFormReturn<WebsiteBuilderPageFormValues, any>;
}> = (props) => {
  const { control, register } = props.methods;

  return (
    <Stack spacing="4">
      <BackButton onClick={props.onClickBack}>
        Back to all components
      </BackButton>

      <Card as={Stack} spacing="4" p="6">
        <Text textStyle="bodyStrong">Component Settings</Text>

        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input
            placeholder="Heading"
            {...register(`content.${props.index}.name`)}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Background Color</FormLabel>
          <Select
            {...register(`content.${props.index}.options.backgroundColor`)}
          >
            <option value={EPageContentBackgroundColor.WHITE}>White</option>
            <option value={EPageContentBackgroundColor.TRANSPARENT}>
              Light Gray
            </option>
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel>Text Alignment</FormLabel>
          <Select {...register(`content.${props.index}.options.textAlign`)}>
            <option value={EHeadingTextAlign.LEFT}>Left</option>
            <option value={EHeadingTextAlign.CENTER}>Center</option>
            <option value={EHeadingTextAlign.RIGHT}>Right</option>
          </Select>
        </FormControl>
      </Card>

      <Card as={Stack} spacing="4" p="6">
        <Text textStyle="bodyStrong">Content</Text>

        <Controller
          control={control}
          name={`content.${props.index}.title`}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Textarea
                resize="vertical"
                value={field.value ?? ''}
                onChange={field.onChange}
                maxLength={50}
              />
              <FormHelperText textAlign="right">
                {field.value?.length ?? 0}/50
              </FormHelperText>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name={`content.${props.index}.subtitle`}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Subtitle</FormLabel>
              <Textarea
                resize="vertical"
                value={field.value ?? ''}
                onChange={field.onChange}
                maxLength={360}
              />
              <FormHelperText textAlign="right">
                {field.value?.length ?? 0}/360
              </FormHelperText>
            </FormControl>
          )}
        />
      </Card>
    </Stack>
  );
};
