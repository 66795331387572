import { Box, BoxProps, Center, Circle, Stack, Text } from '@chakra-ui/react';
import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
  FieldArray,
  FieldPath,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';

import { WebsiteBuilderPageFormValues } from '@/modules/website-builder/types';

import { DeleteButton } from './components/DeleteButton';

export const ContentItem: React.FC<{
  index: number;
  namePath: FieldPath<WebsiteBuilderPageFormValues>;
  onDelete: () => void;
  defaultName: string;
  onClick: () => void;
  image?: React.ReactNode;
  methods: UseFormReturn<WebsiteBuilderPageFormValues, any>;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  pointerEvents: BoxProps['pointerEvents'];
}> = (props) => {
  const name =
    useWatch<WebsiteBuilderPageFormValues>({
      control: props.methods.control,
      name: props.namePath,
    }) || props.defaultName;

  return (
    <Draggable draggableId={props.index.toString()} index={props.index}>
      {(provided, snapshot) => {
        return (
          <Box
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            border="1px"
            borderColor="gray.200"
            borderRadius="xl"
            shadow={snapshot.isDragging ? 'lg' : 'sm'}
            bg="white"
            _hover={{ bg: 'gray.50' }}
            cursor="pointer"
            onClick={props.onClick}
            mb="2"
            ref={provided.innerRef}
            style={provided.draggableProps.style}
            {...provided.draggableProps}
            pointerEvents={props.pointerEvents}
          >
            <Stack direction="row" alignItems="stretch" spacing="0">
              {/* TODO: make it so that the mouse doesn't trigger hover effects on elements underneath the one you are currently dragging */}
              <Center {...provided.dragHandleProps} px="4">
                <Stack spacing="1">
                  <Stack direction="row" spacing="1">
                    <Circle size="2px" bg="gray.500" />
                    <Circle size="2px" bg="gray.500" />
                  </Stack>
                  <Stack direction="row" spacing="1">
                    <Circle size="2px" bg="gray.500" />
                    <Circle size="2px" bg="gray.500" />
                  </Stack>
                  <Stack direction="row" spacing="1">
                    <Circle size="2px" bg="gray.500" />
                    <Circle size="2px" bg="gray.500" />
                  </Stack>
                </Stack>
              </Center>

              <Box flex="1">
                {props.image ? (
                  props.image
                ) : (
                  <Text textStyle="bodyStrong" py="4">
                    {name}
                  </Text>
                )}
              </Box>

              <Center px="4">
                <Box onClick={(event) => event.stopPropagation()}>
                  <DeleteButton
                    onDelete={props.onDelete}
                    namePath={props.namePath}
                    defaultName={props.defaultName}
                    methods={props.methods}
                  />
                </Box>
              </Center>
            </Stack>
          </Box>
        );
      }}
    </Draggable>
  );
};

ContentItem.displayName = 'ContentListItem';
