import { ContentState, convertToRaw } from 'draft-js';

import {
  EBlockGroupBlockAlignment,
  EBlockGroupImageAspectRatio,
  EBlockGroupLinkBehavior,
  ECarouselLinkBehavior,
  EHeadingTextAlign,
  EHeroLinkBehavior,
  EImageGroupImageAlignment,
  ELinkSource,
  EPageContentBackgroundColor,
} from '@/graphql-types/globalTypes';

import {
  BlockGroup,
  BlockGroupBlock,
  Carousel,
  CarouselItem,
  Heading,
  Hero,
  ImageGroup,
  RichText,
} from './types';

export const DEFAULT_HEADING_CONTENT: Heading = {
  name: null,
  title: 'Section title',
  subtitle:
    'Vestibulum interdum augue eu velit lacinia ornare. Sed sed eros a enim venenatis gravida in nec metus. Nunc venenatis molestie lectus, quis euismod dolor sagittis nec. ',
  options: {
    textAlign: EHeadingTextAlign.CENTER,
    backgroundColor: EPageContentBackgroundColor.WHITE,
    __typename: 'HeadingOptions',
  },
  variant: 1,
  __typename: 'Heading',
};

export const DEFAULT_BLOCK_CONTENT: BlockGroupBlock = {
  name: '',
  imageId: null,
  imageProxy: null,
  title: 'Card title',
  subtitle: 'Card Subtitle',
  body: 'Vestibulum interdum augue eu velit lacinia ornare. Sed sed eros a enim venenatis gravida in nec metus. Nunc venenatis molestie lectus, quis euismod dolor sagittis nec. Nullam malesuada dui non faucibus tempor. ',
  linkText: null,
  linkHref: null,
  linkPage: null,
  linkIsExternal: false, // this is odd
  linkSource: ELinkSource.LINK,
  __typename: 'Block',
};

export const DEFAULT_CAROUSEL_ITEM_CONTENT: CarouselItem = {
  name: '',
  imageId: null,
  imageProxy: null,
  title: 'Carousel item title',
  subtitle: 'Carousel item Subtitle',
  body: 'Vestibulum interdum augue eu velit lacinia ornare. Sed sed eros a enim venenatis gravida in nec metus. Nunc venenatis molestie lectus, quis euismod dolor sagittis nec. Nullam malesuada dui non faucibus tempor. ',
  linkText: 'Carousel item link text',
  linkHref: 'Carousel item link href',
  linkPage: null,
  linkIsExternal: false, // this is odd
  linkSource: ELinkSource.LINK,
  __typename: 'CarouselItem',
};

export const DEFAULT_HERO_CONTENT: Hero = {
  name: null,
  variant: 1,
  options: {
    linkBehavior: EHeroLinkBehavior.BUTTON,
    isLinkEnabled: true,
    __typename: 'HeroOptions',
  },
  imageId: null,
  imageProxy: null,
  title: 'Card title',
  subtitle: 'Card subtitle',
  body: 'Vestibulum interdum augue eu velit lacinia ornare. Sed sed eros a enim venenatis gravida in nec metus. Nunc venenatis molestie lectus, quis euismod dolor sagittis nec. Nullam malesuada dui non faucibus tempor.',
  linkText: 'Button',
  linkHref: null,
  linkPage: null,
  linkIsExternal: false, // this is odd
  linkSource: ELinkSource.LINK,
  __typename: 'Hero',
};

export const DEFAULT_IMAGE_GROUP_CONTENT: ImageGroup = {
  name: null,
  variant: 1,
  options: {
    backgroundColor: EPageContentBackgroundColor.WHITE,
    imageHeight: 100,
    imageAlignment: EImageGroupImageAlignment.CENTER,
    __typename: 'ImageGroupOptions',
  },
  images: [
    {
      name: null,
      imageId: '',
      imageProxy: '',
      linkHref: '',
      linkIsExternal: false,
      linkPage: null,
      linkSource: ELinkSource.LINK,
      __typename: 'ImageGroup_Image',
    },
    {
      name: null,
      imageId: '',
      imageProxy: '',
      linkHref: '',
      linkIsExternal: false,
      linkPage: null,
      linkSource: ELinkSource.LINK,
      __typename: 'ImageGroup_Image',
    },
    {
      name: null,
      imageId: '',
      imageProxy: '',
      linkHref: '',
      linkIsExternal: false,
      linkPage: null,
      linkSource: ELinkSource.LINK,
      __typename: 'ImageGroup_Image',
    },
  ],
  __typename: 'ImageGroup',
};

export const DEFAULT_BLOCK_GROUP_CONTENT: BlockGroup = {
  name: null,
  variant: 1,
  options: {
    columns: 3,
    backgroundColor: EPageContentBackgroundColor.WHITE,
    blockAlignment: EBlockGroupBlockAlignment.CENTER,
    blockLinkBehavior: EBlockGroupLinkBehavior.CARD,
    blockImageAspectRatio: EBlockGroupImageAspectRatio.HORIZONTAL,
    isBlockImageEnabled: true,
    isBlockSubtitleEnabled: true,
    isBlockTitleEnabled: true,
    isBlockBodyTextEnabled: true,
    __typename: 'BlockGroupOptions',
  },
  blocks: [DEFAULT_BLOCK_CONTENT, DEFAULT_BLOCK_CONTENT, DEFAULT_BLOCK_CONTENT],
  __typename: 'BlockGroup',
};

export const DEFAULT_CAROUSEL_CONTENT: Carousel = {
  name: null,
  variant: 1,
  options: {
    carouselItemLinkBehavior: ECarouselLinkBehavior.CARD,
    isCarouselItemSubtitleEnabled: true,
    isCarouselItemTitleEnabled: true,
    isCarouselItemBodyTextEnabled: true,
    isCarouselAutoChangeEnabled: true,
    carouselAutoChangeSpeed: 5000,
    __typename: 'CarouselOptions',
  },
  carouselItems: [
    DEFAULT_CAROUSEL_ITEM_CONTENT,
    DEFAULT_CAROUSEL_ITEM_CONTENT,
    DEFAULT_CAROUSEL_ITEM_CONTENT,
  ],
  __typename: 'Carousel',
};

export const DEFAULT_RICH_TEXT_CONTENT: RichText = {
  name: null,
  rawDraftContentState: {
    blocks: [
      {
        key: '7r62r',
        text: 'Heading 3',
        type: 'header-three',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: '39qtr',
        text: '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: 'ci15p',
        text: 'Adipiscing at in tellus integer. Pellentesque massa placerat duis ultricies lacus. Nisi porta lorem mollis aliquam ut porttitor leo. Venenatis cras sed felis eget. Duis ultricies lacus sed turpis tincidunt. Interdum varius sit amet mattis. Libero justo laoreet sit amet cursus sit amet dictum sit. Consequat interdum varius sit amet mattis vulputate enim nulla. Pellentesque habitant morbi tristique senectus et netus et. Elit duis tristique sollicitudin nibh sit. Aliquam etiam erat velit scelerisque in dictum non. Velit egestas dui id ornare arcu odio ut sem nulla. Viverra tellus in hac habitasse platea dictumst vestibulum rhoncus est. Quis hendrerit dolor magna eget est. Lobortis scelerisque fermentum dui faucibus in ornare. Quam id leo in vitae. Vestibulum sed arcu non odio euismod lacinia at quis risus. Est sit amet facilisis magna etiam. Consequat nisl vel pretium lectus quam. Non arcu risus quis varius.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: 'c6q5u',
        text: '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: 'eiesd',
        text: 'Heading 3',
        type: 'header-three',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: 'e7m4h',
        text: '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: '3dipo',
        text: 'Adipiscing at in tellus integer. Pellentesque massa placerat duis ultricies lacus. Nisi porta lorem mollis aliquam ut porttitor leo. Venenatis cras sed felis eget. Duis ultricies lacus sed turpis tincidunt. Interdum varius sit amet mattis. Libero justo laoreet sit amet cursus sit amet dictum sit. Consequat interdum varius sit amet mattis vulputate enim nulla. Pellentesque habitant morbi tristique senectus et netus et. Elit duis tristique sollicitudin nibh sit. Aliquam etiam erat velit scelerisque in dictum non. Velit egestas dui id ornare arcu odio ut sem nulla. Viverra tellus in hac habitasse platea dictumst vestibulum rhoncus est. Quis hendrerit dolor magna eget est. Lobortis scelerisque fermentum dui faucibus in ornare. Quam id leo in vitae. Vestibulum sed arcu non odio euismod lacinia at quis risus. Est sit amet facilisis magna etiam. Consequat nisl vel pretium lectus quam. Non arcu risus quis varius.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: '4dmng',
        text: '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: 'fr3dp',
        text: 'Heading 3',
        type: 'header-three',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: 'fps0o',
        text: '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: 'ecqgn',
        text: 'Adipiscing at in tellus integer. Pellentesque massa placerat duis ultricies lacus. Nisi porta lorem mollis aliquam ut porttitor leo. Venenatis cras sed felis eget. Duis ultricies lacus sed turpis tincidunt. Interdum varius sit amet mattis. Libero justo laoreet sit amet cursus sit amet dictum sit. Consequat interdum varius sit amet mattis vulputate enim nulla. Pellentesque habitant morbi tristique senectus et netus et. Elit duis tristique sollicitudin nibh sit. Aliquam etiam erat velit scelerisque in dictum non. Velit egestas dui id ornare arcu odio ut sem nulla. Viverra tellus in hac habitasse platea dictumst vestibulum rhoncus est. Quis hendrerit dolor magna eget est. Lobortis scelerisque fermentum dui faucibus in ornare. Quam id leo in vitae. Vestibulum sed arcu non odio euismod lacinia at quis risus. Est sit amet facilisis magna etiam. Consequat nisl vel pretium lectus quam. Non arcu risus quis varius.',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  },
  options: {
    backgroundColor: EPageContentBackgroundColor.WHITE,
    __typename: 'RichTextOptions',
  },
  __typename: 'RichText',
};
