import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { UseFormReturn, useFieldArray, useWatch } from 'react-hook-form';

import {
  EImageGroupImageAlignment,
  ELinkSource,
  EPageContentBackgroundColor,
} from '@/graphql-types/globalTypes';
import { Card } from '@/imports/ui/chakra/components/Card';
import { ImageFallbackIcon } from '@/imports/ui/chakra/components/ImageFallback/components/ImageFallbackIcon';
import { PlusIcon } from '@/imports/ui/chakra/feather';
import { BackButton } from '@/modules/website-builder/components/BackButton';
import { WebsiteBuilderPageFormValues } from '@/modules/website-builder/types';

import { ContentItem } from '../ContentItem';
import { ImageEditor } from './components/ImageEditor';

export const ImageGroupEditor: React.FC<{
  index: number;
  defaultName: string;
  onClickBack: () => void;
  methods: UseFormReturn<WebsiteBuilderPageFormValues, any>;
}> = (props) => {
  const newImageModal = useDisclosure();
  const { control, register } = props.methods;

  const imagesFieldArray = useFieldArray({
    control,
    name: `content.${props.index}.images`,
  });

  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const imageMaxHeight = useWatch({
    control,
    name: `content.${props.index}.options.imageHeight`,
  });

  return (
    <Stack spacing="4">
      <Stack spacing="4">
        <BackButton onClick={props.onClickBack}>
          Back to all components
        </BackButton>

        <Card as={Stack} spacing="4" p="6">
          <Text textStyle="bodyStrong">Component Settings</Text>

          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input
              placeholder="Image Group"
              {...register(`content.${props.index}.name`)}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Background Color</FormLabel>
            <Select
              {...register(`content.${props.index}.options.backgroundColor`)}
            >
              <option value={EPageContentBackgroundColor.WHITE}>White</option>
              <option value={EPageContentBackgroundColor.TRANSPARENT}>
                Light Gray
              </option>
            </Select>
          </FormControl>
        </Card>

        <Card as={Stack} spacing="4" p="6">
          <Text textStyle="bodyStrong">Image Settings</Text>

          <FormControl>
            <FormLabel>Height</FormLabel>
            <InputGroup>
              <Input
                {...register(`content.${props.index}.options.imageHeight`, {
                  valueAsNumber: true,
                })}
              />
              <InputRightAddon>px</InputRightAddon>
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel>Alignment</FormLabel>
            <Select
              {...register(`content.${props.index}.options.imageAlignment`)}
            >
              <option value={EImageGroupImageAlignment.CENTER}>Center</option>
              <option value={EImageGroupImageAlignment.LEFT}>Left</option>
            </Select>
          </FormControl>
        </Card>

        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) {
              return;
            }

            imagesFieldArray.move(
              result.source.index,
              result.destination.index
            );
          }}
        >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <Box ref={provided.innerRef} {...provided.droppableProps}>
                {imagesFieldArray.fields.map((field, index) => {
                  return (
                    <ContentItem
                      key={field.id}
                      index={index}
                      namePath={`content.${props.index}.images.${index}.name`}
                      defaultName="Image"
                      onClick={() => setActiveIndex(index)}
                      onDelete={() => imagesFieldArray.remove(index)}
                      image={
                        <Box
                          h="10"
                          borderRadius="md"
                          my="2"
                          overflow="hidden"
                          w="fit-content"
                        >
                          {field.imageProxy ? (
                            <Image src={field.imageProxy} alt="" h="full" />
                          ) : (
                            <Center
                              bg="gray.300"
                              sx={{ aspectRatio: '4/3' }}
                              h="full"
                            >
                              <ImageFallbackIcon color="white" boxSize={4} />
                            </Center>
                          )}
                        </Box>
                      }
                      methods={props.methods}
                      pointerEvents={
                        snapshot.isDraggingOver ? 'none' : undefined
                      }
                    />
                  );
                })}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Stack>

      {imagesFieldArray.fields.map((field, index) => {
        return activeIndex === index ? (
          <ImageEditor
            key={field.id}
            onClickBack={() => setActiveIndex(null)}
            imageMaxHeight={imageMaxHeight}
            value={field}
            onChange={(value) => {
              imagesFieldArray.update(index, value);
              setActiveIndex(null);
            }}
            nonDraftImageProxy={field.imageProxy}
          />
        ) : null;
      })}

      {newImageModal.isOpen && (
        <ImageEditor
          onClickBack={newImageModal.onClose}
          imageMaxHeight={imageMaxHeight}
          value={{
            name: null,
            imageId: '',
            imageProxy: '',
            linkHref: '',
            linkIsExternal: false,
            linkPage: null,
            linkSource: ELinkSource.LINK,
            __typename: 'ImageGroup_Image',
          }}
          onChange={(value) => {
            imagesFieldArray.append(value);
            newImageModal.onClose();
          }}
          nonDraftImageProxy={null}
        />
      )}

      <Center>
        <Button
          colorScheme="purple"
          leftIcon={<PlusIcon />}
          size="sm"
          onClick={newImageModal.onOpen}
        >
          Add New Image
        </Button>
      </Center>
    </Stack>
  );
};
