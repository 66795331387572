import { IconProps, keyframes } from '@chakra-ui/react';
import * as React from 'react';

import { LoaderIcon } from '@/imports/ui/chakra/feather';

const spin = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`;

const animation = `${spin} infinite 2s linear`;

export const AnimatedLoaderIcon: React.FC<IconProps> = (props) => {
  return <LoaderIcon animation={animation} {...props} />;
};
