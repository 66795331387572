import { Button, Stack, Text } from '@chakra-ui/react';
import * as React from 'react';
import { UseFieldArrayAppend } from 'react-hook-form';

import { BigRadio, BigRadioGroup } from '@/components/BigRadio';
import { ELinkSource } from '@/graphql-types/globalTypes';
import { Card } from '@/imports/ui/chakra/components/Card';
import {
  MiniBlockGroup,
  MiniCarousel,
  MiniHeading,
  MiniHero,
  MiniImageGroup,
  MiniRichText,
} from '@/modules/website-builder/components/Minis';
import {
  DEFAULT_BLOCK_GROUP_CONTENT,
  DEFAULT_CAROUSEL_CONTENT,
  DEFAULT_HEADING_CONTENT,
  DEFAULT_HERO_CONTENT,
  DEFAULT_IMAGE_GROUP_CONTENT,
  DEFAULT_RICH_TEXT_CONTENT,
} from '@/modules/website-builder/default-content';
import { WebsiteBuilderPageFormValues } from '@/modules/website-builder/types';

enum OPTION {
  HEADING = 'HEADING',
  HERO = 'HERO',
  IMAGE_GROUP = 'IMAGE_GROUP',
  BLOCK_GROUP = 'BLOCK_GROUP',
  RICH_TEXT = 'RICH_TEXT',
  CAROUSEL = 'CAROUSEL',
}

export const AddNewContent: React.FC<{
  append: UseFieldArrayAppend<WebsiteBuilderPageFormValues, 'content'>;
  onCancel: () => void;
  onSuccess: () => void;
}> = ({ append, onCancel, onSuccess }) => {
  const [value, setValue] = React.useState<OPTION | undefined>(undefined);

  const onSubmit = () => {
    if (value === OPTION.HEADING) {
      append(DEFAULT_HEADING_CONTENT);
    }

    if (value === OPTION.HERO) {
      append(DEFAULT_HERO_CONTENT);
    }

    if (value === OPTION.IMAGE_GROUP) {
      append(DEFAULT_IMAGE_GROUP_CONTENT);
    }

    if (value === OPTION.BLOCK_GROUP) {
      append(DEFAULT_BLOCK_GROUP_CONTENT);
    }

    if (value === OPTION.CAROUSEL) {
      append(DEFAULT_CAROUSEL_CONTENT);
    }

    if (value === OPTION.RICH_TEXT) {
      append(DEFAULT_RICH_TEXT_CONTENT);
    }

    onSuccess();
  };

  return (
    <Card border="1px" borderColor="gray.200" shadow="sm" p="4">
      <Stack spacing="4">
        <BigRadioGroup
          value={value}
          onChange={(newValue) => setValue(newValue as OPTION)}
          colorScheme="purple"
        >
          <BigRadio value={OPTION.HEADING}>
            <Text
              textStyle="detailStrong"
              color="gray.500"
              textTransform="uppercase"
              mb="1"
            >
              Heading
            </Text>
            <MiniHeading />
          </BigRadio>
          <BigRadio value={OPTION.HERO}>
            <Text
              textStyle="detailStrong"
              color="gray.500"
              textTransform="uppercase"
              mb="1"
            >
              Hero
            </Text>
            <MiniHero />
          </BigRadio>
          <BigRadio value={OPTION.IMAGE_GROUP}>
            <Text
              textStyle="detailStrong"
              color="gray.500"
              textTransform="uppercase"
              mb="1"
            >
              Image Group
            </Text>
            <MiniImageGroup />
          </BigRadio>
          <BigRadio value={OPTION.BLOCK_GROUP}>
            <Text
              textStyle="detailStrong"
              color="gray.500"
              textTransform="uppercase"
              mb="1"
            >
              Card Group
            </Text>
            <MiniBlockGroup />
          </BigRadio>
          <BigRadio value={OPTION.CAROUSEL}>
            <Text
              textStyle="detailStrong"
              color="gray.500"
              textTransform="uppercase"
              mb="1"
            >
              Carousel
            </Text>
            <MiniCarousel />
          </BigRadio>
          <BigRadio value={OPTION.RICH_TEXT}>
            <Text
              textStyle="detailStrong"
              color="gray.500"
              textTransform="uppercase"
              mb="1"
            >
              Rich Text
            </Text>
            <MiniRichText />
          </BigRadio>
        </BigRadioGroup>
        <Stack direction="row">
          <Button
            flexShrink="0"
            variant="outline"
            colorScheme="gray"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            w="full"
            colorScheme="purple"
            isDisabled={!value}
            onClick={onSubmit}
          >
            Choose Component
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};
