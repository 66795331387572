import { Box, Text } from '@chakra-ui/react';
import * as React from 'react';

export const Highlight: React.FC<{
  label: string;
  isActive: boolean;
  shouldScroll: boolean;
}> = ({ label, isActive, shouldScroll, children }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (isActive && shouldScroll && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [isActive, shouldScroll]);

  return (
    <Box w="full" position="relative" ref={ref} zIndex="1">
      {children}
      {isActive && (
        <Box
          borderWidth="5px"
          borderColor="purple.500"
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="1"
        >
          <Box
            bg="purple.500"
            position="absolute"
            top="0"
            left="0"
            zIndex="1"
            px="3"
            py="1"
            borderBottomRightRadius="lg"
            ref={ref}
          >
            <Text textStyle="bodyStrong" color="white">
              {label}
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};
