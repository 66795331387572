import { gql, useMutation } from '@apollo/client';
import { MenuItem, useToast } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import * as React from 'react';

import { AnimatedLoaderIcon } from '@/components/AnimatedLoaderIcon';
import { CopyIcon } from '@/imports/ui/chakra/feather';

import {
  DuplicatePageButton_DuplicatePage,
  DuplicatePageButton_DuplicatePageVariables,
} from './graphql-types/DuplicatePageButton_DuplicatePage';

const DUPLICATE_PAGE = gql`
  mutation DuplicatePageButton_DuplicatePage($input: DuplicatePageInput!) {
    duplicatePage(input: $input) {
      page {
        _id
      }
    }
  }
`;

export const DuplicatePageButton: React.FC<{ pageId: string }> = ({
  pageId,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const router = useRouter();
  const toast = useToast();
  const [duplicatePage] = useMutation<
    DuplicatePageButton_DuplicatePage,
    DuplicatePageButton_DuplicatePageVariables
  >(DUPLICATE_PAGE, {
    variables: {
      input: {
        pageId,
      },
    },
    refetchQueries: ['PageList_Pages'],
  });

  const onClick = async () => {
    setIsLoading(true);

    try {
      const result = await duplicatePage();
      await router.push(
        `/website-builder/${result.data?.duplicatePage.page._id}`
      );
    } catch (e) {
      toast({
        status: 'error',
        description: 'An error occurred while attempting to duplicate a page',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MenuItem
      closeOnSelect={false}
      isDisabled={isLoading}
      icon={isLoading ? <AnimatedLoaderIcon /> : <CopyIcon />}
      onClick={onClick}
    >
      Duplicate
    </MenuItem>
  );
};
