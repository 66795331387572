import { gql, useMutation } from '@apollo/client';
import * as React from 'react';
import { UseFormReturn, useFormState, useWatch } from 'react-hook-form';

import { useDebounce } from '@/imports/ui/hooks/useDebounce';
import { WebsiteBuilderPageFormValues } from '@/modules/website-builder/types';
import { convertPageContentToPageContentInput } from '@/modules/website-builder/utilities';
import { PAGE_FRAGMENT } from '@/queries';
import { PageFragment } from '@/queries/graphql-types/PageFragment';

import {
  AutoSave_UpdatePage,
  AutoSave_UpdatePageVariables,
} from './graphql-types/AutoSave_UpdatePage';

const UPDATE_PAGE = gql`
  mutation AutoSave_UpdatePage($input: UpdatePageInput!) {
    updatePage(input: $input) {
      page(input: { isUnpublishedVersion: true }) {
        _id
        ...PageFragment
      }
    }
  }
  ${PAGE_FRAGMENT}
`;

// this is a component instead of a hook because we are trying to avoid very
// expensive re-renders in the main page component. the watch() function we use
// in here triggers unnecessary re-renders - this way we isolate that

export const AutoSave: React.FC<{
  page: PageFragment;
  methods: UseFormReturn<WebsiteBuilderPageFormValues, any>;
}> = ({ page, methods }) => {
  const [updatePage] = useMutation<
    AutoSave_UpdatePage,
    AutoSave_UpdatePageVariables
  >(UPDATE_PAGE);

  const { control, handleSubmit } = methods;

  const { isDirty } = useFormState({ control });
  const content = useWatch({ control });

  const onSubmit = handleSubmit(async (data) => {
    await updatePage({
      variables: {
        input: {
          pageId: page._id,
          pageVersion: page.metadata.version,
          content: convertPageContentToPageContentInput(data.content),
        },
      },
      optimisticResponse: {
        updatePage: {
          page: {
            ...page,
            content: data.content,
          },
          __typename: 'UpdatePageOutput',
        },
      },
    });
  });

  const onChange = useDebounce(() => {
    if (isDirty) {
      onSubmit();
    }
  }, 1000);

  React.useEffect(onChange, [onChange, content]);

  return null;
};
