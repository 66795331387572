import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Switch,
  Text,
  Textarea,
  VisuallyHidden,
} from '@chakra-ui/react';
import * as React from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';

import { ButtonRadio, ButtonRadioGroup } from '@/components/ButtonRadio';
import {
  EBlockGroupLinkBehavior,
  ELinkSource,
} from '@/graphql-types/globalTypes';
import { Card } from '@/imports/ui/chakra/components/Card';
import { ASPECT_RATIO } from '@/modules/website-builder/attributes';
import { BackButton } from '@/modules/website-builder/components/BackButton';
import { SearchablePageSelect } from '@/modules/website-builder/components/SearchablePageSelect';
import { WebsiteBuilderPageFormValues } from '@/modules/website-builder/types';

import { ImagePicker } from '../../ImagePicker';
import { ParentName } from '../../ParentName';

export const BlockEditor: React.FC<{
  parentIndex: number;
  index: number;
  defaultName: string;
  onClickBack: () => void;
  methods: UseFormReturn<WebsiteBuilderPageFormValues, any>;
}> = (props) => {
  const { control, register } = props.methods;

  const blockImageAspectRatio = useWatch({
    control,
    name: `content.${props.parentIndex}.options.blockImageAspectRatio`,
  });

  const blockLinkBehavior = useWatch({
    control,
    name: `content.${props.parentIndex}.options.blockLinkBehavior`,
  });

  const isBlockImageEnabled = useWatch({
    control,
    name: `content.${props.parentIndex}.options.isBlockImageEnabled`,
  });

  const isBlockSubtitleEnabled = useWatch({
    control,
    name: `content.${props.parentIndex}.options.isBlockSubtitleEnabled`,
  });

  const isBlockTitleEnabled = useWatch({
    control,
    name: `content.${props.parentIndex}.options.isBlockTitleEnabled`,
  });

  const isBlockBodyTextEnabled = useWatch({
    control,
    name: `content.${props.parentIndex}.options.isBlockBodyTextEnabled`,
  });

  const linkSource = useWatch({
    control,
    name: `content.${props.parentIndex}.blocks.${props.index}.linkSource`,
  });

  const imageProxy = useWatch({
    control,
    name: `content.${props.parentIndex}.blocks.${props.index}.imageProxy`,
  });

  return (
    <Stack spacing="4">
      <BackButton onClick={props.onClickBack}>
        Back to{' '}
        <ParentName
          parentIndex={props.parentIndex}
          default="Card Group"
          methods={props.methods}
        />
      </BackButton>

      <Card as={Stack} spacing="4" p="6">
        <Text textStyle="bodyStrong">Settings</Text>

        <FormControl>
          <FormLabel>Card Name</FormLabel>
          <Input
            placeholder="Card"
            {...register(
              `content.${props.parentIndex}.blocks.${props.index}.name`
            )}
          />
        </FormControl>
      </Card>

      <Card as={Stack} spacing="4" p="6">
        <Text textStyle="bodyStrong">Settings</Text>

        {isBlockImageEnabled && (
          <Stack spacing="4">
            <FormControl>
              <FormLabel>Image</FormLabel>
              <Controller
                control={control}
                name={`content.${props.parentIndex}.blocks.${props.index}.imageId`}
                render={({ field }) => (
                  <ImagePicker
                    {...field}
                    imageAspectRatio={ASPECT_RATIO[blockImageAspectRatio]}
                    imageInput={{ w: 1000 }}
                    nonDraftImageProxy={imageProxy}
                  />
                )}
              />
            </FormControl>
          </Stack>
        )}

        {isBlockSubtitleEnabled && (
          <Controller
            control={control}
            name={`content.${props.parentIndex}.blocks.${props.index}.subtitle`}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Card Subtitle</FormLabel>
                <Textarea
                  resize="vertical"
                  value={field.value ?? ''}
                  onChange={field.onChange}
                  maxLength={60}
                />
                <FormHelperText textAlign="right">
                  {field.value?.length ?? 0}/60
                </FormHelperText>
              </FormControl>
            )}
          />
        )}

        {isBlockTitleEnabled && (
          <Controller
            control={control}
            name={`content.${props.parentIndex}.blocks.${props.index}.title`}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Card Title</FormLabel>
                <Textarea
                  resize="vertical"
                  value={field.value ?? ''}
                  onChange={field.onChange}
                  maxLength={50}
                />
                <FormHelperText textAlign="right">
                  {field.value?.length ?? 0}/50
                </FormHelperText>
              </FormControl>
            )}
          />
        )}

        {isBlockBodyTextEnabled && (
          <Controller
            control={control}
            name={`content.${props.parentIndex}.blocks.${props.index}.body`}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Card Body</FormLabel>
                <Textarea
                  resize="vertical"
                  value={field.value ?? ''}
                  onChange={field.onChange}
                  maxLength={270}
                />
                <FormHelperText textAlign="right">
                  {field.value?.length ?? 0}/270
                </FormHelperText>
              </FormControl>
            )}
          />
        )}
      </Card>

      <Card as={Stack} spacing="4" p="6">
        <Text textStyle="bodyStrong">Link</Text>

        {blockLinkBehavior === EBlockGroupLinkBehavior.CARD && (
          <Box bg="gray.100" borderRadius="md" color="gray.500" py="2" px="4">
            The card will be the link
          </Box>
        )}

        {blockLinkBehavior === EBlockGroupLinkBehavior.BUTTON && (
          <Controller
            control={control}
            name={`content.${props.parentIndex}.blocks.${props.index}.linkText`}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Button copy</FormLabel>

                <Input
                  value={field.value ?? ''}
                  onChange={field.onChange}
                  maxLength={50}
                />
                <FormHelperText textAlign="right">
                  {field.value?.length ?? 0}/50
                </FormHelperText>
              </FormControl>
            )}
          />
        )}

        <Controller
          control={control}
          name={`content.${props.parentIndex}.blocks.${props.index}.linkSource`}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Link to</FormLabel>
              <ButtonRadioGroup colorScheme="purple" {...field}>
                <ButtonRadio value={ELinkSource.CREATED_PAGE}>
                  Created Page
                </ButtonRadio>
                <ButtonRadio value={ELinkSource.LINK}>
                  External Link
                </ButtonRadio>
              </ButtonRadioGroup>
            </FormControl>
          )}
        />

        {linkSource === ELinkSource.CREATED_PAGE && (
          <Controller
            control={control}
            name={`content.${props.parentIndex}.blocks.${props.index}.linkPage`}
            render={({ field }) => (
              <FormControl>
                <VisuallyHidden>
                  <FormLabel>Page</FormLabel>
                </VisuallyHidden>
                <SearchablePageSelect {...field} />
              </FormControl>
            )}
          />
        )}

        {linkSource === ELinkSource.LINK && (
          <FormControl>
            <VisuallyHidden>
              <FormLabel>Href</FormLabel>
            </VisuallyHidden>
            <Input
              placeholder="https://"
              {...register(
                `content.${props.parentIndex}.blocks.${props.index}.linkHref`
              )}
            />
          </FormControl>
        )}
      </Card>
    </Stack>
  );
};
