import {
  Box,
  BoxProps,
  Button,
  Flex,
  FlexProps,
  Heading,
  IconButton,
  Stack,
  Text,
  useControllableProp,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';
import { useInterval } from 'usehooks-ts';

import {
  ECarouselLinkBehavior,
  ELinkSource,
} from '@/graphql-types/globalTypes';
import { Card, CardProps } from '@/imports/ui/chakra/components/Card';
import { ChevronLeftIcon, ChevronRightIcon } from '@/imports/ui/chakra/feather';
import { Carousel, CarouselItem } from '@/modules/website-builder/types';
import { getExternalUrl } from '@/utilities/getExternalUrl';

const height: Record<number, BoxProps['height']> = {
  1: '3xl',
  2: '2xl',
  3: '2xl',
};

const justifyContent: Record<number, FlexProps['justifyContent']> = {
  1: 'flex-start',
  2: 'space-between',
  3: undefined,
};

const background: Record<number, BoxProps['background']> = {
  1: 'blackAlpha.500',
  2: 'blackAlpha.700',
  3: undefined,
};

const CarouselItemCard: React.FC<
  CardProps & { carousel: Carousel; carouselItem: CarouselItem }
> = ({ carousel, carouselItem, ...props }) => {
  const href =
    carouselItem.linkSource === ELinkSource.CREATED_PAGE
      ? carouselItem.linkPage?.href
      : carouselItem.linkSource === ELinkSource.LINK
      ? carouselItem.linkHref
      : undefined;

  if (
    carousel.options.carouselItemLinkBehavior === ECarouselLinkBehavior.CARD &&
    href
  ) {
    if (carouselItem.linkIsExternal) {
      return (
        <Card
          cursor="pointer"
          as="a"
          display="block"
          target="_blank"
          rel="noreferrer"
          href={getExternalUrl(href)}
          {...props}
        />
      );
    }

    return (
      <NextLink href={href}>
        <Card cursor="pointer" {...props} />
      </NextLink>
    );
  }

  return <Card {...props} />;
};

const CarouselItemButton: React.FC<{ carouselItem: CarouselItem }> = ({
  carouselItem,
}) => {
  const href =
    carouselItem.linkSource === ELinkSource.CREATED_PAGE
      ? carouselItem.linkPage?.href
      : carouselItem.linkSource === ELinkSource.LINK
      ? carouselItem.linkHref
      : undefined;

  if (href) {
    if (carouselItem.linkIsExternal) {
      return (
        <Button
          as="a"
          target="_blank"
          rel="noreferrer"
          href={getExternalUrl(href)}
        >
          {carouselItem.linkText}
        </Button>
      );
    }

    return (
      <NextLink href={href}>
        <Button>{carouselItem.linkText}</Button>
      </NextLink>
    );
  }

  return <Button isDisabled>{carouselItem.linkText}</Button>;
};

export const CarouselRender: React.FC<{
  carousel: Carousel;
  carouselIndex: number | undefined;
}> = ({ carousel, carouselIndex }) => {
  const [_currentIndex, setCurrentIndex] = React.useState(0);

  const [isControlled, currentIndex] = useControllableProp(
    carouselIndex,
    _currentIndex
  );

  const previous = () => {
    setCurrentIndex((previousValue) =>
      previousValue === 0
        ? carousel.carouselItems.length - 1
        : previousValue - 1
    );
  };

  const next = () => {
    setCurrentIndex((previousValue) =>
      previousValue === carousel.carouselItems.length - 1
        ? 0
        : previousValue + 1
    );
  };

  const carouselItem =
    carousel.carouselItems[currentIndex] ?? carousel.carouselItems[0];

  useInterval(
    () => {
      if (!isControlled) {
        next();
      }
    },
    carousel.options.isCarouselAutoChangeEnabled
      ? carousel.options.carouselAutoChangeSpeed
      : null
  );

  // keep the internal index in sync with the controlling index so that when
  // the the component becomes uncontrolled we don't flash the image back
  // to a different image
  React.useEffect(() => {
    if (carouselIndex !== undefined) {
      setCurrentIndex(carouselIndex);
    }
  }, [carouselIndex]);

  return (
    <Box w="full" position="relative" height={height[carousel.variant] ?? 'xl'}>
      <Flex
        w="full"
        backgroundColor="gray.500"
        backgroundImage={carouselItem.imageProxy ?? undefined}
        backgroundPosition="center"
        backgroundSize="cover"
        alignItems="center"
        justifyContent={justifyContent[carousel.variant]}
        position="absolute"
        top="0"
        bottom="0"
        left="0"
        right="0"
        zIndex="0"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          background={background[carousel.variant]}
          zIndex="1"
        />

        {carousel.carouselItems.length > 1 && (
          <IconButton
            variant="ghost"
            color="white"
            zIndex="2"
            ml={{ base: 3, lg: 8 }}
            icon={<ChevronLeftIcon strokeWidth="3" fontSize="4xl" />}
            aria-label="previous carousel item"
            onClick={previous}
            size="lg"
            _hover={{ bg: 'transparent' }}
          />
        )}

        {carousel.variant === 1 && (
          <Box
            w="full"
            maxW="container.xl"
            mx="auto"
            paddingX={{ base: 3, lg: 24 }}
            zIndex="2"
          >
            <CarouselItemCard
              carousel={carousel}
              carouselItem={carouselItem}
              p="8"
              w={{ base: 'full', lg: 'xl' }}
            >
              {carouselItem.subtitle && (
                <Text textStyle="xlBodyStrong" textTransform="uppercase">
                  {carouselItem.subtitle}
                </Text>
              )}

              {carouselItem.title && (
                <Heading mt="4">{carouselItem.title}</Heading>
              )}

              {carouselItem.body && (
                <Text mt="4" textStyle="xlBody">
                  {carouselItem.body}
                </Text>
              )}

              {carousel.options.carouselItemLinkBehavior ===
                ECarouselLinkBehavior.BUTTON &&
                carouselItem.linkText && (
                  <Stack mt="8" direction="row" justifyContent="flex-end">
                    <CarouselItemButton carouselItem={carouselItem} />
                  </Stack>
                )}
            </CarouselItemCard>
          </Box>
        )}

        {carousel.variant === 2 && (
          <Box
            w="full"
            maxW="580px"
            textAlign="center"
            color="white"
            mx="6"
            zIndex="3"
          >
            {carouselItem.subtitle && (
              <Text textStyle="xlBodyStrong" textTransform="uppercase">
                {carouselItem.subtitle}
              </Text>
            )}

            {carouselItem.title && (
              <Heading mt="2">{carouselItem.title}</Heading>
            )}

            {carouselItem.body && (
              <Text mt="2" textStyle="xlBody">
                {carouselItem.body}
              </Text>
            )}

            {carouselItem.linkText && (
              <Box mt="10">
                <CarouselItemButton carouselItem={carouselItem} />
              </Box>
            )}
          </Box>
        )}

        {carousel.variant === 3 && <Box flex="1"></Box>}

        {carousel.carouselItems.length > 1 && (
          <IconButton
            variant="ghost"
            color="white"
            zIndex="2"
            mr={{ base: 3, lg: 8 }}
            icon={<ChevronRightIcon strokeWidth="3" fontSize="4xl" />}
            aria-label="next carousel item"
            onClick={next}
            size="lg"
            _hover={{ bg: 'transparent' }}
          />
        )}
      </Flex>
    </Box>
  );
};
