import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  VisuallyHidden,
} from '@chakra-ui/react';
import * as React from 'react';

import { AnimatedLoaderIcon } from '@/components/AnimatedLoaderIcon';
import { SearchIcon } from '@/imports/ui/chakra/feather';

export const Search: React.FC<{
  value?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  isLoading: boolean;
  label: string;
  maxW?: React.ComponentProps<typeof FormControl>['maxW'];
}> = ({ value, defaultValue, onChange, isLoading, label, maxW }) => {
  return (
    <FormControl maxW={maxW}>
      <VisuallyHidden>
        <FormLabel>{label}</FormLabel>
      </VisuallyHidden>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <SearchIcon />
        </InputLeftElement>
        <Input
          variant="filled"
          borderRadius="full"
          placeholder={label}
          value={value}
          defaultValue={defaultValue}
          onChange={(event) => onChange(event.target.value)}
        />
        {isLoading && (
          <InputRightElement pointerEvents="none">
            <AnimatedLoaderIcon />
          </InputRightElement>
        )}
      </InputGroup>
    </FormControl>
  );
};
