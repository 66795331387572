import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { Box, SlideFade, Stack } from '@chakra-ui/react';
import * as React from 'react';

import { Search } from '@/components/Search';
import { PlusIcon } from '@/imports/ui/chakra/feather';
import { useDebouncedValue } from '@/imports/ui/hooks/useDebouncedValue';
import { VeryGhostlyButton } from '@/modules/website-builder/components/GhostlyButton';

import {
  PageList_Pages,
  PageList_PagesVariables,
} from './graphql-types/PageList_Pages';

import { PageLink } from '../PageLink';
import { CreateNewPage } from './components/CreateNewPage';

const PAGES = gql`
  query PageList_Pages($input: PagesInput!) {
    pages(input: $input) {
      edges {
        node {
          _id
          ...PageLink_PageFragment
        }
      }
    }
  }
  ${PageLink.pageFragment}
`;

export const PageList: React.FC<{
  onClickPageLink: (pageId: string) => void;
}> = (props) => {
  const [searchText, setSearchText] = React.useState('');
  const debouncedSearchText = useDebouncedValue(searchText, 500);

  const query = useQuery<PageList_Pages, PageList_PagesVariables>(PAGES, {
    variables: {
      input: {
        first: 200,
        filter: { includeUnpublished: true, searchText: debouncedSearchText },
      },
    },
  });

  const [index, setIndex] = React.useState(0);

  return (
    <>
      {index === 0 && (
        <SlideFade in>
          <Stack spacing="0">
            <Box p="4" bg="white">
              <Search
                defaultValue={searchText}
                onChange={setSearchText}
                isLoading={query.networkStatus === NetworkStatus.setVariables}
                label="Search name or URL slug"
              />
            </Box>
            <VeryGhostlyButton
              leftIcon={<PlusIcon />}
              onClick={() => setIndex(1)}
              borderLeft="none"
              borderRight="none"
              borderBottom="none"
              borderRadius="none"
            >
              Add a New Page
            </VeryGhostlyButton>
            {query.data?.pages.edges.map(({ node }) => (
              <PageLink
                key={node._id}
                page={node}
                onClick={() => props.onClickPageLink(node._id)}
              />
            ))}
          </Stack>
        </SlideFade>
      )}

      {index === 1 && (
        <SlideFade in>
          <CreateNewPage onCancel={() => setIndex(0)} />
        </SlideFade>
      )}
    </>
  );
};
