import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Select,
  SlideFade,
  Stack,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  Controller,
  UseFormReturn,
  useFieldArray,
  useWatch,
} from 'react-hook-form';

import { SelectButton } from '@/components/SelectButton';
import {
  EBlockGroupBlockAlignment,
  EBlockGroupImageAspectRatio,
  EBlockGroupLinkBehavior,
  EPageContentBackgroundColor,
} from '@/graphql-types/globalTypes';
import { Card } from '@/imports/ui/chakra/components/Card';
import { PlusIcon } from '@/imports/ui/chakra/feather';
import { BackButton } from '@/modules/website-builder/components/BackButton';
import { DEFAULT_BLOCK_CONTENT } from '@/modules/website-builder/default-content';
import { WebsiteBuilderPageFormValues } from '@/modules/website-builder/types';

import { ContentItem } from '../ContentItem';
import { BlockEditor } from './components/BlockEditor';
import {
  SpecialFormControl,
  SpecialFormLabel,
  SpecialSwitch,
} from './components/Special';

const ASPECT_RATIO_LABEL = {
  [EBlockGroupImageAspectRatio.HORIZONTAL]: 'Horizontal (4:3)',
  [EBlockGroupImageAspectRatio.VERY_HORIZONTAL]: 'Horizontal (16:9)',
  [EBlockGroupImageAspectRatio.VERTICAL]: 'Vertical (3:4)',
  [EBlockGroupImageAspectRatio.VERY_VERTICAL]: 'Vertical (9:16)',
  [EBlockGroupImageAspectRatio.SQUARE]: 'Square (1:1)',
};

export const BlockGroupEditor: React.FC<{
  index: number;
  defaultName: string;
  onClickBack: () => void;
  methods: UseFormReturn<WebsiteBuilderPageFormValues, any>;
}> = (props) => {
  const { control, register } = props.methods;

  const isBlockImageEnabled = useWatch({
    control,
    name: `content.${props.index}.options.isBlockImageEnabled`,
  });

  const blocksFieldArray = useFieldArray({
    control,
    name: `content.${props.index}.blocks`,
  });

  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  return (
    <Stack spacing="4">
      {activeIndex === null && (
        <SlideFade in>
          <Stack spacing="4">
            <BackButton onClick={props.onClickBack}>
              Back to all components
            </BackButton>

            <Card as={Stack} spacing="4" p="6">
              <Text textStyle="bodyStrong">Component Settings</Text>

              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  placeholder="Card Group"
                  {...register(`content.${props.index}.name`)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Background Color</FormLabel>
                <Select
                  {...register(
                    `content.${props.index}.options.backgroundColor`
                  )}
                >
                  <option value={EPageContentBackgroundColor.WHITE}>
                    White
                  </option>
                  <option value={EPageContentBackgroundColor.TRANSPARENT}>
                    Light Gray
                  </option>
                </Select>
              </FormControl>
            </Card>

            <Card as={Stack} spacing="4" p="6">
              <Text textStyle="bodyStrong">Card Settings</Text>
              <FormControl>
                <FormLabel>Size</FormLabel>
                <Select
                  {...register(`content.${props.index}.options.columns`, {
                    valueAsNumber: true,
                  })}
                >
                  <option value="4">Small</option>
                  <option value="3">Medium</option>
                  <option value="2">Large</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Text Alignment</FormLabel>
                <Select
                  {...register(`content.${props.index}.options.blockAlignment`)}
                >
                  <option value={EBlockGroupBlockAlignment.CENTER}>
                    Center
                  </option>
                  <option value={EBlockGroupBlockAlignment.LEFT}>Left</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Link Option</FormLabel>
                <Select
                  {...register(
                    `content.${props.index}.options.blockLinkBehavior`
                  )}
                >
                  <option value={EBlockGroupLinkBehavior.CARD}>
                    Card link
                  </option>
                  <option value={EBlockGroupLinkBehavior.BUTTON}>Button</option>
                </Select>
              </FormControl>
              <Text textStyle="compactStrong">Content settings</Text>
              <SpecialFormControl>
                <SpecialFormLabel>Image</SpecialFormLabel>
                <SpecialSwitch
                  {...register(
                    `content.${props.index}.options.isBlockImageEnabled`
                  )}
                />
              </SpecialFormControl>

              {isBlockImageEnabled && (
                <Controller
                  control={control}
                  name={`content.${props.index}.options.blockImageAspectRatio`}
                  render={({ field }) => (
                    <FormControl
                      bg="gray.50"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      py="2"
                      px="4"
                      borderRadius="md"
                      gap="4"
                    >
                      <FormLabel fontWeight="normal" mb="0">
                        Ratio
                      </FormLabel>
                      <Menu>
                        <MenuButton as={SelectButton} size="sm">
                          {ASPECT_RATIO_LABEL[field.value]}
                        </MenuButton>
                        <MenuList>
                          <MenuOptionGroup
                            type="radio"
                            value={field.value}
                            onChange={field.onChange}
                          >
                            <MenuItemOption
                              value={EBlockGroupImageAspectRatio.HORIZONTAL}
                            >
                              {
                                ASPECT_RATIO_LABEL[
                                  EBlockGroupImageAspectRatio.HORIZONTAL
                                ]
                              }
                            </MenuItemOption>
                            <MenuItemOption
                              value={
                                EBlockGroupImageAspectRatio.VERY_HORIZONTAL
                              }
                            >
                              {
                                ASPECT_RATIO_LABEL[
                                  EBlockGroupImageAspectRatio.VERY_HORIZONTAL
                                ]
                              }
                            </MenuItemOption>
                            <MenuItemOption
                              value={EBlockGroupImageAspectRatio.VERTICAL}
                            >
                              {
                                ASPECT_RATIO_LABEL[
                                  EBlockGroupImageAspectRatio.VERTICAL
                                ]
                              }
                            </MenuItemOption>
                            <MenuItemOption
                              value={EBlockGroupImageAspectRatio.VERY_VERTICAL}
                            >
                              {
                                ASPECT_RATIO_LABEL[
                                  EBlockGroupImageAspectRatio.VERY_VERTICAL
                                ]
                              }
                            </MenuItemOption>
                            <MenuItemOption
                              value={EBlockGroupImageAspectRatio.SQUARE}
                            >
                              {
                                ASPECT_RATIO_LABEL[
                                  EBlockGroupImageAspectRatio.SQUARE
                                ]
                              }
                            </MenuItemOption>
                          </MenuOptionGroup>
                        </MenuList>
                      </Menu>
                    </FormControl>
                  )}
                />
              )}

              <SpecialFormControl>
                <SpecialFormLabel>Subtitle</SpecialFormLabel>
                <SpecialSwitch
                  {...register(
                    `content.${props.index}.options.isBlockSubtitleEnabled`
                  )}
                />
              </SpecialFormControl>

              <SpecialFormControl>
                <SpecialFormLabel>Title</SpecialFormLabel>
                <SpecialSwitch
                  {...register(
                    `content.${props.index}.options.isBlockTitleEnabled`
                  )}
                />
              </SpecialFormControl>

              <SpecialFormControl>
                <SpecialFormLabel>Body text</SpecialFormLabel>
                <SpecialSwitch
                  {...register(
                    `content.${props.index}.options.isBlockBodyTextEnabled`
                  )}
                />
              </SpecialFormControl>
            </Card>

            <DragDropContext
              onDragEnd={(result) => {
                if (result.destination) {
                  blocksFieldArray.move(
                    result.source.index,
                    result.destination.index
                  );
                }
              }}
            >
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <Box ref={provided.innerRef} {...provided.droppableProps}>
                    {blocksFieldArray.fields.map((field, index) => {
                      return (
                        <ContentItem
                          key={field.id}
                          index={index}
                          namePath={`content.${props.index}.blocks.${index}.name`}
                          defaultName="Card"
                          onClick={() => setActiveIndex(index)}
                          onDelete={() => blocksFieldArray.remove(index)}
                          methods={props.methods}
                          pointerEvents={
                            snapshot.isDraggingOver ? 'none' : undefined
                          }
                        />
                      );
                    })}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Stack>
        </SlideFade>
      )}

      {blocksFieldArray.fields.map((field, index) => {
        return activeIndex === index ? (
          <SlideFade in key={index}>
            <BlockEditor
              parentIndex={props.index}
              index={index}
              defaultName="Card"
              onClickBack={() => setActiveIndex(null)}
              methods={props.methods}
            />
          </SlideFade>
        ) : null;
      })}

      {activeIndex === null && (
        <SlideFade in>
          <Center>
            <Button
              colorScheme="purple"
              leftIcon={<PlusIcon />}
              size="sm"
              onClick={() => blocksFieldArray.append(DEFAULT_BLOCK_CONTENT)}
            >
              Add a New Card
            </Button>
          </Center>
        </SlideFade>
      )}
    </Stack>
  );
};
