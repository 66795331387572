import { SlideFade, Stack, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import {
  Controller,
  FieldArray,
  UseFormReturn,
  useFieldArray,
} from 'react-hook-form';

import {
  EDefaultNavigationItemIdentifier,
  ENavigationItemStructure,
} from '@/graphql-types/globalTypes';
import { BackButton } from '@/modules/website-builder/components/BackButton';
import {
  MiniCommunityPage,
  MiniEventsPage,
  MiniPeopleDirectoryPage,
  MiniSponsorDirectoryPage,
} from '@/modules/website-builder/components/Minis';
import { WebsiteBuilderNavigationFormValues } from '@/modules/website-builder/types';

import { NewDirectLinkForm } from '../NewDirectLinkForm';
import { DeleteButton } from './components/DeleteButton';
import { DirectLinkForm } from './components/DirectLinkForm';
import { DropdownMenuForm } from './components/DropdownMenuForm';
import {
  NotEditable,
  NotEditableDescription,
  NotEditableRestriction,
  NotEditableSuggestion,
  NotEditableTitle,
} from './components/NotEditable';

export const NavigationItemEditor: React.FC<{
  index: number;
  field: FieldArray<WebsiteBuilderNavigationFormValues, 'items'>;
  onClickBack: () => void;
  methods: UseFormReturn<WebsiteBuilderNavigationFormValues, any>;
  onDelete: () => void;
}> = ({ index, field, onClickBack, methods, onDelete }) => {
  const newLinkForm = useDisclosure();

  const childrenFieldArray = useFieldArray({
    control: methods.control,
    name: `items.${index}.children`,
  });

  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  return (
    <Stack spacing="4">
      {activeIndex === null && !newLinkForm.isOpen && (
        <SlideFade in>
          <Stack spacing="4">
            <BackButton onClick={onClickBack}>Back to all tabs</BackButton>

            {!field.defaultItemIdentifier && (
              <Controller
                name={`items.${index}`}
                control={methods.control}
                render={({ field: { value, onChange } }) => {
                  return {
                    [ENavigationItemStructure.DIRECT_LINK]: (
                      <DirectLinkForm
                        value={value}
                        onChange={onChange}
                        isEditable={!field.defaultItemIdentifier}
                      />
                    ),
                    [ENavigationItemStructure.DROPDOWN]: (
                      <DropdownMenuForm
                        value={value}
                        onChange={onChange}
                        onClickAdd={newLinkForm.onOpen}
                        onClickChild={(childIndex) =>
                          setActiveIndex(childIndex)
                        }
                      />
                    ),
                  }[field.structure];
                }}
              />
            )}
          </Stack>
        </SlideFade>
      )}

      {field.defaultItemIdentifier ===
        EDefaultNavigationItemIdentifier.COMMUNITY &&
        field.restriction && (
          <NotEditable page={<MiniCommunityPage />}>
            <NotEditableTitle>Tradewing Community</NotEditableTitle>
            <NotEditableDescription>
              Bring your members together and foster engagement with our online
              community. Here, members and association admins can connect and
              collaborate.
            </NotEditableDescription>
            <NotEditableSuggestion>
              <NotEditableRestriction restriction={field.restriction} />
            </NotEditableSuggestion>
          </NotEditable>
        )}

      {field.defaultItemIdentifier ===
        EDefaultNavigationItemIdentifier.EVENTS &&
        field.restriction && (
          <NotEditable page={<MiniEventsPage />}>
            <NotEditableTitle>Tradewing Events</NotEditableTitle>
            <NotEditableDescription>
              Discover and register for upcoming events on Tradewing by
              exploring the events page. All events hosted on our platform will
              be listed here.
            </NotEditableDescription>
            <NotEditableSuggestion>
              <NotEditableRestriction restriction={field.restriction} />
            </NotEditableSuggestion>
          </NotEditable>
        )}

      {field.defaultItemIdentifier ===
        EDefaultNavigationItemIdentifier.MEMBERS &&
        field.restriction && (
          <NotEditable page={<MiniPeopleDirectoryPage />}>
            <NotEditableTitle>Tradewing Member Directory</NotEditableTitle>
            <NotEditableDescription>
              Find and connect association members on the Tradewing Member
              Directory.
            </NotEditableDescription>
            <NotEditableSuggestion>
              <NotEditableRestriction restriction={field.restriction} />
            </NotEditableSuggestion>
          </NotEditable>
        )}

      {field.defaultItemIdentifier ===
        EDefaultNavigationItemIdentifier.SPONSORS &&
        field.restriction && (
          <NotEditable page={<MiniSponsorDirectoryPage />}>
            <NotEditableTitle>Tradewing Sponsor Directory</NotEditableTitle>
            <NotEditableDescription>
              A place where members can easily discover and connect with
              sponsors.
            </NotEditableDescription>
            <NotEditableSuggestion>
              <NotEditableRestriction restriction={field.restriction} />
            </NotEditableSuggestion>
          </NotEditable>
        )}

      {field.defaultItemIdentifier ===
        EDefaultNavigationItemIdentifier.SPONSOR_DASHBOARD &&
        field.restriction && (
          <NotEditable page={<></>}>
            <NotEditableTitle>Tradewing Sponsor Dashboard</NotEditableTitle>
            <NotEditableDescription>
              Each sponsor has their own personalized dashboard, where they can
              engage with leads, post content, and more.
            </NotEditableDescription>
            <NotEditableSuggestion>
              This page is only visible to sponsor company admins.
            </NotEditableSuggestion>
          </NotEditable>
        )}

      {activeIndex === null && newLinkForm.isOpen && (
        <SlideFade in>
          <NewDirectLinkForm
            onClickBack={newLinkForm.onClose}
            backText={`Back to ${field.label}`}
            onSubmit={(item) => {
              childrenFieldArray.append(item);
              newLinkForm.onClose();
            }}
            submitText="Add Link"
          />
        </SlideFade>
      )}

      {activeIndex === null && !newLinkForm.isOpen && (
        <SlideFade in>
          <DeleteButton
            onDelete={onDelete}
            labelPath={`items.${index}.label`}
            isDisabled={!!field.defaultItemIdentifier}
            methods={methods}
          >
            Remove Tab
          </DeleteButton>
        </SlideFade>
      )}

      {childrenFieldArray.fields.map((field, childIndex) => {
        return activeIndex === childIndex ? (
          <SlideFade key={childIndex} in>
            <Stack spacing="4">
              <BackButton onClick={() => setActiveIndex(null)}>
                Back to Add a tab
              </BackButton>
              <DirectLinkForm
                value={field}
                onChange={(value) =>
                  childrenFieldArray.update(childIndex, value)
                }
                isEditable
              />
              <DeleteButton
                onDelete={() => {
                  childrenFieldArray.remove(childIndex);
                  setActiveIndex(null);
                }}
                labelPath={`items.${index}.children.${childIndex}.label`}
                isDisabled={!!field.defaultItemIdentifier}
                methods={methods}
              >
                Remove Link
              </DeleteButton>
            </Stack>
          </SlideFade>
        ) : null;
      })}
    </Stack>
  );
};
